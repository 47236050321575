import { Modal, styled } from "@mui/material";
import { Button } from "src/components";

export const Wrapper = styled("div")({
  backgroundColor: "white",
  padding: "25px",
  borderRadius: "10px",
  width: "285px",

  p: {
    fontSize: "16px",
    lineHeight: "21px",
    fontWeight: 600,
  },

  button: {
    marginTop: "10px",
  },
});

export const ScheduleButton = styled(Button)({
  borderRadius: "5px",
});

export const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 99999999,
});
