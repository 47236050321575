import React from "react";
import Calendar from "./Calendar";
import UpcomingSessions from "./UpcomingSessions";
import { styled } from "@mui/material";

const ManagerContent = () => {
  return (
    <Wrapper>
      <Calendar />
      <UpcomingSessions />
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  padding: "20px",
  width: "100%",
  maxHeight: `${window.innerHeight - 130}px`,
  overflow: "auto",
});

export default ManagerContent;
