import { createContext, useContext, useState, ReactNode } from "react";

interface ContextValue {
  isReactivationVisible: boolean;
  isWelcomeBackVisible: boolean;
  manageModalWelcomeBack: () => void;
  manageModalReactivation: () => void;
}

const ReactivationContext = createContext<ContextValue>({} as ContextValue);

export const useReactivation = () => useContext(ReactivationContext);

const ReactivationProvider = ({ children }: { children: ReactNode }) => {
  const [isReactivationVisible, setisReactivationVisibleVisible] =
    useState(false);
  const [isWelcomeBackVisible, setisWelcomeBackVisible] = useState(false);

  const manageModalReactivation = () => {
    setisReactivationVisibleVisible((prev) => !prev);
  };

  const manageModalWelcomeBack = () => {
    setisWelcomeBackVisible((prev) => !prev);
  };

  const value = {
    isReactivationVisible,
    isWelcomeBackVisible,
    manageModalReactivation,
    manageModalWelcomeBack,
  };

  return (
    <ReactivationContext.Provider value={value}>
      {children}
    </ReactivationContext.Provider>
  );
};

export default ReactivationProvider;
