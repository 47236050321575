import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, InfoLayout } from "src/components";
import { ROUTES } from "src/types/routes";
import { WistiaEmbed } from "src/components";
import { Collapse, Typography } from "@mui/material";
import {
  ButtonWrapper,
  Content,
  ReadButtonWrapper,
  TextWrapper,
} from "./styles";
import {
  useGetActiveModuleQueryResolver,
  useGetYoureOnYourWayQueryResolver,
} from "src/api/resolvers";
import { ArrowDown } from "src/assets/icons";
import { useGetMemberQuery, useUpdateMemberMutation } from "src/api/main";
import { AppStatuses } from "src/api/types";
import { useGetModulesQuery } from "src/api/cms";
import { useFlags } from "launchdarkly-react-client-sdk";

const JourneyStarted = () => {
  const { appOnboardingQuestionnaire } = useFlags();

  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const { data: cmsContent } = useGetYoureOnYourWayQueryResolver();
  const [textOpened, setTextOpened] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  //prefetch for pathway selection screen
  useGetModulesQuery();
  //end of prefetch

  const { data: module } = useGetActiveModuleQueryResolver();
  const [updateMember] = useUpdateMemberMutation();
  const { data: member } = useGetMemberQuery();

  const openText = () => {
    setTextOpened((prev) => !prev);
    setDisabled(false);
  };

  useEffect(() => {
    if (cmsContent && textRef?.current) {
      textRef.current.innerHTML = cmsContent?.summary;
    }
  }, [cmsContent]);

  const cmsUrl = new URL(process.env.REACT_APP_CMS_URL || "").origin;

  if (!cmsContent) return <></>;

  const handleContinueClick = () => {
    const pathwayPage = appOnboardingQuestionnaire
      ? ROUTES.PERSONALIZATION_DESCRIPTION
      : ROUTES.MODULE_SELECTION;
    const page = module ? ROUTES.HOME : pathwayPage;
    setDisabled(true);

    updateMember({
      memberId: member?.uuid || "",
      body: {
        app_status: AppStatuses.ACTIVE,
      },
    })
      .unwrap()
      .then(() => {
        navigate(page, {
          state: { referer: ROUTES.JOURNEY_STARTED },
        });
        setDisabled(false);
      });
  };

  return (
    <InfoLayout
      Icon={() => (
        <img
          src={cmsUrl + cmsContent.image.data[0]?.attributes.url}
          alt="couple"
        />
      )}
      title={cmsContent.title}
      subtitle={cmsContent.subtitle}
      sx={{ height: "calc(var(--vh, 1vh) * 100 - 96px)" }}
      AdditionalContent={
        <Content>
          <WistiaEmbed
            link={cmsContent.link.data.attributes.link}
            onFinish={() => setDisabled(false)}
            isAudio
            withSkip={false}
          />
          <ReadButtonWrapper id="cant-listen-button" onClick={openText}>
            <Typography variant="h3">Prefer to read?</Typography>
            <ArrowDown
              style={{ transform: `rotate(${textOpened ? -180 : 0}deg)` }}
            />
          </ReadButtonWrapper>
          <Collapse in={textOpened}>
            <TextWrapper ref={textRef} />
          </Collapse>
        </Content>
      }
    >
      <ButtonWrapper>
        <Button
          disabled={disabled}
          id="onboarding_intro_complete_audio"
          onClick={handleContinueClick}
        >
          {cmsContent.button}
        </Button>
      </ButtonWrapper>
    </InfoLayout>
  );
};

export default JourneyStarted;
