import React from "react";

import { AvatarImage } from "../styles";
import { useGetMemberQuery } from "src/api/main";

const Avatar = () => {
  const { data: user } = useGetMemberQuery();

  if (!user?.picture) {
    return <></>;
  }

  return <AvatarImage src={user.picture} />;
};

export default Avatar;
