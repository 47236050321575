import { Typography, styled } from "@mui/material";

export const CalendarGrid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
});

export const CalendarGridItem = styled("div")({
  aspectRatio: "1",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const Text = styled(Typography)({
  color: "black",
});

export const Heading = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  flexDirection: "row",
  marginTop: "20px",
});

export const ArrowButtonsRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
});

export const RightArrow = styled("div")({
  transform: "rotate(180deg)",
});

export const ColorDescriptionWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  marginTop: "20px",
});

export const ColorItem = styled("div")({
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "5px",
  padding: "5px",
  display: "flex",
});
