import React from "react";
import { ErrorLayout } from "src/components";
import ErrorLoginPaymentLayout from "src/components/ErrorScreens/ErrorLoginPaymentLayout";
import MemberDoesntExist from "src/components/ErrorScreens/MemberDoesntExist";
import { PartnerInfoMismatch } from "src/components/ErrorScreens/PartnerInfoMismatch";

enum LoginError {
  PAYMENT_FAILED = "6100",
  MEMBER_DOESNT_EXIST = "6000",
  PARTNER_INFO_MISMATCH = "6200",
}

const errorScreens = {
  [LoginError.PAYMENT_FAILED]: <ErrorLoginPaymentLayout />,
  [LoginError.MEMBER_DOESNT_EXIST]: <MemberDoesntExist />,
  [LoginError.PARTNER_INFO_MISMATCH]: <PartnerInfoMismatch />,
};

const LoginErrorScreen = ({ error }: { error: Error }) => {
  const key = Object.keys(errorScreens).find((key) =>
    error.message.includes(key)
  );

  return key ? errorScreens[key as LoginError] : <ErrorLayout />;
};

export default LoginErrorScreen;
