import { mainApi, useGetMemberQuery } from "src/api/main";
import { OnboardingStatus } from "src/api/types";
import { useAppDispatch } from "src/store/hooks";

const useUpdateStatus = () => {
  const appDispatch = useAppDispatch();
  const { data: member } = useGetMemberQuery();

  const updateOnboardingStatus = (updatedData: Partial<OnboardingStatus>) => {
    appDispatch(
      mainApi.util.updateQueryData(
        "getOnboardingStatus",
        member?.uuid || "",
        (draft) => {
          return { ...draft, ...updatedData };
        }
      )
    );
  };

  return updateOnboardingStatus;
};

export default useUpdateStatus;
