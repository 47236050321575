import { useRoutes } from "react-router-dom";
import { routes } from "./router";
import { allowedWithoutMember } from "./types/routes";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import useDataDogLogs from "./hooks/useDataDogLogs";
import { useGetMemberQuery } from "./api/main";
import AppWrapper from "./AppWrapper";
import useDatadogRUM from "./hooks/useDatadogRUM";
import { ErrorLayout, InAppFeedback, MemberLoader } from "./components";
import StreamProvider from "./pages/Chat/contexts/StreamContext";
import useSubscribeToSupportReply from "./hooks/useSubscribeToSupportReply";
import useSubscribeToSessionUpdate from "./pages/Home/components/SessionSection/utils/useSubscribeToSessionUpdate";
import useCioInAppEvents from "./hooks/useCioInAppEvents";
import CompleteProfileProvider from "src/contexts/CompleteProfileContext";
import ReactivationProvider from "src/contexts/ReactivationContext";
import RitualAnalytics from "client-analytics";
import * as Sentry from "@sentry/react";
import { useUpdateAppState } from "./hooks/useUpdateAppState";

function App() {
  const { isFetching: isTokenFetching } = useUpdateAppState();

  useDataDogLogs();
  useDatadogRUM();
  useSubscribeToSupportReply();
  useSubscribeToSessionUpdate();
  useCioInAppEvents();

  const { data: member, isFetching, isError } = useGetMemberQuery();

  useEffect(() => {
    RitualAnalytics.initialize({
      trackClicks: true,
      trackViews: true,
      clickEventName: "member_webapp_clicked",
      source: "webapp",
    });
  }, []);

  useEffect(() => {
    if (!member?.email) return;

    RitualAnalytics.identify({ id: member.uuid, email: member.email });
    Sentry.setUser({ id: member.uuid, email: member.email });
    Sentry.setContext("member", member);

    const identifyMember = () => {
      try {
        //@ts-ignore
        analytics.identify(member.uuid, {
          userId: member.uuid,
        });
        //@ts-ignore
        window?.FS?.setUserVars({
          displayName: member.first_name + " " + member.last_name,
          email: member.email,
        });

        window.smartlook &&
          window.smartlook("identify", member.uuid, {
            name: member.full_name,
            email: member.email,
          });

        if (!document.cookie.includes("_cioid")) {
          window._cio.identify({
            id: member.uuid,
          });
        }
      } catch (e) {
        setTimeout(identifyMember, 150);
      }
    };
    identifyMember();
  }, [member]);

  const router = useRoutes(routes);

  if (isTokenFetching) {
    return <MemberLoader />;
  }

  if (
    isTokenFetching ||
    (isFetching &&
      !allowedWithoutMember.find((item) => window.location.href.includes(item)))
  ) {
    return <MemberLoader />;
  }

  if (isError) return <ErrorLayout />;

  return (
    <StreamProvider>
      <CompleteProfileProvider>
        <ReactivationProvider>
          <AppWrapper>{router}</AppWrapper>
        </ReactivationProvider>
      </CompleteProfileProvider>
      <InAppFeedback />
    </StreamProvider>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_ID as string,
})(App);
