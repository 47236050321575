import { Avatar } from "@mui/material";
import { useGetMemberQuery } from "src/api/main";

const UserAvatar = () => {
  const { data: user } = useGetMemberQuery();

  return <>{user && <Avatar alt={user.full_name} src={user.picture} />}</>;
};

export default UserAvatar;
