import { styled, Slider } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  borderRadius: "32px",
  background: theme.palette.grey[900],
  padding: "20px 20px 24px 10px",
  width: "100%",
  display: "flex",
  gap: "14px",
}));

export const ControlButton = styled("button")({
  background: "white",
  minWidth: "56px",
  height: "56px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const Details = styled("div")({
  width: "100%",
});

export const Time = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "2px",
});

export const Track = styled(Slider)(({ theme }) => ({
  ".MuiSlider-thumb": {
    display: "none",
  },

  ".MuiSlider-rail": {
    color: theme.palette.grey[300],
  },

  ".MuiSlider-root": {
    padding: "0 !important",
  },
}));
