import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { ManagerWrapper } from "../styles";

const Loader = () => {
  return (
    <ManagerWrapper>
      <Skeleton
        variant="rounded"
        sx={{ borderRadius: "10px", maxWidth: "initial" }}
      >
        <Skeleton height={160} width="100%" />
      </Skeleton>
    </ManagerWrapper>
  );
};

export default Loader;
