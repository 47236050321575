import { styled } from "@mui/material";
import { Typography } from "src/components";
import theme from "src/theme";

export const Wrapper = styled("div")({
  height: "100%",

  "& > div": {
    backgroundColor: theme.palette.grey.A700,
  },
});

export const LogoutButton = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
});

export const LogoutButtonText = styled("p")({
  fontFamily: "Avenir Next",
  fontWeight: 500,
  color: theme.palette.primary.main,
  fontSize: "16px",
  lineHeight: "24px",
});

export const HeaderWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "12px",
});

export const AvatarImage = styled("img")({
  width: "56px",
  height: "56px",
  borderRadius: "28px",
});

export const MemberEmail = styled(Typography)({
  fontFamily: "Avenir Next",
  fontWeight: 400,
  marginTop: "6px",
});

export const JoinedDate = styled(Typography)({
  fontFamily: "Avenir Next",
  fontWeight: 400,
  marginTop: "16px",
  fontSize: "14px",
  lineHeight: "18px",
  color: theme.palette.grey[100],
});

export const ProfileTitle = styled(Typography)({
  color: theme.palette.grey[400],
  marginTop: "30px",
});

export const ProfileWrapper = styled("div")({
  paddingBottom: "44px",
});

export const ProfileItemsWrapper = styled("div")({
  marginTop: "24px",
});
