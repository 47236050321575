import { styled, Typography } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")<{
  recommended: boolean;
  selected: boolean;
}>(({ recommended, selected }) => ({
  borderColor: selected ? theme.palette.primary.main : "transparent",
  borderWidth: "2px",
  borderStyle: "solid",
  marginTop: "12px",
  borderRadius: "20px",
  backgroundColor: "white",
  padding: "2px",
  width: "100%",
  position: "relative",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
}));

export const Card = styled("div")<{ recommended: boolean }>(
  ({ recommended }) => {
    const recommendedStyles = {
      flexDirection: "row-reverse",
      paddingTop: "18px",
      paddingBottom: "28px",
      paddingLeft: "22px",
      paddingRight: "30px",
      gap: "22px",
    };

    const regularStyles = {
      flexDirection: "row",
      padding: "14px",
      gap: "12px",
    };

    return {
      backgroundColor: "white",
      borderRadius: "20px",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      ...((recommended ? recommendedStyles : regularStyles) as any),
    };
  }
);

export const Image = styled("img")<{ recommended: boolean }>(
  ({ recommended }) => ({
    width: recommended ? "96px" : "40px",
    height: recommended ? "96px" : "40px",
  })
);

export const Name = styled(Typography)({
  fontFamily: "Avenir Next",
  fontWeight: 600,
});

export const WeeksLengths = styled(Typography)({
  color: theme.palette.grey.A200,
  marginTop: "2px",
});

export const Goal = styled(Typography)<{ recommended?: boolean }>(
  ({ recommended }) => {
    const recommendedStyles = {
      marginTop: "12px",
      color: theme.palette.grey.A200,
      opacity: 0.8,
      fontSize: "14px",
      lineHeight: "18px",
    };

    const regularStyles = {
      color: theme.palette.grey[100],
      marginTop: "8px",
    };

    return recommended ? recommendedStyles : regularStyles;
  }
);

export const SelectedMark = styled("div")({
  position: "absolute",
  width: "24px",
  height: "24px",
  backgroundColor: "white",
  borderColor: theme.palette.primary.main,
  borderWidth: "8px",
  borderStyle: "solid",
  borderRadius: "12px",
  right: "16px",
  top: "12px",
});
