import illustration from "src/assets/images/native_app_homescreen_illustration.webp";
import { useVisibility } from "../utils/useVisibility";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import RitualAnalytics from "client-analytics";
import { Background, Wrapper, Text, ActionButton } from "./styles";

export const SunsetPopup = () => {
  const { showSunsetPopup } = useFlags();
  const visible = useVisibility();

  useEffect(() => {
    if (showSunsetPopup && !visible) {
      RitualAnalytics.track("member_popup_native2_show");
    }
  }, [showSunsetPopup, visible]);

  if (!showSunsetPopup || visible) {
    return <></>;
  }

  const onClick = () => {
    if (process.env.REACT_APP_NATIVE_APP_STORE_LINK) {
      window.location.href = process.env.REACT_APP_NATIVE_APP_STORE_LINK;
    }
  };

  return (
    <Background>
      <Wrapper>
        <img src={illustration} />
        <Text variant="headline3">
          OurRitual can now be accessed only through our app
        </Text>
        <Text>
          Our services are no longer available through mobile browsers. You can
          continue right where you left off by downloading the app.
        </Text>
        <ActionButton id="download-app" onClick={onClick}>
          Download App
        </ActionButton>
      </Wrapper>
    </Background>
  );
};
