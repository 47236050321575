import { useEffect, Fragment } from "react";
import { Circle, Divider, Wrapper } from "./styles";
import { Check } from "src/assets/icons";

const DIVIDER_GAP = 22;

const ProgressLine = ({
  prefix = "",
  completedTasks,
}: {
  prefix?: string;
  completedTasks: { relation_cms_id: number; completed: boolean }[];
}) => {
  const setDividerHeight = (gwCard: HTMLElement, i: number) => {
    const divider = document.getElementById(`${prefix}divider-${i}`);
    if (divider) {
      const image = gwCard.querySelector(
        `[id^="card_image"]`
      ) as HTMLImageElement;
      if (image.complete)
        divider.style.height = gwCard.clientHeight - DIVIDER_GAP + "px";
      else
        image.onload = () => {
          divider.style.height = gwCard.clientHeight - DIVIDER_GAP + "px";
        };
    }
  };

  useEffect(() => {
    for (let i = 0; i < completedTasks.length; i++) {
      const elementID = `${prefix}growthwork_card-${i}`;
      const gwCard = document.getElementById(elementID);
      if (gwCard) setDividerHeight(gwCard, i);
      else {
        const observer = new MutationObserver(function (_, instance) {
          const div = document.getElementById(elementID);
          if (div) {
            setDividerHeight(div, i);
            instance.disconnect();
          }
        });

        observer.observe(document, {
          childList: true,
          subtree: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedTasks, prefix]);

  return (
    <Wrapper>
      {completedTasks.map(({ completed }, i) => {
        return (
          <Fragment key={i}>
            {!completed ? (
              <Circle
                sx={{
                  borderColor: completed ? "primary.main" : "grey.50",
                }}
              />
            ) : (
              <Check />
            )}

            {i < completedTasks.length - 1 && (
              <Divider id={`${prefix}divider-${i}`} disabled={!completed} />
            )}
          </Fragment>
        );
      })}
    </Wrapper>
  );
};

export default ProgressLine;
