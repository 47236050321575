import styled from "@emotion/styled";
import Typography from "../Typography";
import { Button } from "../Button";

export const Wrapper = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
});

export const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "auto 24px",
  backgroundColor: "white",
  borderRadius: "20px",
  padding: "16px 16px 24px 16px",
});

export const Illustration = styled("img")({
  width: "100%",
});

export const Title = styled(Typography)({
  marginTop: "24px",
  textAlign: "center",
});

export const Subtitle = styled(Typography)({
  marginTop: "16px",
  marginBottom: "32px",
  textAlign: "center",
});

export const SecondaryButton = styled(Button)({
  marginTop: "16px",
});

export const CloseButton = styled("div")({
  marginLeft: "auto",
});
