import React from "react";
import { ProfileItemsWrapper } from "../styles";
import {
  ExpertCard,
  CurrentPathwayCard,
} from "src/components/ProfileItemCard/Cards";

const ProfileItemList = () => {
  return (
    <ProfileItemsWrapper>
      <CurrentPathwayCard />
      <ExpertCard />
    </ProfileItemsWrapper>
  );
};

export default ProfileItemList;
