import { ReactNode } from "react";
import { CarouselContent } from "src/api/types";
import { Button } from "src/components";
import { Content, Header, Wrapper } from "./styles";

interface LayoutProps extends CarouselContent {
  children?: ReactNode;
  onClick: () => void;
}

const Layout = ({
  image,
  button_text,
  background_color,
  children,
  step,
  onClick,
}: LayoutProps) => {
  return (
    <Wrapper>
      <Header sx={{ backgroundColor: background_color }}>
        <img src={image} alt="carousel_image" />
      </Header>
      <Content>
        {children}
        <Button id="carousel_button" onClick={onClick}>
          {button_text}
        </Button>
      </Content>
    </Wrapper>
  );
};

export default Layout;
