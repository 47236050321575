import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  textAlign: "center",
  padding: "10px",
  paddingTop: "30px",
  paddingBottom: "56px",
  width: "100%",
  height: "100%",
  backgroundColor: "white",
});

export const Illustration = styled("img")({
  display: "block",
  margin: "auto",
  marginTop: "30px",
  marginBottom: "60px",
  height: "35vh",
});

export const TextWrapper = styled("div")({
  maxWidth: "580px",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

export const ContactUsWrapper = styled("div")({
  marginTop: "8px",
  display: "flex",
  justifyContent: "center",
  gap: "6px",
});
