import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import {
  useGetCompletedWelcomeSessionQueryResolver,
  useGetSurveyQueryResolver,
} from "src/api/resolvers";
import {
  COMPLETE_PROFILE_FORM_ID,
  PERSONALIZATION_FORM_ID,
} from "src/constants/survey";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import useLocationState from "src/hooks/useLocationState";

interface ContextValue {
  isCompleteProfileModalVisible: boolean;
  manageModal: () => void;
  isSurveyCompleted: boolean;
  finishSurvey: () => void;
}

const CompleteProfileContext = createContext<ContextValue>({} as ContextValue);

export const useCompleteProfile = () => useContext(CompleteProfileContext);

const CompleteProfileProvider = ({ children }: { children: ReactNode }) => {
  const [isCompleteProfileModalVisible, setIsCompleteProfileModalVisible] =
    useState(false);
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);
  const { appOnboardingQuestionnaire } = useFlags();

  const {
    data: completeProfileSurvey,
    isLoading: isCompleteProfileSurveyLoading,
  } = useGetSurveyQueryResolver(
    appOnboardingQuestionnaire
      ? PERSONALIZATION_FORM_ID
      : COMPLETE_PROFILE_FORM_ID
  );
  const { showMemberDemographicInformation } = useFlags();
  const { data: isWelcomeSessionEnded } =
    useGetCompletedWelcomeSessionQueryResolver();
  const navigate = useNavigate();
  const redirect = useLocationState<string>("redirect", true);

  useEffect(() => {
    if (showMemberDemographicInformation) {
      if (
        !isWelcomeSessionEnded &&
        !isCompleteProfileSurveyLoading &&
        !completeProfileSurvey
      ) {
        setIsSurveyCompleted(false);
      } else {
        setIsSurveyCompleted(true);
      }
    } else {
      setIsSurveyCompleted(true);
    }
  }, [
    completeProfileSurvey,
    isCompleteProfileSurveyLoading,
    isWelcomeSessionEnded,
    showMemberDemographicInformation,
  ]);

  const manageModal = () => {
    setIsCompleteProfileModalVisible((prev) => !prev);
  };

  const finishSurvey = () => {
    manageModal();
    setIsSurveyCompleted(true);
    navigate(redirect || ROUTES.HOME);
  };

  const value = {
    isCompleteProfileModalVisible,
    manageModal,
    finishSurvey,
    isSurveyCompleted,
  };

  return (
    <CompleteProfileContext.Provider value={value}>
      {children}
    </CompleteProfileContext.Provider>
  );
};

export default CompleteProfileProvider;
