import { Typography, styled } from "@mui/material";
import { Button as ButtonComponent } from "src/components";
import theme from "src/theme";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  backgroundColor: "white",
  borderRadius: "5px",

  marginTop: "15px",
});

export const SessionDate = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "12px",
  borderTopLeftRadius: "5px",
  borderBottomLeftRadius: "5px",

  p: {
    fontWeight: 600,
  },
});

export const SessionTime = styled(Typography)({
  margin: "7px 0",
});

export const SessionDuration = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "7px",
  alignItems: "center",
});

export const Content = styled("div")({
  flex: 1,
  padding: "15px",

  p: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 400,
    color: theme.palette.grey[600],
  },
});

export const TopRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

export const ButtonsRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  marginTop: "20px",
});

export const Button = styled(ButtonComponent)({
  flex: 1,
});
