import { styled } from "@mui/material";
import { LayoutWithBackButton, Typography } from "src/components";
import theme from "src/theme";

export const Layout = styled(LayoutWithBackButton)({
  paddingLeft: "24px",
  paddingRight: "24px",
  backgroundColor: theme.palette.grey.A700,
});

export const Title = styled(Typography)({
  marginTop: "8px",
});

export const Caption = styled(Typography)({
  marginTop: "23px",
  color: theme.palette.grey[100],
  textAlign: "center"
});

export const WrapperButton = styled("div")({
  padding: "24px",
  borderRadius: "20px",
  marginTop: "auto",
  marginBottom: "24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});