import React from "react";
import { Typography } from "ritual-ui";
import { useGetPartGrowthworksResolver } from "src/api/resolvers";
import useLocationState from "src/hooks/useLocationState";
import Content from "./components/Content";
import { Layout, Image, ContentWrapper } from "./styles";
import image from "src/assets/images/background_journal.webp";
import { useGetJournalResultQuery } from "src/api/main";
import Loading from "src/components/Loading";

const JournalResponse = () => {
  const growthwork = useLocationState<{ uuid: string; cmsId: number }>(
    "growthwork"
  );

  const growthworks = useGetPartGrowthworksResolver();
  const details = growthworks.find(
    ({ relation_cms_id }) => growthwork?.cmsId === relation_cms_id
  );

  const { data, isFetching } = useGetJournalResultQuery(
    growthwork?.uuid || "",
    {
      skip: !growthwork?.uuid,
    }
  );

  if (isFetching) {
    return <Loading />;
  }

  return (
    <Layout>
      <Typography variant="headline3">{details?.gw_short_title}</Typography>
      <ContentWrapper>{data && <Content {...data} />}</ContentWrapper>
      <Image src={image} />
    </Layout>
  );
};

export default JournalResponse;
