import React from "react";
import { ROUTES } from "src/types/routes";
import { Description, SupportButton, Wrapper } from "./styles";
import theme from "src/theme";
import { useNavigate } from "react-router-dom";

const SupportFooter = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Description color={theme.palette.grey.A200}>
        You can manage your subscription by contacting Support
      </Description>
      <SupportButton
        id="subscription-support-redirect"
        color="outlined-primary"
        onClick={() => navigate(ROUTES.CONTACT_US)}
      >
        Contact support
      </SupportButton>
    </Wrapper>
  );
};

export default SupportFooter;
