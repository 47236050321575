import { Typography, Button } from "src/components";
import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  padding: "16px 20px",
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

export const Content = styled("div")({
  flex: 1,
  alignItems: "center",
});

export const Illustration = styled("img")({
  width: "375px",
  height: "210px",
});

export const Title = styled(Typography)({
  textAlign: "center",
  marginTop: "32px",
});

export const Body = styled(Typography)({
  textAlign: "center",
  fontWeight: 400,
  color: "#575961",
  fontSize: "16px",
  marginTop: "16px",
});

export const SecondaryButton = styled(Button)({
  marginTop: "24px",
});
