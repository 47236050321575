import { styled } from "@mui/material";
import { Close } from "src/assets/icons";

export const Wrapper = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",

  paddingTop: "23px",
  paddingBottom: "12px",

  borderBottom: "1px solid black",

  position: "relative",
});

export const CloseButton = styled(Close)({
  position: "absolute",
  left: "20px",
});
