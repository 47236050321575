import React from "react";
import { Text } from "./styles";
import { SUPPORT_EMAIL } from "src/constants/support";

const LoginErrorFooter = () => {
  return (
    <Text>
      If you believe this is an error, reach out{"\n"}to our{" "}
      <a href={SUPPORT_EMAIL}>Support team</a>
    </Text>
  );
};

export default LoginErrorFooter;
