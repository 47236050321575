import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "src/components/Loading";
import { ROUTES } from "src/types/routes";

const LoginRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const qp = new URLSearchParams(location.search);
    const path = qp.get("path") || ROUTES.HOME;
    navigate(path);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default LoginRedirect;
