import { styled } from "@mui/material";
import theme from "src/theme";

export const Headline = styled("h1")({
  fontFamily: "Garnett",
  fontWeight: 500,
  fontSize: "32px",
  lineHeight: "45px",
  letterSpacing: "0.01em",

  [theme.breakpoints.down("sm")]: {
    fontSize: "22px",
    lineHeight: "30px",
  },
});

export const Headline2 = styled("h2")({
  fontFamily: "Garnett",
  fontWeight: 500,
  fontSize: "26px",
  lineHeight: "36px",
  letterSpacing: "0.26px",
  color: "#131414",
});

export const Headline3 = styled("h3")({
  fontFamily: "Garnett",
  fontWeight: 500,
  fontSize: "22px",
  lineHeight: "30px",
  letterSpacing: "0.01em",

  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    lineHeight: "25px",
  },
});

export const Headline4 = styled("h4")({
  fontFamily: "Garnett",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "25px",
  letterSpacing: "0.01em",
});

export const Headline5 = styled("h5")({
  color: "#9EA0A8",
  fontFamily: "Avenir Next",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "16px",
});

export const Body = styled("p")({
  fontFamily: "Avenir Next",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "26px",
  color: "#575961",

  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "18px",
  },
});

export const Body2 = styled("p")({
  fontFamily: "Avenir Next",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "18px",
});

export const Body3 = styled("p")({
  color: "#1F2222",
  fontFamily: "Avenir Next",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
});

export const BodyMedium = styled("p")({
  fontFamily: "Avenir Next",
  fontWeight: 500,
  color: "#7C7F87",
  fontSize: "16px",
  lineHeight: "24px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "20px",
  },
});

export const TypographyButton = styled("p")({
  color: theme.palette.primary.main,
  textAlign: "center",

  fontFamily: "Avenir Next",
  fontSize: "16px",

  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.064px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "20px",
  },
});

export const Caption = styled("p")({
  fontSize: "12px",
  lineHeight: "20px",
  fontFamily: "Avenir Next",
  fontWeight: 400,
  color: "#1F2222",
});
