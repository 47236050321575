import { StreamChat } from "stream-chat";
import { ChannelInfo } from "src/types/chat";

export class ChannelManager {
  streamClient: StreamChat;

  constructor(streamClient: StreamChat) {
    this.streamClient = streamClient;
  }

  async getOrCreateChannel(
    details: ChannelInfo["details"] & { initiatorId: string }
  ) {
    if (!details) {
      return null;
    }

    const { members, channelId, show, membersCount, initiatorId } = details;

    const foundChannel = await this.findChannel(
      initiatorId,
      members,
      membersCount
    );

    if (foundChannel) {
      return { channel: foundChannel, details };
    }

    if (!show) {
      return null;
    }

    const canCreateChannel = await this.allMembersConnected(
      members,
      membersCount
    );

    if (canCreateChannel) {
      const newChannel = await this.createChannel(channelId, members);
      return { channel: newChannel, details };
    } else {
      return { channel: null, details };
    }
  }

  async findChannel(
    initiatorId: string,
    members: string[],
    membersCount: number
  ) {
    const filter = {
      type: "messaging",
      members: { $in: [initiatorId] },
      member_count: membersCount,
    };

    const channels = (
      await this.streamClient.queryChannels(filter, undefined, {
        watch: false,
      })
    ).filter(({ state }) => members.every((member) => !!state.members[member]));

    return channels[0];
  }

  async allMembersConnected(members: string[], membersCount: number) {
    return members.length === membersCount;
  }

  async createChannel(channelId: string, members: string[]) {
    const newChannel = this.streamClient.channel("messaging", channelId, {
      members,
    });
    await newChannel?.create();
    return newChannel;
  }
}
