interface ParamsItems {
  matchers?: string[];
  value: string | number;
}

interface Params {
  [key: string]: ParamsItems[] | string[];
}

export const buildUrl = (model: string, params?: Params) => {
  let url = `/${model}`;

  if (process.env.REACT_APP_DEV_MODE) {
    params = params || {};
    params.publicationState = ["preview"];
  }

  if (!params) return url;

  url += "?";
  const keys = Object.keys(params);

  keys.forEach((key) => {
    if (!params?.[key]) return;

    if (url[url.length - 1] !== "?") url += "&";

    const value = params[key];
    const queryParams = value.map((item) => getQueryParam(key, item));
    url += queryParams.join("&");
  });

  return url;
};

const getQueryParam = (key: string, params: ParamsItems | string) => {
  let queryParams = key;

  if (typeof params !== "string") {
    const { value, matchers } = params;
    queryParams += `${getMarchersString(matchers)}=${value}`;
  } else queryParams += `=${params}`;

  return queryParams;
};

const getMarchersString = (matchers?: string[]) =>
  matchers?.map((item) => `[${item}]`).join("") || "";
