import React, { ReactNode } from "react";
import {
  Header,
  LeftSideContent,
  LeftSideLayout,
  LeftSideWrapper,
  RightSideWrapper,
  Wrapper,
} from "./styles";
import Stepper from "../Stepper";
import { Typography } from "src/components";
import { useLocation } from "react-router-dom";
import { ROUTES, onboardingSteps } from "src/types/routes";
import { OurRitualLogo } from "src/assets/icons";
import useOnboardingSteps from "../../utils/useOnboardingSteps";

export interface LayoutProps {
  LeftSide?: JSX.Element | ReactNode;
  RightSide?: JSX.Element | ReactNode;
  title?: string;
  subtitle?: string;
}

const Layout = ({ LeftSide, RightSide, title, subtitle }: LayoutProps) => {
  const { pathname } = useLocation();
  const step = onboardingSteps.findIndex((route) => route === pathname);
  const steps = useOnboardingSteps();

  return (
    <Wrapper>
      <LeftSideWrapper>
        <OurRitualLogo />
        <LeftSideLayout>
          {step >= 0 && pathname !== ROUTES.ONBOARDING_INITIAL && (
            <Stepper steps={steps} currentStep={step} />
          )}
          <Header>
            <Typography variant="headline">{title}</Typography>
            <Typography variant="body">{subtitle}</Typography>
          </Header>
          <LeftSideContent>{LeftSide}</LeftSideContent>
        </LeftSideLayout>
      </LeftSideWrapper>
      <RightSideWrapper>{RightSide}</RightSideWrapper>
    </Wrapper>
  );
};

export default Layout;
