import React from "react";
import Label from "../Label";
import { TextResponse as TextResponseIcon } from "src/assets/icons";
import { Typography } from "ritual-ui";
import { TextWrapper } from "./styles";
import theme from "src/theme";

const TextResponse = ({ response }: { response: string }) => {
  return (
    <div>
      <Label title="Text response" Icon={TextResponseIcon} />
      <TextWrapper>
        <Typography weight={500} color={theme.palette.grey.A200}>
          {response}
        </Typography>
      </TextWrapper>
    </div>
  );
};

export default TextResponse;
