import React from "react";
import CommonTemplate from "../CommonTemplate";
import { ScheduleSessionText, styles } from "../styles";
import { Button } from "src/components";
import image from "src/assets/images/schedule_welcome_illustration.png";
import { SessionType } from "src/api/types";
import useGetSessionLinks, {
  useGetSessionParams,
} from "../../../utils/useGetSessionLinks";
import useActions from "../../../utils/useActions";

const ScheduleWelcomeSession = () => {
  const { welcomeLink } = useGetSessionLinks();
  const { setCalendlyLink } = useActions();
  const getScheduleSessionParams = useGetSessionParams();

  const onClick = () => {
    setCalendlyLink(
      `${welcomeLink}${getScheduleSessionParams(SessionType.WELCOME)}`
    );
  };

  return (
    <CommonTemplate
      title="Time for your first expert session"
      wrapperStyle={styles.scheduleSession}
      image={image}
      Info={
        <ScheduleSessionText>
          Get to know your dedicated expert and set clear goals for your
          OurRitual journey.
        </ScheduleSessionText>
      }
    >
      <Button
        id="schedule-welcome-session"
        color="outlined-secondary"
        onClick={onClick}
      >
        Schedule first session
      </Button>
    </CommonTemplate>
  );
};

export default ScheduleWelcomeSession;
