import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { NoAccountFoundTypes } from "src/types/login";
import {
  Avatar,
  BackButton,
  BackButtonContainer,
  Heading,
  LoginWrapper,
  LogoWrapper,
  Text,
} from "src/pages/JoinSessionUnauthorized/styles";
import { ArrowRight, OurRitualLogo } from "src/assets/icons";
import image from "src/assets/images/login-failed.svg";
import { Button } from "ritual-ui";
import { Wrapper } from "src/components/ErrorScreens/MemberDoesntExist/styles";
import { isMobile } from "src/utils/isMobile";

const MemberDoesntExist = () => {
  const { logout, error } = useAuth0();
  const value = error?.message.split(":")[1];
  const { state } = useLocation() as {
    state: { type: NoAccountFoundTypes; email: string; phoneNumber: string };
  };
  const navigate = useNavigate();

  const onLogin = () => {
    logout({ returnTo: window.location.origin });
  };

  const onBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <Wrapper>
      <BackButtonContainer>
        {!isMobile() && (
          <BackButton onClick={onBackButtonClick}>
            <ArrowRight
              style={{ transform: "rotate(-180deg)", color: "black" }}
            />
          </BackButton>
        )}
        <LogoWrapper>
          <OurRitualLogo />
        </LogoWrapper>
      </BackButtonContainer>
      <LoginWrapper>
        <Heading variant="headline2">Couldn’t Log You In</Heading>
        <Avatar src={image} />
        <Text>
          We don't have an account registered with{" "}
          <strong>{state?.email || state?.phoneNumber || value}</strong>. Please
          confirm the information with which you registered, and try logging in
          again.
        </Text>

        <Button
          id={"back-to-login"}
          style={{ margin: "40px 0 24px", borderRadius: "40px" }}
          onClick={onLogin}
          data-testid="back-to-login"
        >
          Back To Log In
        </Button>

        <Button
          variant="text"
          id={"contact-support"}
          style={{ fontSize: "16px", marginTop: "12px" }}
          onClick={() =>
            (window.location.href = process.env.REACT_APP_SUPPORT_EMAIL || "")
          }
          data-testid="contact-support"
        >
          Contact support
        </Button>
      </LoginWrapper>
    </Wrapper>
  );
};

export default MemberDoesntExist;
