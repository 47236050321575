import React from "react";
import { useGetModuleCms } from "src/api/resolvers";
import ProfileItemCard from "..";

const CurrentPathwayCard = () => {
  const {data: module} = useGetModuleCms();

  return (
    <ProfileItemCard
      name="Pathway"
      title={module?.name || ""}
      image={module?.icon}
    />
  );
};

export default CurrentPathwayCard;
