import React, { Fragment } from "react";
import { Divider, ItemWrapper, StepDescription, Wrapper } from "./styles";
import { Typography } from "src/components";
import theme from "src/theme";
import { CurrentStepItem, NextStepsItem, PreviousStepsItem } from "./StepItems";

interface Step {
  name: string;
  completed?: boolean | null;
  hidden?: boolean | null;
}

interface StepperProps {
  currentStep: number;
  steps: Step[];
}

const Stepper = ({ steps, currentStep }: StepperProps) => {
  return (
    <Wrapper>
      <ItemWrapper>{steps.map(renderStep({ steps, currentStep }))}</ItemWrapper>
      <StepDescription>
        {steps.map((item, i) => (
          <Typography
            key={`${item.name}-number}`}
            color={getStepColor(i, currentStep)}
          >
            {item.name}
          </Typography>
        ))}
      </StepDescription>
    </Wrapper>
  );
};

const getStepColor = (i: number, currentStep: number) => {
  if (i === currentStep - 1) return theme.palette.primary.main;
  if (i < currentStep) return theme.palette.grey.A200;
  return theme.palette.grey[100];
};
const renderStep =
  ({ steps, currentStep }: StepperProps) =>
  (item: Step, i: number) => {
    let Component;

    if (i === currentStep - 1) {
      Component = CurrentStepItem;
    } else if (i >= currentStep) {
      Component = NextStepsItem;
    } else {
      Component = PreviousStepsItem;
    }

    return (
      <Fragment key={item.name}>
        <Component step={i + 1} />
        {i + 1 < steps.length && <Divider completed={i + 1 < currentStep} />}
      </Fragment>
    );
  };

export default Stepper;
