import React from "react";
import ScheduleWelcomeSession from "./ScheduleWelcomeSession";
import ScheduleNotAvailable from "./ScheduleNotAvailable";
import {
  useGetCompletedMatchingSessionQueryResolver,
  useGetCompletedWelcomeSessionQueryResolver, useGetMemberSubscriptionStatusQueryResolver,
  useGetSessionLinksQueryResolver,
} from "src/api/resolvers";
import ScheduleNextSession from "./ScheduleNextSession";
import ScheduleMatchingSession
  from "src/pages/Home/components/SessionSection/components/SessionView/ScheduleSession/ScheduleMatchingSession";
import {useFlags} from "launchdarkly-react-client-sdk";
import Loader from "src/pages/Home/components/SessionSection/components/Loader";

const ScheduleSession = () => {
  const { data: isWelcomeSessionEnded } =
    useGetCompletedWelcomeSessionQueryResolver();
  const { isLoading: isSubscriptionLoaded } =
    useGetMemberSubscriptionStatusQueryResolver();
  const { data: matchingSessionDetails, isLoading } =
    useGetCompletedMatchingSessionQueryResolver();
  const { data: sessionLinks } = useGetSessionLinksQueryResolver();

  if (isLoading && isSubscriptionLoaded) {
    return <Loader />;
  }

  if (matchingSessionDetails?.is_matching && !matchingSessionDetails?.is_matching_session_done){
    return <ScheduleMatchingSession />;
  }

  if (!matchingSessionDetails?.is_matching &&
        (isWelcomeSessionEnded ? false : !matchingSessionDetails?.is_matching_session_done)) {
    return <ScheduleWelcomeSession />;
  }

  if (!sessionLinks?.length) {
    return <ScheduleNotAvailable />;
  }

  return <ScheduleNextSession />;
};

export default ScheduleSession;
