import { useMemo } from "react";
import { Typography } from "@mui/material";
import { ItemWrapper, Indicator } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";

interface NavigationItemProps {
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  title: string;
  disabled?: boolean;
  redirectTo: ROUTES;
}

const NavigationItem = ({
  Icon,
  title,
  disabled,
  redirectTo,
}: NavigationItemProps) => {
  const location = useLocation();
  const isActive = useMemo(
    () => location.pathname === redirectTo,
    [redirectTo, location.pathname]
  );

  const navigate = useNavigate();
  const handleRedirect = () => {
    if (isActive || disabled) return;
    navigate(redirectTo);
  };

  return (
    <ItemWrapper
      id={`navigation-${redirectTo}`}
      isActive={isActive}
      onClick={handleRedirect}
    >
      {disabled && <Indicator>Soon</Indicator>}
      <Icon />
      <Typography>{title}</Typography>
    </ItemWrapper>
  );
};

export default NavigationItem;
