import React from "react";
import { Skeleton } from "@mui/material";

const Loader = () => {
  return (
    <Skeleton
      variant="rounded"
      width="100%"
      height="390px"
      sx={{ marginTop: "24px", borderRadius: "20px" }}
    />
  );
};

export default Loader;
