import React, { PropsWithChildren, useEffect, useState } from "react";
import ConnectionFailed from "../ErrorScreens/ConnectionFailed";
import useOfflineImage from "src/hooks/useOfflineImage";
import { ErrorStatus } from "src/pages/JoinSession/SessionStates/InvalidSession/utils";
import InvalidSession from "src/pages/JoinSession/SessionStates/InvalidSession";
import { useLocation } from "react-router-dom";

const NetworkStatusWrapper = ({ children }: PropsWithChildren) => {
  const [online, setOnline] = useState(true);
  const image = useOfflineImage("/images/connection_failed-illustration.webp");
  const sessionImage = useOfflineImage("/images/no-wify.webp");
  const location = useLocation();

  const isSessionRoute = location.pathname.includes("/session");

  useEffect(() => {
    window.ononline = () => setOnline(true);
    window.onoffline = () => setOnline(false);

    return () => {
      window.ononline = null;
      window.onoffline = null;
    };
  }, []);

  if (!online) {
    return isSessionRoute ? (
      <InvalidSession
        image={sessionImage}
        errorStatus={ErrorStatus.CONNECTION_ISSUES}
      />
    ) : (
      <ConnectionFailed illustration={image} />
    );
  }

  return <>{children}</>;
};

export default NetworkStatusWrapper;
