import { styled } from "@mui/material";
import theme, { getResponsiveValue, DESKTOP_MOCKUP_HEIGHT } from "src/theme";
import { Button, Typography } from "ritual-ui";

export const PageWrapper = styled("div")({
  paddingBottom: getResponsiveValue(100, DESKTOP_MOCKUP_HEIGHT),
  minHeight: "100vh",
  width: "100%",
  background: theme.palette.background.paper,
  padding: "40px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    padding: "16px",
    width: "100%",
  },
});

export const LoginWrapper = styled("div")({
  backgroundColor: "white",
  borderRadius: "12px",
  padding: "32px 40px",
  textAlign: "center",
  width: "600px",
  margin: "40px auto",

  [theme.breakpoints.down("sm")]: {
    padding: "20px 16px",
    width: "100%",
  },
});

export const LogoWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

export const Heading = styled(Typography)({
  fontFamily: "Noto Serif",
  fontWeight: 500,
  marginBottom: "16px",
  fontSize: "36px",

  [theme.breakpoints.down("sm")]: {
    padding: 0,
    width: "100%",
    fontSize: "24px",
    marginBottom: "8px",
  },
});

export const Text = styled(Typography)({
  fontSize: "18px",
  wordWrap: "break-word",
  overflowWrap: "break-word",
  whiteSpace: "normal",

  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
});

export const Link = styled(Button)({
  textDecoration: "underline",
  display: "inline",
  width: "fit-content",
  padding: 0,
  fontSize: "16px",
});

export const Avatar = styled("img")({
  width: "164px",
  height: "164px",
  margin: "0 auto 50px",
});

export const BackButton = styled("div")({
  width: "fit-content",
  padding: "10px 10px 7px",
  border: "1px solid #C3BEC2",
  borderRadius: "50%",
});

export const BackButtonContainer = styled("div")({
  display: "flex",
});
