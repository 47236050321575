import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useGetMemberQuery } from "src/api/main";

const useDatadogRUM = () => {
  const { data: member } = useGetMemberQuery();

  useEffect(() => {
    if (window.location.origin.includes("localhost")) return;

    datadogRum.init({
      applicationId: process.env.DATADOG_RUM_APPLICATION_ID || "",
      clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN || "",
      site: "datadoghq.com",
      service: "ritual-webapp",
      env: process.env.DATADOG_RUM_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      silentMultipleInit: true,
    });

    datadogRum.startSessionReplayRecording();
  }, []);

  useEffect(() => {
    if (member) {
      datadogRum.setUser({
        id: member.uuid,
        name: `${member.first_name} ${member.last_name}`,
        email: member.email,
      });
    }
  }, [member]);
};

export default useDatadogRUM;
