import React from "react";
import CommonTemplate from "../CommonTemplate";
import { ScheduleSessionText, styles } from "../styles";
import { Button } from "src/components";
import image from "src/assets/images/schedule_welcome_illustration.png";
import { SessionType } from "src/api/types";
import useGetSessionLinks, {
  useGetSessionParams,
} from "../../../utils/useGetSessionLinks";
import useActions from "../../../utils/useActions";

const ScheduleMatchingSession = () => {
  const { matchingLink } = useGetSessionLinks();
  const { setCalendlyLink } = useActions();
  const getScheduleSessionParams = useGetSessionParams();

  const onClick = () => {
    setCalendlyLink(
      `${matchingLink}${getScheduleSessionParams(SessionType.MATCHING)}`
    );
  };

  return (
    <CommonTemplate
      title="Schedule your matching session"
      wrapperStyle={styles.scheduleSession}
      image={image}
      Info={
        <ScheduleSessionText>
          Gain valuable insights into your relationship dynamics and how
          OurRitual works.
        </ScheduleSessionText>
      }
    >
      <Button
        id="schedule-matching-session"
        color="outlined-secondary"
        onClick={onClick}
      >
        Schedule matching session
      </Button>
    </CommonTemplate>
  );
};

export default ScheduleMatchingSession;
