import { Skeleton } from "@mui/material";
import React from "react";

const Icon = (icon: string) => () => {
  return !icon ? (
    <Skeleton variant="circular" width={140} height={140} />
  ) : (
    <img src={icon} alt="success_icon" />
  );
};

export default Icon;
