import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import AudioPlayer from "../AudioPlayer";
import { VIDEO_ASPECT } from "./constants";
import theme from "src/theme";

interface WistiaEmbedProps {
  link: string;
  onVideoWatched?: () => void;
  isAudio: boolean;
  onFinish?: () => void;
  withSkip?: boolean;
  fullScreen?: boolean;
  email?: string;
}

const WistiaEmbed = ({
  link,
  onVideoWatched,
  isAudio,
  onFinish,
  withSkip = true,
  fullScreen = true,
  email,
}: WistiaEmbedProps) => {
  const [videoState, setVideo] = useState<any>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  const videoId = useMemo(() => {
    const pathname = new URL(link).pathname;
    const split = pathname.split("/");
    return split[split.length - 1];
  }, [link]);

  useEffect(() => {
    //@ts-ignore
    window._wq = window._wq || [];
    //@ts-ignore
    _wq.push({
      id: videoId,
      options: { email: email},
      onReady: (video: any) => {
        setVideo(video);

        if (isAudio)
          video.bind("timechange", () => {
            setCurrentTime(video.time());
          });

        onVideoWatched &&
          video.bind("percentwatchedchanged", (percent: number) => {
            if (percent >= 0.1) {
              onVideoWatched && onVideoWatched();
            }
          });

        video.bind("end", function () {
          onFinish && onFinish();
          setIsPlaying(false);
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAudio, videoId, email]);

  useEffect(() => {
    const background = document.querySelector("[alt='Video Thumbnail']");
    const playButton = document.querySelector(".w-big-play-button");

    const onPlay = () => {
      if (!videoState.inFullscreen() && fullScreen) {
        videoState.play();
        videoState.requestFullscreen();
      }
    };

    background && background.addEventListener("click", onPlay);
    playButton && playButton.addEventListener("click", onPlay);

    return () => {
      background && background.removeEventListener("click", onPlay);
      playButton && playButton.removeEventListener("click", onPlay);
    };
  }, [videoState, fullScreen]);

  const handlePlayClick = () => {
    !isPlaying ? videoState.play() : videoState.pause();
    setIsPlaying((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <script src="//fast.wistia.com/assets/external/E-v1.js" async></script>
        <script
          src={`https://fast.wistia.com/embed/medias/${videoId}.jsonp`}
          async
        />
      </Helmet>
      <div
        style={{
          backgroundColor: theme.palette.grey[50],
          height: isAudio ? "0" : `calc((100vw - 40px) / ${VIDEO_ASPECT})`,
          width: "100%",
        }}
        className={`wistia_embed wistia_async_${videoId}`}
      />
      {isAudio && (
        <AudioPlayer
          title={videoState?.name() || ""}
          duration={videoState?.duration() || 0}
          currentTime={currentTime}
          isPlaying={isPlaying}
          onPlay={handlePlayClick}
          setTime={(value: number) => withSkip && videoState?.time(value)}
        />
      )}
    </>
  );
};

export default WistiaEmbed;
