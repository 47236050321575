import { useGetRecommendationsQueryResolver } from "src/api/resolvers";
import { PathwayRecommendationType } from "src/api/types";

const RECOMMENDED_MAX_COUNT = 3;

export const useGetRecommendationCount = () => {
  const { data } = useGetRecommendationsQueryResolver();

  return data?.type === PathwayRecommendationType.PERSONALIZED
    ? RECOMMENDED_MAX_COUNT
    : 1;
};
