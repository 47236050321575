import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  textAlign: "center",
  padding: "10px",
  paddingTop: "30px",
  paddingBottom: "56px",
  maxWidth: "580px",
});

export const Footer = styled("div")({
  display: "flex",
  justifyContent: "center",
  gap: "8px",
  marginTop: "10px",
  marginBottom: "20px",
});

export const QRCodeBackground = styled("div")({
  padding: "70px",
  paddingBottom: "48px",
  backgroundRepeat: "no-repeat",
  width: "fit-content",
  margin: "auto",
  marginTop: "50px",
});

export const PageWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  backgroundColor: "white",
});
