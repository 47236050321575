import { createAction, createReducer } from "@reduxjs/toolkit";

export const updateUnreadMessagesCount = createAction<number>(
  "support-chat/update-messages-count"
);

export interface State {
  unreadMessagesCount: number;
}

const initialState: State = {
  unreadMessagesCount: 0,
};

const supportChatReducer = createReducer(initialState, (builder) => {
  builder.addCase(updateUnreadMessagesCount, (state, action) => {
    state.unreadMessagesCount = action.payload;
  });
});

export default supportChatReducer;
