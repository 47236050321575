import React from "react";
import { Button, InfoLayout } from "src/components";
import SupportFooter from "../components/SupportFooter/ConnectionFailedFooter";

const ConnectionFailed = ({ illustration }: { illustration: string }) => {
  return (
    <InfoLayout
      large
      sx={{
        maxWidth: "327px",
        margin: "auto",
        ".illustration": {
          maxWidth: "200px",
        },
      }}
      title={
        <>
          Connection <span>failed</span>
        </>
      }
      subtitle={<>Check your internet connection and try again.</>}
      illustration={illustration}
      AdditionalContent={
        <Button
          id="connection_lost"
          color="primary-transparent"
          className="action_button"
          onClick={() => window.location.reload()}
        >
          Try again
        </Button>
      }
    >
      <SupportFooter />
    </InfoLayout>
  );
};

export default ConnectionFailed;
