import React from "react";
import { useNavigate } from "react-router-dom";
import LayoutWithBackButton from "src/components/LayoutWithBackButton";
import { ROUTES } from "src/types/routes";
import illustration from "src/assets/images/pathway_illustration.webp";
import {
  Wrapper,
  Content,
  Illustration,
  Title,
  Body,
  SecondaryButton,
} from "./styles";
import { Button } from "src/components";

export const PersonalizationDescription = () => {
  const navigate = useNavigate();

  const redirectToQuestionnaire = () => {
    navigate(ROUTES.COMPLETE_PROFILE, {
      state: {
        redirect: ROUTES.CALCULATING_RESULTS,
      },
    });
  };

  const redirectToPathways = () => {
    navigate(ROUTES.MODULE_SELECTION, {
      state: {
        isDefaultRecommendation: true,
      },
    });
  };

  return (
    <LayoutWithBackButton>
      <Wrapper>
        <Content>
          <Illustration src={illustration} />
          <Title variant="headline">Put me on a Pathway</Title>
          <Body variant="body3">
            But first, a few questions to better understand you and your
            relationship, so we can guide you on the right pathway.
          </Body>
        </Content>
        <div>
          <Button
            id="personalization-form-start"
            onClick={redirectToQuestionnaire}
          >
            Let’s go
          </Button>
          <SecondaryButton
            id="personalization-form-skip"
            color="text"
            onClick={redirectToPathways}
          >
            Browse Pathways
          </SecondaryButton>
        </div>
      </Wrapper>
    </LayoutWithBackButton>
  );
};
