import { styled } from "@mui/material";

export const ShowcaseButtonsWrapper = styled("div")({
  display: "flex",
  width: "100%",
  gap: "8px",
});

export const ResultProcessingWrapper = styled("div")({
  padding: "4px 8px",
  borderRadius: "8px",
  backgroundColor: "#F7F7FA",
  width: "100%",
  marginTop: "15px",
});
