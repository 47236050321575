import { Typography } from "@mui/material";
import { ModulePartGrowthworks } from "src/api/types";
import GrowthworksList from "src/components/GrowthworksList";
import useRedirectToTask from "src/hooks/useRedirectToTask";
import { Wrapper } from "../styles";

interface WistiaGrowthworksProps {
  growthworks: ModulePartGrowthworks;
}

const WistiaGrowthworks = ({ growthworks }: WistiaGrowthworksProps) => {
  const redirectToTask = useRedirectToTask();

  return (
    <>
      {Object.keys(growthworks).map((key) => (
        <Wrapper key={key}>
          <Typography variant="h2">{key}</Typography>
          {Object.values(growthworks[key]).map((item) => (
            <div key={item[0].part_goal}>
              <Typography variant="h3">{item[0].part_goal}</Typography>
              <GrowthworksList
                growthworks={item}
                prefix={item[0].part_goal}
                completedCount={item.length}
                onClick={redirectToTask}
                key={item[0].part_goal}
              />
            </div>
          ))}
        </Wrapper>
      ))}
    </>
  );
};

export default WistiaGrowthworks;
