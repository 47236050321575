import { Typography, styled } from "@mui/material";

export const SessionDay = styled(Typography)({
  fontFamily: "Avenir Next",
  fontWeight: 600,
});

export const SessionTime = styled(Typography)({
  fontFamily: "Avenir Next",
  fontWeight: 600,
  margin: "8px 0",
});
