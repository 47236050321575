import React from 'react';
import {SessionType} from 'src/api/types';
import ExpertAndPartner from './ExpertAndPartner';
import Expert from './Expert';

const Participants = ({type}: {type: SessionType}) => {
  switch (type) {
    case SessionType.CHAT:
      return <Expert />;
    case SessionType.GROUP:
      return <ExpertAndPartner />;
    default:
      return <Expert />;
  }
};

export default Participants;
