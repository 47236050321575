import React from "react";
import { mappedSections } from "./sections";
import { Typography } from "src/components";
import FieldLink from "./FieldLink";
import { ArrowRight } from "src/assets/icons";
import { ActionSection, ProfileItem, Section, Wrapper } from "./styles";
import theme from "src/theme";

const ProfileSections = ({
  isMembershipPaused, isMatching
}: {
  isMembershipPaused?: boolean;
  isMatching?: boolean;
}) => {
  return (
    <Wrapper>
      {mappedSections(isMembershipPaused, isMatching).map(({ name, fields }) => (
        <Section key={name}>
          <Typography color={theme.palette.grey[400]}>{name}</Typography>
          <div>
            {fields.map((field, i) => (
              <FieldLink {...field} key={field.id}>
                <ProfileItem last={i < fields.length - 1}>
                  <Typography variant="body3" style={{ color: ("caption" in field && field.caption.matching) ? theme.palette.primary.main : 'inherit' }}>
                    {field.name}
                    </Typography>
                  <ActionSection>
                    {"caption" in field && field.caption.condition && (
                      <Typography
                        variant="body3"
                        color={theme.palette.grey[400]}
                      >
                        {field.caption.text}
                      </Typography>
                    )}
                    <ArrowRight />
                  </ActionSection>
                </ProfileItem>
              </FieldLink>
            ))}
          </div>
        </Section>
      ))}
    </Wrapper>
  );
};

export default ProfileSections;
