import React from "react";
import LoginButton from "./CommonLoginButton";
import { Mobile, Sms } from "src/assets/icons";

interface LoginButtonProps {
  text?: string;
  loginParams?: { [key: string]: any };
}

export const LoginWithEmail = ({ text, loginParams }: LoginButtonProps) => (
  <LoginButton
    id="login_with_email"
    additionalParams={{
      sign_with_email: true,
      ...loginParams
    }}
  >
    <Sms />
    {text || "Log in using email"}
  </LoginButton>
);

export const LoginWithPhone = ({ text, loginParams }: LoginButtonProps) => (
  <LoginButton
    id="login_with_phone"
    additionalParams={{
      sign_with_sms: true,
      ...loginParams,
    }}
  >
    <Mobile />
    {text || "Log in with phone number"}
  </LoginButton>
);
