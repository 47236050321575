import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "14px 40px",
  background: "white",
  zIndex: 100,
});

export const ItemWrapper = styled("div")<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",

    svg: {
      path: {
        stroke: isActive ? "black" : theme.palette.grey[400],
      },
    },

    "svg, img": {
      width: "32px",
      height: "32px",
    },

    img: {
      borderRadius: "50%",
    },

    p: {
      fontSize: "12px",
      lineHeight: "17px",
      marginTop: "7px",
      fontWeight: 500,
      color: isActive ? "black" : theme.palette.grey[400],
    },
  })
);

export const Indicator = styled("div")(({ theme }) => ({
  position: "absolute",
  padding: "4px 8px",
  fontWeight: "800",
  fontSize: "10px",
  lineHeight: "14px",
  color: "white",
  background: theme.palette.primary.main,
  borderRadius: "6px",
  left: "75%",
  top: "-10%",
}));
