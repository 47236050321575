import { Skeleton, styled } from "@mui/material";
import React from "react";
import theme from "src/theme";

export const PathwayPickerLoader = () => (
  <Skeleton
    variant="rounded"
    sx={{
      width: "250px",
      height: "30px",
      borderRadius: "20px",
      marginLeft: "20px",
    }}
  />
);

export const LibraryGrowthworksLoader = () => (
  <div>
    <PartLoader />
    <PartLoader />
  </div>
);

const PartLoaderWrapper = styled("div")({
  backgroundColor: theme.palette.background.default,
  marginTop: "28px",
  padding: "16px 0",
});

const PartLoader = () => (
  <>
    <PartLoaderWrapper>
      <Skeleton
        variant="rounded"
        sx={{
          width: "135px",
          height: "16px",
          borderRadius: "10px",
          marginLeft: "20px",
        }}
      />
      <Skeleton
        variant="rounded"
        sx={{
          width: "330px",
          height: "36px",
          borderRadius: "10px",
          marginLeft: "20px",
          marginTop: "10px",
        }}
      />
    </PartLoaderWrapper>
    <GrowthworkLoader />
    <GrowthworkLoader />
  </>
);

export const GrowthworkLoader = ({ margin }: { margin?: number }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: margin === 0 ? "20px" : 0,
    }}
  >
    <Skeleton
      sx={{
        transform: "scale(1)",
        width: "88px",
        height: "80px",
        borderRadius: "16px",
        marginLeft: margin ?? "24px",
        marginTop: margin ?? "24px",
      }}
    />
    <div style={{ marginLeft: "16px", marginTop: "13px" }}>
      <Skeleton
        variant="rounded"
        sx={{ width: "180px", height: "36px", borderRadius: "10px" }}
      />
      <Skeleton
        variant="rounded"
        sx={{
          width: "48px",
          height: "16px",
          borderRadius: "10px",
          marginTop: "5px",
        }}
      />
    </div>
  </div>
);
