import React, { PropsWithChildren } from "react";
import { Wrapper, Heading, Title, Illustration, MainContent } from "./styles";
import { Calendar } from "src/assets/icons";
import { SxProps, Theme } from "@mui/material";

export interface CommonTemplateProps extends PropsWithChildren {
  title: string;
  image?: string;
  Info: JSX.Element;
  wrapperStyle: SxProps<Theme>;
  onClick?: () => void;
}

const CommonTemplate = ({
  title,
  Info,
  image,
  wrapperStyle,
  onClick,
  children,
}: CommonTemplateProps) => {
  return (
    <Wrapper
      sx={wrapperStyle}
      id="open-session-manager"
      onClick={onClick}
    >
      <Heading>
        <Title variant="h2">{title}</Title>
        <Calendar />
      </Heading>
      <MainContent>
        <Illustration src={image} />
        {Info}
      </MainContent>
      {children}
    </Wrapper>
  );
};

export default CommonTemplate;
