import { PropsWithChildren, useState } from "react";
import Sheet from "react-modal-sheet";

interface Props extends PropsWithChildren {
  isOpen: boolean;
  close: () => void;
  detent?: "content-height" | "full-height";
  Header?: JSX.Element;
  onCloseEnd?: () => void;
  sx?: any;
}

export const BottomPopup = ({
  detent = "content-height",
  isOpen,
  close,
  children,
  Header,
  onCloseEnd,
  sx,
}: Props) => {
  return (
    <Sheet
      isOpen={isOpen}
      onClose={close}
      detent={detent}
      onCloseEnd={onCloseEnd}
    >
      <Sheet.Container
        style={{
          boxShadow: "none",
          borderTopLeftRadius: "32px",
          borderTopRightRadius: "32px",
          ...sx,
        }}
      >
        {Header || <Sheet.Header />}
        <Sheet.Content>{children}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={close} />
    </Sheet>
  );
};

export const useSheet = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return { open, close, isOpen };
};
