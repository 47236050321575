import React, { useState } from "react";
import useAutoFullscreen from "./utils/useAutoFullscreen";
import { styled } from "@mui/material";

const VideoResponse = ({ response }: { response: string }) => {
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);

  useAutoFullscreen(videoRef);

  return <Video src={response} controls ref={(ref) => setVideoRef(ref)} />;
};

const Video = styled("video")({
  width: "100%",
});

export default VideoResponse;
