import { useGetMemberQuery } from "src/api/main";
import useLocationState from "src/hooks/useLocationState";
import { useEffect, useState } from "react";
import shortLinkGenerator, {
  dataForShortLink,
  getMemberEnrichedUrl,
} from "src/shortio/short_links_generator";

const useGenerateShortLink = () => {
  const { data: member } = useGetMemberQuery();
  const href = useLocationState<string>("href", true);
  const [shortLink, setShortLink] = useState("");

  useEffect(() => {
    constructAShortLink();
  }, []);

  const constructAShortLink = async () => {
    const origin = href || window.location.origin;
    const url = member ? getMemberEnrichedUrl(origin, member) : origin;
    const shortLink = await shortLinkGenerator(
      { ...dataForShortLink, originalURL: url },
      process.env.REACT_APP_SHORT_IO_KEY || ""
    );

    setShortLink(shortLink);
  };

  return shortLink;
};

export default useGenerateShortLink;
