import * as React from "react";
import { SVGProps } from "react";

type SvgProps = {
  size?: "small" | "normal" | "large";
};

const svgSize = {
  small: {
    size: 24,
    path1:
      "m9.02 2.84-5.39 4.2C2.73 7.74 2 9.23 2 10.36v7.41c0 2.32 1.89 4.22 4.21 4.22h11.58c2.32 0 4.21-1.9 4.21-4.21V10.5c0-1.21-.81-2.76-1.8-3.45l-6.18-4.33c-1.4-.98-3.65-.93-5 .12Z",
    path2:
      "M10.5 18h3c1.65 0 3-1.35 3-3v-3c0-1.65-1.35-3-3-3h-3c-1.65 0-3 1.35-3 3v3c0 1.65 1.35 3 3 3ZM12 9v9M7.5 13.5h9",
  },
  normal: {
    size: 32,
    path1:
      "m12.027 3.787-7.187 5.6c-1.2.933-2.173 2.92-2.173 4.426v9.88c0 3.094 2.52 5.627 5.613 5.627h15.44c3.093 0 5.613-2.533 5.613-5.613V14c0-1.613-1.08-3.68-2.4-4.6l-8.24-5.773c-1.866-1.307-4.866-1.24-6.666.16Z",
    path2:
      "M14 24h4c2.2 0 4-1.8 4-4v-4c0-2.2-1.8-4-4-4h-4c-2.2 0-4 1.8-4 4v4c0 2.2 1.8 4 4 4ZM16 12v12M10 18h12",
  },
  large: {
    size: 40,
    path1: "",
    path2: "",
  },
};

const SvgComponent = (props: SVGProps<SVGSVGElement> & SvgProps) => {
  const size = props.size || "normal";

  return (
    <svg
      width={svgSize[size].size}
      height={svgSize[size].size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d={svgSize[size].path1}
        stroke={props.stroke || "#131414"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={svgSize[size].path2}
        stroke={props.stroke || "#131414"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgComponent;
