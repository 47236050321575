import { Typography, styled } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 20px",
  backgroundColor: "white",
});

export const ChatName = styled(Typography)({
  fontWeight: 600,
  color: theme.palette.grey[600],
});

export const Status = styled(Typography)({
  color: theme.palette.grey[100],
  fontWeight: 400,
  textAlign: "center",
});

export const NameWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
