import { useEffect, useState } from "react";
import {
  useGetMemberQuery,
  useGetOrCreateChatMembersMutation,
} from "src/api/main";
import { ChannelManager } from "./ChannelManager";
import { getExpectedChannels } from "./helpers";
import { ChannelInfo } from "src/types/chat";
import { StreamChat } from "stream-chat";
import {useGetMemberPlanResolver} from "src/api/resolvers";
import {useGetPlanCategory} from "src/hooks/useGetPlanCategory";

export const useGetChannels = ({
  chatClient,
  isChatInitialized,
}: {
  chatClient: StreamChat | null;
  isChatInitialized: boolean;
}) => {
  const [memberChannels, setChannels] = useState<ChannelInfo[]>([]);
  const { data: member } = useGetMemberQuery();
  const [loading, setLoading] = useState(true);
  const planCategory = useGetPlanCategory();
  const [getOrCreateMembers] = useGetOrCreateChatMembersMutation();

  useEffect(() => {
    if (!chatClient || !member || !isChatInitialized || !planCategory) return;

    const expectedChannelsDetails = getExpectedChannels(member, planCategory);
    const manager = new ChannelManager(chatClient);

    (async function () {
      setLoading(true);
      const channels = [];

      for (const details of expectedChannelsDetails) {
        if (!details) continue;

        let members: string[];
        try {
          members = await getOrCreateMembers(details.members).unwrap();
        } catch (error) {
          members = [];
        }

        const channelInfo = await manager.getOrCreateChannel({
          ...details,
          initiatorId: member.uuid,
          members,
        });

        channelInfo && channels.push(channelInfo);
      }

      setChannels(channels);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatClient, member, isChatInitialized]);

  return { channels: memberChannels, loading };
};
