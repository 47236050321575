import { useLocation, useNavigate } from "react-router-dom";
import { Actions, AlertTypes, useSessionManagerContext } from "./reducer";
import { ROUTES } from "src/types/routes";

const useActions = () => {
  const { dispatch } = useSessionManagerContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const redirectFromDeepLink = () => {
    if (pathname !== ROUTES.HOME) {
      navigate(ROUTES.HOME);
    }
  };

  const openManagerSheet = () => {
    dispatch({ type: Actions.OPEN_MANAGER });
  };

  const closeManagerSheet = () => {
    dispatch({ type: Actions.CLOSE_MANAGER });
    redirectFromDeepLink();
  };

  const closeCalendly = () => {
    dispatch({ type: Actions.SET_CALENDLY_LINK, payload: "" });
    redirectFromDeepLink();
  };

  const setCalendlyLink = (link: string) => {
    dispatch({ type: Actions.SET_CALENDLY_LINK, payload: link });
  };

  const openTypeSelectModal = () => {
    dispatch({ type: Actions.OPEN_SELECT_TYPE });
  };

  const closeTypeSelectModal = () => {
    dispatch({ type: Actions.CLOSE_SELECT_TYPE });
    redirectFromDeepLink();
  };

  const showAlert = (payload: { alertLink: string; alertType: AlertTypes }) => {
    dispatch({ type: Actions.SHOW_ALERT, payload });
  };

  const hideAlert = () => {
    dispatch({ type: Actions.HIDE_ALERT });
  };

  return {
    openManagerSheet,
    closeManagerSheet,

    closeCalendly,
    setCalendlyLink,
    openTypeSelectModal,
    closeTypeSelectModal,

    showAlert,
    hideAlert,
  };
};

export default useActions;
