import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import { ScheduleSessionButton } from "./styles";
import { isMobile } from "src/utils/isMobile";

const ScheduleSession = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(ROUTES.SCHEDULE_POPUP);
  };

  if (!isMobile()) {
    return <></>;
  }

  return (
    <ScheduleSessionButton id="transition-page-schedule" onClick={onClick}>
      Schedule next session
    </ScheduleSessionButton>
  );
};

export default ScheduleSession;
