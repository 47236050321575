export const links = {
  matching: {
    prod: "https://calendly.com/ritual-schedule/matching-with-naomi",
    dev: "https://calendly.com/ritual-schedule/dev-matching-session",
    qa: "https://calendly.com/d/ckts-d93-zd7/matching-qa",
  },
  welcome: {
    prod: "https://calendly.com/ritual-schedule/ritual-welcome-session-onboarding",
    dev: "https://calendly.com/ritual-schedule/dev-ritual-welcome-session",
    qa: "https://calendly.com/d/cpqc-27q-pm6/ritual-welcome-session-qa",
  },
};
