import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { wistiaUrls } from "./urls";
import { WistiaDetails } from "./types";

export const wistiaApi = createApi({
  reducerPath: "wistia",
  baseQuery: fetchBaseQuery({
    baseUrl: "https:",
  }),
  endpoints: (builder) => ({
    getWistiaDetails: builder.query<WistiaDetails, string>({
      query: wistiaUrls.details,
      transformResponse: (res: WistiaDetails) => ({
        ...res,
        duration: Math.round(res.duration / 60),
      }),
    }),
  }),
});

export const { useGetWistiaDetailsQuery } = wistiaApi;
