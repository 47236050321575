import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      stroke="#D60D0D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 22.125c5.5 0 10-4.5 10-10s-4.5-10-10-10-10 4.5-10 10 4.5 10 10 10ZM12 8.125v5"
    />
    <path
      stroke="#D60D0D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.994 16.125h.01"
    />
  </svg>
)
export default SvgComponent
