import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.293 12a4.667 4.667 0 1 0 0-9.333 4.667 4.667 0 0 0 0 9.333ZM22.627 20h4c1.466 0 2.666 1.2 2.666 2.667v4c0 1.466-1.2 2.666-2.666 2.666h-4a2.674 2.674 0 0 1-2.667-2.666v-4c0-1.467 1.2-2.667 2.667-2.667Z"
      stroke="#9EA0A8"
      strokeWidth={1.5}
    />
    <path
      d="M16 6.667h3.573c2.467 0 3.614 3.053 1.76 4.68l-10.653 9.32c-1.853 1.613-.707 4.666 1.747 4.666H16"
      stroke="#9EA0A8"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.315 7.333h.015M24.648 24.667h.016"
      stroke="#9EA0A8"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
