import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={10}
      cy={10}
      r={9}
      fill="#F76666"
      stroke="#F76666"
      strokeWidth={2}
    />
    <path
      d="m6.5 9.75 2.25 2.5 5-4.75"
      stroke="#F6F6F8"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgComponent;
