import React, { useEffect, useState } from "react";
import { useGetRecommendationsQueryResolver } from "src/api/resolvers";
import { ROUTES } from "src/types/routes";
import { PathwayRecommendationType } from "src/api/types";
import { MAX_RETRY_COUNT, POOLING_INTERVAL } from "./constants";
import { Content } from "./ui/Content";
import { useNavigate } from "react-router-dom";

export const CalculatingResults = () => {
  const navigate = useNavigate();

  const [retryCount, setRetryCount] = useState(0);

  const { data, requestId } = useGetRecommendationsQueryResolver({
    pollingInterval: POOLING_INTERVAL,
  });

  useEffect(() => {
    setRetryCount((prev) => prev + 1);

    if (
      data?.type === PathwayRecommendationType.PERSONALIZED ||
      retryCount > MAX_RETRY_COUNT
    ) {
      navigate(ROUTES.MODULE_SELECTION);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, data]);

  return <Content />;
};
