import React, { PropsWithChildren } from "react";
import {
  Content,
  SessionDate,
  SessionDuration,
  SessionTime,
  TopRow,
  Wrapper,
} from "./styles";
import { Clock } from "src/assets/icons";
import { Typography } from "@mui/material";

interface SessionCardProps extends PropsWithChildren {
  dayOfWeek: string;
  date: string;
  title: string;
  duration: string;
  time: string;
  color: string;
}

const SessionCard = ({
  dayOfWeek,
  date,
  title,
  duration,
  time,
  color,
  children,
}: SessionCardProps) => {
  return (
    <Wrapper>
      <SessionDate sx={{ backgroundColor: color }}>
        <Typography>{dayOfWeek}</Typography>
        <Typography>{date}</Typography>
      </SessionDate>
      <Content>
        <TopRow>
          <Typography sx={{fontWeight: "600 !important"}}>{title}</Typography>
          <SessionDuration>
            <Clock />
            <Typography>{duration}</Typography>
          </SessionDuration>
        </TopRow>
        <SessionTime>{time}</SessionTime>
        {children}
      </Content>
    </Wrapper>
  );
};

export default SessionCard;
