import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import { requestFullscreen } from "src/utils/media/requestFullscreen";

const useAutoFullscreen = (video: HTMLVideoElement | null) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (video) {
      video.onloadedmetadata = () => {
        video.play().then(() => {
          requestFullscreen(video);
        });
      };
    }

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) navigate(ROUTES.HOME);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);
};

export default useAutoFullscreen;
