import { styled } from "@mui/material";
import Typography from "../Typography";
import theme from "src/theme";

export const ButtonWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "9px",
  padding: "8px 16px",
  borderRadius: "16px",
  border: `1px solid ${theme.palette.grey[50]}`,
  background: theme.palette.grey.A700,
  width: "fit-content",
  pointerEvents: "none",
});

export const ButtonText = styled(Typography)({
  color: theme.palette.grey.A200,
  fontSize: "14px",
  lineHeight: "20px",
  fontFamily: "Avenir Next",
  fontWeight: 500,
});
