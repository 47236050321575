import { styled } from "@mui/material";
import { LayoutWithBackButton } from "src/components";

export const Layout = styled(LayoutWithBackButton)({
  backgroundColor: "#FEFEFB",
  padding: "0 20px",
});

export const Image = styled("img")({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
});

export const ContentWrapper = styled("div")({
  zIndex: 999999,
});
