import React from "react";
import InfoLayout from "src/components/InfoLayout";
import illustration from "src/assets/images/app_not_responding-illustration.webp";
import { Button } from "src/components/Button";

const AppNotRespond = () => {
  return (
    <InfoLayout
      large
      sx={{ backgroundColor: "white" }}
      title={
        <>
          The app is <span>not responding</span>
        </>
      }
      subtitle="Try refreshing the app to resolve."
      illustration={illustration}
      AdditionalContent={
        <Button
          className="action_button"
          id="app_not_responding"
          color="primary-transparent"
          onClick={() => window.location.reload()}
        >
          Refresh app
        </Button>
      }
    />
  );
};

export default AppNotRespond;
