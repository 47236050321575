import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ROUTES } from "src/types/routes";

function useLocationState<T>(field: string, withoutRedirection?: boolean) {
  const location = useLocation();
  const state = location.state as any;
  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.[field] && !withoutRedirection) navigate(ROUTES.HOME);
    // eslint-disable-next-line
  }, [state]);

  return state?.[field] as T | undefined;
}

export default useLocationState;
