import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={73}
    height={64}
    fill="none"
    {...props}
  >
    <g
      fill="#fff"
      fillRule="evenodd"
      clipPath="url(#ourritual-small-logo)"
      clipRule="evenodd"
    >
      <path
        fillOpacity={0.7}
        d="M23 0C0 0 0 28 0 32s0 32 23 32 23-28 23-32S46 0 23 0Z"
      />
      <path
        fillOpacity={0.7}
        d="M47 0C27 0 23 8 23 18c0 10.667 7.976 25.667 23.928 45h25.269L55 36c13 0 16-12 16-18S67 0 47 0Z"
      />
      <path d="M32.713 2.102c12.808 6.348 13.27 25.142 13.286 29.472v.847c-.012 3.194-.266 14.26-5.54 22.394C28.818 39.38 23 27.108 23 17.998c0-6.9 1.904-12.848 9.655-15.873l.058-.023Z" />
    </g>
    <defs>
      <clipPath id="ourritual-small-logo">
        <path fill="#fff" d="M0 0h73v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
