import React from "react";
import { NotificationPopupLayout } from "src/components/NotificationPopupLayout";
import illustration from "src/assets/images/moving-forward.svg";

interface MovingForwardPopupProps {
  isOpened: boolean;
  closeModal: () => void;
  onClick: (id?: string) => void;
}

const MovingForward = ({
  isOpened,
  closeModal,
  onClick,
}: MovingForwardPopupProps) => {
  return (
    <NotificationPopupLayout
      modal={{ open: isOpened, onClose: closeModal }}
      illustration={illustration}
      title={"Yay for moving forward!"}
      text={
        "Our data shows that members experience the most growth if they follow the recommended Growth work order."
      }
      primaryAction={{
        text: "Go to next task",
        id: "go_the_recommended_growthwork",
        onClick: () => onClick("go_the_recommended_growthwork"),
      }}
      secondaryAction={{
        text: "Continue with my selection",
        id: "go_to_chosen_growthwork",
        onClick: () => onClick(),
      }}
    />
  );
};

export default MovingForward;
