import React from "react";
import { useGetModulesQuery } from "src/api/cms";
import { useGetMemberModulesQueryResolver } from "src/api/resolvers";
import {
  Content,
  IconWrapper,
  PathwayCard,
  PathwayName,
  Title,
} from "./styles";
import { Module } from "src/api/types";
import { setCurrentPathway } from "src/store/libraryReducer";
import { useGetLibraryState } from "src/store/selectors";
import { useAppDispatch } from "src/store/hooks";

const PathwaysLibraryList = () => {
  const dispatch = useAppDispatch();

  const { data: pathwaysCms } = useGetModulesQuery();
  const { data: pathways } = useGetMemberModulesQueryResolver();
  const { currentPathway } = useGetLibraryState();

  const selectPathway = (pathway?: Module) => () => {
    dispatch(setCurrentPathway(pathway));
  };

  return (
    <Content>
      <Title variant="body3">Show content for:</Title>
      {pathways?.map((pathway) => {
        const cmsPathway = pathwaysCms?.find(
          ({ module_id }) => module_id === pathway?.program.cms_id
        );

        return (
          <div
            id="library-select-pathway"
            key={pathway.uuid}
            onClick={selectPathway(cmsPathway)}
          >
            <PathwayCard>
              {cmsPathway?.icon && (
                <IconWrapper>
                  <img src={cmsPathway.icon} alt="pathway-icon" />
                </IconWrapper>
              )}
              <PathwayName
                variant="body3"
                active={currentPathway?.id === cmsPathway?.id}
              >
                {cmsPathway?.name}
              </PathwayName>
            </PathwayCard>
          </div>
        );
      })}
    </Content>
  );
};

export default PathwaysLibraryList;
