import { useGetMemberQuery } from "src/api/main";
import Loading from "src/components/Loading";
import SessionPageLayout from "src/components/SessionPageLayout";
import {
  SessionLogo,
  SupportText,
  SupportUrl,
  SupportWrapper,
} from "../../styles";
import {
  CanceledButton,
  CanceledText,
  CanceledTitle,
  CanceledWrapper,
} from "./styles";
import { EndStatus, EndType } from "./utils";
import { SUPPORT_EMAIL } from "src/constants/support";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";

const CanceledSession = ({ endStatus }: { endStatus: EndStatus }) => {
  const { data: member, isFetching } = useGetMemberQuery();
  const navigate = useNavigate();

  if (isFetching) {
    return <Loading />;
  }

  return (
    <SessionPageLayout memberInfo={member}>
      <CanceledWrapper>
        <SessionLogo src={EndType[endStatus].img} alt="page_illustration" />
        <CanceledTitle>{EndType[endStatus].title}</CanceledTitle>
        <CanceledText>{EndType[endStatus].text}</CanceledText>
        <CanceledButton
          id="schedule_new-session"
          onClick={() => navigate(ROUTES.HOME)}
        >
          Schedule new session
        </CanceledButton>
      </CanceledWrapper>
      {endStatus === EndStatus.CANCELLED && (
        <SupportWrapper>
          <SupportText>Think this is a mistake?</SupportText>
          <SupportUrl onClick={() => (window.location.href = SUPPORT_EMAIL)}>
            Contact support
          </SupportUrl>
        </SupportWrapper>
      )}
    </SessionPageLayout>
  );
};

export default CanceledSession;
