import { Banner } from "src/api/types";
import { StickyNotification } from "src/components";
import { openDrawer } from "src/store/drawersReducer";
import { useAppDispatch } from "src/store/hooks";
import useStateResolver from "../utils/stateResolver";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";

const CmsBanner = ({ banner }: { banner: Banner }) => {
  const state = useStateResolver(banner.state);
  const isMatchingCategory = useIsMatchingPlanCategory();
  const dispatch = useAppDispatch();

  if (!state || (isMatchingCategory)) {
    return <></>;
  }

  const onClick = () => {
    dispatch(openDrawer(banner.query_param || ""));
  };

  return (
    <StickyNotification
      definedColor={banner.color}
      Icon={() => <img src={banner.icon} alt="banner_icon" />}
      title={banner.title}
      Subtitle={<>{banner.text}</>}
      onClick={onClick}
    />
  );
};

export default CmsBanner;
