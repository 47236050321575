import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      stroke={props.stroke || "#9EA0A8"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M23.973 14.387v5.333c0 .347-.014.68-.054 1-.306 3.6-2.426 5.387-6.333 5.387h-.533c-.334 0-.654.16-.854.426l-1.6 2.134c-.706.946-1.853.946-2.56 0l-1.6-2.134c-.173-.226-.56-.426-.853-.426h-.533c-4.254 0-6.387-1.054-6.387-6.387v-5.333c0-3.907 1.8-6.027 5.387-6.334.32-.04.653-.053 1-.053h8.533c4.253 0 6.387 2.133 6.387 6.387Z"
    />
    <path
      stroke={props.stroke || "#9EA0A8"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M29.306 9.053v5.333c0 3.92-1.8 6.027-5.387 6.333.04-.32.054-.653.054-1v-5.333c0-4.253-2.134-6.387-6.387-6.387H9.053c-.347 0-.68.014-1 .054.306-3.587 2.426-5.387 6.333-5.387h8.533c4.254 0 6.387 2.133 6.387 6.387Z"
    />
    <path
      stroke={props.stroke || "#9EA0A8"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.995 17.667h.012M13.327 17.667h.012M8.66 17.667h.013"
    />
  </svg>
);
export default SvgComponent;
