import PhoneInput from "react-phone-input-2";
import "./phone_input.css";
import { ErrorIcon, Wrapper, Error, Input } from "./styles";

const InputWrapper = ({ error, ...rest }: any) => {
  return (
    <div>
      <Wrapper valid={!error}>
        <InputComponent {...rest} />
        {error && <ErrorIcon />}
      </Wrapper>
      {error && <Error>{error}</Error>}
    </div>
  );
};

const InputComponent = (props: any) => {
  if (props.type === "tel")
    return (
      <PhoneInput
        country={"us"}
        enableSearch
        value={props.value.value}
        onChange={(value) => {
          props.onChange(`+${value}`);
        }}
        inputClass="input_class"
        buttonClass="button_class"
        containerClass="container_class"
      />
    );
  return <Input {...props} onChange={(e) => props.onChange(e.target.value)} />;
};

export default InputWrapper;
