import { styled } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1,
});

export const SessionDuration = styled("div")({
  display: "flex",
  gap: "10px",
  backgroundColor: theme.palette.info.light,
  borderRadius: "50px",
  width: "fit-content",
  padding: "7px",
  marginTop: "25px",
});

export const SessionContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
});

export const WidgetWrapper = styled("div")({
  maxWidth: "450px",
  width: "100%",
  position: "relative",

  ".calendly-inline-widget": {
    maxHeight: "calc(100vh - 40px)",
    [theme.breakpoints.down("lg")]: {
      maxHeight: "initial",
    },
  },
});

export const MatchingWrapper = styled("div")({
  background: theme.palette.info.light,
  padding: "20px",

  marginLeft: "-50px",
  width: "100vw",

  marginBottom: "20px",
  marginTop: "30px",

  [theme.breakpoints.down("lg")]: {
    marginLeft: "-24px",
  },
});

export const SkipButton = styled("div")({
  marginBottom: "15px",
});

export const WelcomeWrapper = styled("div")({
  background: theme.palette.info.light,
  padding: "20px",

  marginLeft: "-50px",
  width: "100vw",

  marginBottom: "20px",
  marginTop: "30px",

  [theme.breakpoints.down("lg")]: {
    marginLeft: "-24px",
  },
});