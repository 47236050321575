import React from "react";
import { Typography } from "src/components";
import { Wrapper } from "./EmptyState";
import groupChatErrorIllustration from "src/assets/images/illustration_groupchat_error.webp";

const GroupChatDisabled = () => {
  return (
    <Wrapper>
      <img
        src={groupChatErrorIllustration}
        alt="group_disabled_illustration"
        style={{ maxWidth: "233px" }}
      />
      <Typography variant="headline4">Enable Group chat</Typography>
      <Typography>
        To enable Group Chat, ensure your{" "}
        <span style={{ fontWeight: 600 }}>
          partner has signed up and logged in within the last 48 hours.
        </span>{" "}
        If not, ask them to log in to enable this feature.
      </Typography>
    </Wrapper>
  );
};

export default GroupChatDisabled;
