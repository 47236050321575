import React, { PropsWithChildren } from "react";
import { FieldTypes } from "./sections";
import { useNavigate } from "react-router-dom";

interface FieldLinkProps extends PropsWithChildren {
  type: FieldTypes;
  id: string;
  url: string;
}

const FieldLink = ({ id, url, type, children }: FieldLinkProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    switch (type) {
      case FieldTypes.EXTERNAL: {
        window.open(url, "_blank");
        return;
      }
      case FieldTypes.IN_APP: {
        return navigate(url);
      }
    }
  };

  return (
    <div id={id} onClick={onClick}>
      {children}
    </div>
  );
};

export default FieldLink;
