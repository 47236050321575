import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#FF5B4C"
      fillRule="evenodd"
      d="M11.646 5.4C4 5.4 4 14.708 4 16.038s0 10.637 7.646 10.637 7.646-9.308 7.646-10.637c0-1.33 0-10.638-7.646-10.638Z"
      clipRule="evenodd"
    />
    <path
      fill="#FF5B4C"
      fillRule="evenodd"
      d="M19.624 5.4c-6.649 0-7.978 2.66-7.978 5.984 0 3.545 2.651 8.532 7.954 14.959H28l-5.717-8.976c4.322 0 5.319-3.989 5.319-5.983 0-1.995-1.33-5.984-7.978-5.984Z"
      clipRule="evenodd"
    />
    <path
      fill="#8C2C38"
      fillRule="evenodd"
      d="M14.874 6.1c4.258 2.11 4.412 8.357 4.417 9.797v.281c-.004 1.062-.088 4.74-1.842 7.444-3.87-5.13-5.804-9.21-5.804-12.238 0-2.294.634-4.271 3.21-5.277l.02-.008Z"
      clipRule="evenodd"
    />
  </svg>
);

export default SvgComponent;
