import React from "react";
import { Typography } from "src/components";
import emptyChatIllustration from "src/assets/images/empty_chat.png";
import { styled } from "@mui/material";

const EmptyState = () => {
  return (
    <Wrapper>
      <img src={emptyChatIllustration} alt="sms_illustration" />
      <Typography variant="headline4">No messages yet</Typography>
      <Typography>
        Get chatting with your personal OurRitual Expert as soon as you've
        completed your first session
      </Typography>
    </Wrapper>
  );
};

export const Wrapper = styled("div")({
  marginTop: "32px",
  padding: "0 12px",
  paddingBottom: "24px",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  h4: {
    marginTop: "24px",
  },

  p: {
    textAlign: "center",
    marginTop: "12px",
  },
});

export default EmptyState;
