import React from "react";
import Lottie from "lottie-react";
import loaderAnimation from "src/assets/animations/loader_animation.json";
import illustration from "src/assets/images/calculating_results_illustration.webp";
import { Illustration, Wrapper, Text } from "./styles";

export const Content = () => {
  return (
    <Wrapper>
      <Illustration src={illustration} />
      <Text variant="headline4">Analyzing your answers...</Text>
      <Lottie
        style={{ height: "240px" }}
        animationData={loaderAnimation}
        loop={true}
      />
    </Wrapper>
  );
};
