import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  width: "100%",
  height: "100vh",
});

export const ButtonWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "15px",
});
