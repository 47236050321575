import { createSlice } from "@reduxjs/toolkit";
import { Module } from "src/api/types";

export interface LibraryState {
  currentPathway?: Module;
}

const initialState = {} as LibraryState;

const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    setCurrentPathway(state, { payload }) {
      state.currentPathway = payload;
    },
  },
});

export const { setCurrentPathway } = librarySlice.actions;
export default librarySlice.reducer;
