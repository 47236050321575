import { styled } from "@mui/material";
import Typography from "../Typography";
import { ErrorMark } from "src/assets/icons";
import theme from "src/theme";

export const Input = styled("input")({
  padding: "18px 16px",
  background: "white",
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: "16px",

  fontFamily: "Avenir Next",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: theme.palette.grey.A200,

  "&::placeholder": {
    color: theme.palette.grey[100],
  },

  "&:focus": {
    borderColor: theme.palette.primary.main,
  },

  "&:not(:placeholder-shown)": {
    backgroundColor: "white",
  },
});

export const Wrapper = styled("div")<{ valid: boolean }>(({ valid }) => ({
  width: "100%",
  position: "relative",

  "& > input, .react-tel-input": {
    width: "100%",
  },

  "& > input, .input_class, .flag-dropdown": {
    color: !valid ? theme.palette.error.main : "black",
    borderColor: !valid
      ? `${theme.palette.error.main} !important`
      : theme.palette.grey[500],
  },
}));

export const Error = styled(Typography)({
  color: theme.palette.error.main,
  marginTop: "8px",
  marginLeft: "16px",
});

export const ErrorIcon = styled(ErrorMark)({
  position: "absolute",
  right: "16px",
  top: "50%",
  transform: "translate(0, -50%)",
});
