import { styled } from "@mui/material";
import { Typography } from "src/components";

export const Wrapper = styled("div")({
  padding: "48px 24px",
  textAlign: "center",
  height: "100%",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const Footer = styled("div")({
  marginTop: "auto",
  textAlign: "center",
});

export const FooterLink = styled("div")({
  display: "flex",
  gap: "6px",
});

export const Buttons = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  marginTop: "40px",
});

export const Title = styled(Typography)({
  marginTop: "48px",
});

export const Subtitle = styled(Typography)({
  marginTop: "16px",
  whiteSpace: "break-spaces",
});
