import React from "react";
import { Illustration, PageWrapper } from "src/pages/WelcomeOnboarding/styles";
import QRCodeWelcome from "src/pages/WelcomeOnboarding/components/QRCodeWelcome";
import backgroundIllustration from "src/assets/images/onboarding_welcome.svg";
import illustration from "src/assets/images/app_preview.webp";

const WelcomeOnboarding = () => {
  return (
    <PageWrapper>
      <QRCodeWelcome />
      <Illustration
        style={{ backgroundImage: `url(${backgroundIllustration})` }}
      >
        <img src={illustration} alt="app_illustration" />
      </Illustration>
    </PageWrapper>
  );
};

export default WelcomeOnboarding;
