import { styled, Theme } from "@mui/material";

const getButtonStyle = (theme: Theme) => ({
  primary: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
  },

  "primary-transparent": {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    border: "1px solid #FCC2C2",
    boxShadow: "none",
    fontFamily: "Avenir Next",
    fontWeight: 600,
  },

  secondary: {
    color: theme.palette.primary.main,
    backgroundColor: "white",
  },

  success: {
    color: "white",
    backgroundColor: theme.palette.success.main,
  },

  outlined: {
    backgroundColor: "white",
    color: theme.palette.grey.A200,
  },

  "outlined-primary": {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    border: `2px solid ${theme.palette.grey[300]}`,
    borderRadius: "12px",
    padding: "8px 0",
  },

  disabled: {
    backgroundColor: theme.palette.grey[50],
    color: "white",
    cursor: "default",
  },

  "outlined-secondary": {
    border: `2px solid ${theme.palette.grey[50]}`,
    borderRadius: "5px",
    color: "black",
    fontFamily: "Avenir Next",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "18px",
  },

  borderless: {
    fontFamily: "Avenir Next",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    padding: "4px 24px",
    color: theme.palette.primary.main,
    boxShadow: "none",

    [theme.breakpoints.down("lg")]: {
      padding: "4px 24px",
    },
  },

  text: {
    borderRadius: "16px",
    paddingVertical: "8px",
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.primary.main,
    boxShadow: "none",
  },
});

export interface ButtonProps {
  id: string;
  color?:
    | "primary"
    | "primary-transparent"
    | "secondary"
    | "success"
    | "outlined"
    | "disabled"
    | "outlined-primary"
    | "outlined-secondary"
    | "borderless"
    | "text";
}

export const Button = styled("button")<ButtonProps>(
  ({ theme, color = "primary", disabled }) => ({
    cursor: "pointer",
    borderRadius: "16px",
    padding: "20px 0",
    boxShadow: "0px 4px 15px 1px rgba(72, 51, 51, 0.05)",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",

    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,

    [theme.breakpoints.down("lg")]: {
      padding: "14px 12px",
    },

    ...getButtonStyle(theme)[color],
    ...(disabled && getButtonStyle(theme)["disabled"]),
  })
);
