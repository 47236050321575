import React from "react";
import { useNavigate } from "react-router-dom";
import { LeftArrow } from "src/assets/icons";
import { ROUTES } from "src/types/routes";

const BackButton = () => {
  const navigate = useNavigate();

  const onBackButtonClick = () => {
    navigate(ROUTES.CHAT_INITIAL);
  };

  return (
    <div onClick={onBackButtonClick} id="chat-back-button">
      <LeftArrow />
    </div>
  );
};

export default BackButton;
