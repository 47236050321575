import { useMemo } from "react";
import { useGetMemberQuery } from "src/api/main";
import { Session, SessionType } from "src/api/types";
import { useGetFirstSessionLink } from "src/utils/getFirstSessionLink";


const useGetSessionLinks = (session?: Session) => {

  const welcomeLink = useGetFirstSessionLink("welcome");
  const matchingLink = useGetFirstSessionLink("matching");

  const rescheduleLink = useMemo(() => {
    return `https://calendly.com/reschedulings/${session?.next_session_invitee_id}`;
  }, [session]);

  const cancelLink = useMemo(
    () =>
      `https://calendly.com/cancellations/${session?.next_session_invitee_id}`,
    [session]
  );

  return { rescheduleLink, cancelLink, welcomeLink, matchingLink };
};

export const useGetSessionParams = () => {
  const { data: member } = useGetMemberQuery();

  const commonParams = `&email=${member?.email}&embed_domain=any`;
  const individualSessionParams = `?name=${member?.first_name}%20${member?.last_name}`;
  const coupleSessionParams = member?.partner
    ? `?guests=${member?.partner?.email}&name=${member?.first_name}%20and%20${member?.partner?.first_name}`
    : individualSessionParams;

  const getSessionParams = (type: SessionType) => {
    let uniqueParams = "";

    switch (type) {
      case SessionType.GROUP:
        uniqueParams = coupleSessionParams;
        break;
      default:
        uniqueParams = individualSessionParams;
    }

    return (uniqueParams + commonParams).replaceAll("+", "%2B");
  };

  return getSessionParams;
};

export default useGetSessionLinks;
