import React from 'react';
import {
  useGetScheduledSessionQueryResolver,
  useGetSessionEventDetails,
} from 'src/api/resolvers';
import NextSessionTemplate from './NextSessionTemplate';
import Participants from '../../Participants';

export const NextSession = () => {
  const {data: scheduledSession} = useGetScheduledSessionQueryResolver();
  const eventDetails = useGetSessionEventDetails(scheduledSession?.type);

  if (!scheduledSession) {
    return <></>;
  }

  return (
    <NextSessionTemplate
      image={eventDetails?.image}
      wrapperStyle={{backgroundColor: eventDetails?.color}}>
      <Participants type={scheduledSession.type} />
    </NextSessionTemplate>
  );
};
