import { styled } from "@mui/material";
import theme, { DESKTOP_MOCKUP_HEIGHT, getResponsiveValue } from "src/theme";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  flex: 1,
  justifyContent: "flex-start",
  margin: `${getResponsiveValue(64, DESKTOP_MOCKUP_HEIGHT)} 0px`,

  [theme.breakpoints.down("sm")]: {
    margin: "25px 0",
  },
});

export const Divider = styled("div")<{ completed: boolean }>(
  ({ completed }) => ({
    marginLeft: "16px",
    maxHeight: "82px",
    minHeight: "30px",
    height: "100%",
    backgroundColor: completed
      ? theme.palette.primary.main
      : theme.palette.grey[500],
    width: "1px",

    [theme.breakpoints.down("lg")]: {
      maxHeight: "18px",
      flex: 1,
    },
  })
);

export const Step = styled("div")({
  padding: "15px 0",
  display: "flex",
  alignItems: "center",
});

export const StepText = styled("div")({
  marginLeft: "10px",
});
