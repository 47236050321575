import React, { useEffect, useRef, useState } from "react";
import { useGetSessionDetailsQueryResolver } from "src/api/resolvers";
import { Timer, Wrapper } from "./styles";
import { formatTime, getTimeUntilSession, isSessionStarted } from "./utils";

const Countdown = () => {
  const { data: session } = useGetSessionDetailsQueryResolver();
  const [timeLeft, setTimeLeft] = useState(
    getTimeUntilSession(session?.next_session!)
  );
  const interval = useRef<NodeJS.Timer | undefined>();

  useEffect(() => {
    const SECOND = 1000;

    if (!session) return;

    interval.current = setInterval(() => {
      setTimeLeft(getTimeUntilSession(session.next_session));
    }, SECOND);

    return () => {
      clearInterval(interval.current);
    };
  }, [session]);

  useEffect(() => {
    if (isSessionStarted(timeLeft)) {
      clearInterval(interval.current);
    }
  }, [timeLeft]);

  return (
    <Wrapper>
      <Timer>{formatTime(timeLeft)}</Timer>
    </Wrapper>
  );
};

export default Countdown;
