import { Widget } from "@typeform/embed-react";
import { useGetMemberQuery } from "src/api/main";

interface TypeformEmbedProps {
  formId: string;
  onSubmit: () => void;
  hash: string;
}

const TypeformEmbed = ({ formId, onSubmit, hash }: TypeformEmbedProps) => {
  const { data: member } = useGetMemberQuery();

  return (
    <Widget
      id={formId}
      style={{ width: "100%", height: "100%" }}
      onSubmit={onSubmit}
      hidden={{
        email: member?.email || "",
        hash,
        ...(process.env.REACT_APP_DEV_MODE ? { funnel_mode: "test" } : {}),
      }}
    />
  );
};

export default TypeformEmbed;
