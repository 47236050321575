import { Typography, styled } from "@mui/material";
import React from "react";
import {
  useGetExpertCmsDetails,
  useGetExpertQueryResolver,
} from "src/api/resolvers";

const Expert = () => {
  const { data: expertCMS } = useGetExpertCmsDetails();
  const { data: expert } = useGetExpertQueryResolver();

  return (
    <ExpertWrapper>
      {expertCMS?.avatar && (
        <Avatar src={expertCMS?.avatar} alt="expert-avatar" />
      )}
      <Typography fontWeight={400}>
        {expert?.first_name} {expert?.last_name}
      </Typography>
    </ExpertWrapper>
  );
};

export const ExpertWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});

const Avatar = styled("img")({
  width: "26px",
  height: "26px",
  borderRadius: "50%",
});

export default Expert;
