import React from "react";
import image from "src/assets/images/schedule_session_illustration.png";
import CommonTemplate from "../CommonTemplate";
import { ScheduleSessionText, styles } from "../styles";
import BookSessionButton from "../../BookSessionButton";
import useIsMatchingExpertChanged from "src/hooks/useIsMatchingExpertChanged";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";

const ScheduleNextSession = () => {
    const isMatchginExpertChanged = useIsMatchingExpertChanged();
    const isMatchingCategory = useIsMatchingPlanCategory();
    const infoMessage = isMatchginExpertChanged || !isMatchingCategory
        ? "You currently don't have any sessions scheduled with your expert."
        : "We hope you enjoyed your matching session! Now, we’ll connect you with an available Expert who aligns with your goals and preferences.";
  return (
    <CommonTemplate
      title="Time for your next session"
      image={image}
      wrapperStyle={styles.scheduleSession}
      Info={
        <ScheduleSessionText>
            {infoMessage}
        </ScheduleSessionText>
      }
    >{(isMatchginExpertChanged || !isMatchingCategory) &&
      <BookSessionButton id="schedule-next-session" color="outlined-secondary">
        Schedule your next session
      </BookSessionButton>}
    </CommonTemplate>
  );
};

export default ScheduleNextSession;
