import { styled } from "@mui/material";
import { Button, Typography } from "src/components";

export const Background = styled("div")({
  width: "100%",
  height: "100%",
  position: "fixed",
  backdropFilter: "blur(10px)",
  background: "rgba(0, 0, 0, 0.5)",
  zIndex: 100000000000,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 20px",
});

export const Wrapper = styled("div")({
  padding: "24px 16px",
  borderRadius: "20px",
  background: "white",
  width: "100%",

  img: {
    width: "100%",
  },
});

export const Text = styled(Typography)({
  marginTop: "16px",
  textAlign: "center",
  color: "#131414",
});

export const ActionButton = styled(Button)({
  marginTop: "32px",
});
