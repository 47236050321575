import { useFlags } from "launchdarkly-react-client-sdk";
import { useVisibility } from "../utils/useVisibility";
import { ActionButton, Description, Title, Wrapper } from "./styles";

export const SunsetBanner = () => {
  const { showSunsetBanner } = useFlags();
  const visible = useVisibility();

  if (!showSunsetBanner || visible) {
    return <></>;
  }

  const onClick = () => {
    if (process.env.REACT_APP_NATIVE_APP_STORE_LINK) {
      window.location.href = process.env.REACT_APP_NATIVE_APP_STORE_LINK;
    }
  };

  return (
    <Wrapper>
      <Title variant="body3">Download the OurRitual App</Title>
      <Description variant="body-medium">
        Access to OurRitual services will soon be app-only. Ensure a smooth
        transition by making the move today!
      </Description>
      <ActionButton id="banner_native" onClick={onClick}>
        Get the App
      </ActionButton>
    </Wrapper>
  );
};
