import React from "react";
import { OurRitualLogo, OurRitualLogoMark } from "src/assets/icons";
import { Typography, SxProps, Theme, Skeleton } from "@mui/material";
import { Wrapper, Title } from "./styles";
import { isMobile } from "src/utils/isMobile";

interface InfoLayoutProps {
  Icon?: () => JSX.Element;
  illustration?: string;
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  children?: any;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  AdditionalContent?: JSX.Element;
  large?: boolean;
  hideLogo?: boolean;
}

const InfoLayout = ({
  Icon = OurRitualLogoMark,
  illustration,
  title,
  subtitle,
  children,
  isLoading,
  AdditionalContent,
  sx,
  large = false,
  hideLogo,
}: InfoLayoutProps) => {
  const renderTitle = () => {
    if (isLoading)
      return (
        <Skeleton
          variant="text"
          sx={{ fontSize: "18px", width: "200px", mt: "32px" }}
        />
      );

    if (title)
      return (
        <Typography variant={!isMobile() ? "h1" : "h2"}>{title}</Typography>
      );

    return null;
  };

  return (
    <Wrapper sx={sx} large={large}>
      {!hideLogo && <OurRitualLogo />}
      <Title>
        {illustration ? (
          <img
            src={illustration}
            alt="page-illustration"
            className="illustration"
            style={{ ...(!isMobile() && { height: "35vh", maxWidth: "auto" }) }}
          />
        ) : (
          <Icon />
        )}
        {renderTitle()}
        <Typography
          variant="h3"
          sx={{ mt: title && !isLoading ? "0" : "32px" }}
        >
          {isLoading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: "16px", width: "150px" }}
            />
          ) : (
            subtitle
          )}
        </Typography>
        {AdditionalContent}
      </Title>
      <div>{children}</div>
    </Wrapper>
  );
};

export default InfoLayout;
