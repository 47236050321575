import React from "react";
import {
  Body,
  Body2,
  Body3,
  BodyMedium,
  Caption,
  Headline,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  TypographyButton,
} from "./styles";

interface TypographyProps {
  variant?:
    | "headline"
    | "headline2"
    | "headline3"
    | "headline4"
    | "headline5"
    | "body"
    | "body2"
    | "body3"
    | "body-medium"
    | "button"
    | "caption";

  weight?: number;
  color?: string;
  style?: any;
  children?: any;
  className?: string;
  id?: string;
}

const componentMapping = {
  headline: Headline,
  headline2: Headline2,
  headline3: Headline3,
  headline4: Headline4,
  headline5: Headline5,
  body: Body,
  body2: Body2,
  body3: Body3,
  "body-medium": BodyMedium,
  button: TypographyButton,
  caption: Caption,
};

const Typography = ({
  variant = "body",
  weight,
  color,
  style,
  children,
  className,
  id,
}: TypographyProps) => {
  const Tag = componentMapping[variant] as any;
  return (
    <Tag
      className={className}
      style={{
        ...(weight ? { fontWeight: weight } : {}),
        ...(color ? { color: color } : {}),
        ...style,
      }}
      id={id}
    >
      {children}
    </Tag>
  );
};

export default Typography;
