import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "16px 24px",

  h2: {
    lineHeight: "22px",
  },
});
