import React from "react";
import {
  Caption,
  CaptionWrapper,
  StatusWrapper,
  Wrapper,
} from "src/components/MembershipStatus/styles";

export interface MembershipStatusProps {
  isMembershipPaused?: boolean;
  pauseUntilDate?: string | null;
}

const MembershipStatus = ({
  isMembershipPaused,
  pauseUntilDate,
}: MembershipStatusProps) => {
  return (
    <Wrapper>
      {isMembershipPaused && (
        <>
          <StatusWrapper>
            <Caption variant="body1">Paused membership</Caption>
          </StatusWrapper>
          {pauseUntilDate && (
            <CaptionWrapper>
              <Caption variant="body1">
                Paused until{" "}
                <span style={{ fontWeight: 600 }}>{pauseUntilDate}</span>
              </Caption>
            </CaptionWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default MembershipStatus;
