import React from "react";
import illustration from "src/assets/images/complete-profile.svg";
import { NotificationPopupLayout } from "src/components";
import { useCompleteProfile } from "src/contexts/CompleteProfileContext";

export const ProfileComplete = () => {
  const { isCompleteProfileModalVisible, manageModal } = useCompleteProfile();

  return (
    <NotificationPopupLayout
      modal={{ open: isCompleteProfileModalVisible, onClose: manageModal }}
      illustration={illustration}
      title={"Your profile is complete!"}
      text={`You're ready for your first meeting. Your expert is now reviewing your details.`}
      primaryAction={{
        text: "Great",
        id: "acknowledge_complete_profile",
        onClick: manageModal,
      }}
    />
  );
};
