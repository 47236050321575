import { createAction, createReducer } from "@reduxjs/toolkit";

export const openDrawer = createAction<string>("drawers/open-drawer");
export const closeDrawer = createAction<void>("drawers/close-drawer");

export interface State {
  activeDrawer: string;
}

const initialState: State = {
  activeDrawer: "",
};

const drawersReducer = createReducer(initialState, (builder) => {
  builder.addCase(openDrawer, (state, action) => {
    state.activeDrawer = action.payload;
  });

  builder.addCase(closeDrawer, (state, action) => {
    state.activeDrawer = "";
  });
});

export default drawersReducer;
