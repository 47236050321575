import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20 7.125v10c0 4-1 5-5 5H9c-4 0-5-1-5-5v-10c0-4 1-5 5-5h6c4 0 5 1 5 5ZM14 5.625h-4"
    />
    <path
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 19.225a1.55 1.55 0 1 0 0-3.1 1.55 1.55 0 0 0 0 3.1Z"
    />
  </svg>
);
export default SvgComponent;
