import React from "react";
import { useCompleteProfile } from "src/contexts/CompleteProfileContext";
import {
  COMPLETE_PROFILE_FORM_ID,
  PERSONALIZATION_FORM_ID,
} from "src/constants/survey";
import { Widget } from "@typeform/embed-react";
import { useGetMemberQuery } from "src/api/main";
import { useFlags } from "launchdarkly-react-client-sdk";

const CompleteProfileSurvey = () => {
  const { data: member } = useGetMemberQuery();
  const { finishSurvey } = useCompleteProfile();
  const { appOnboardingQuestionnaire } = useFlags();

  return (
    <Widget
      id={
        appOnboardingQuestionnaire
          ? PERSONALIZATION_FORM_ID
          : COMPLETE_PROFILE_FORM_ID
      }
      style={{ width: "100%", height: "100%" }}
      onSubmit={finishSurvey}
      hidden={{
        ...(process.env.REACT_APP_DEV_MODE ? { funnel_mode: "test" } : {}),
        uuid: member?.uuid || "",
      }}
    />
  );
};

export default CompleteProfileSurvey;
