import { Wrapper, ControlButton, Details, Time, Track } from "./styles";
import AudioStop from "src/assets/icons/AudioStop";
import AudioPlay from "src/assets/icons/AudioPlay";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import Loader from "./Loader";

interface AudioPlayerProps {
  title: string;
  isPlaying: boolean;
  duration: number;
  currentTime: number;
  onPlay: () => void;
  setTime: (value: number) => void;
}

const AudioPlayer = ({
  title,
  isPlaying,
  duration,
  currentTime,
  onPlay,
  setTime,
}: AudioPlayerProps) => {
  dayjs.extend(durationPlugin);

  const formatTime = (seconds: number) =>
    dayjs.duration(seconds * 1000).format("mm:ss");

  if (!duration) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <ControlButton id="audio-player-button" onClick={onPlay}>
        {isPlaying ? <AudioStop /> : <AudioPlay />}
      </ControlButton>
      <Details>
        <Typography variant="h4">{title}</Typography>
        <Time>
          <Typography variant="h5">{formatTime(duration)}</Typography>
          {isPlaying && (
            <Typography variant="h5">
              -{formatTime(duration - currentTime)}
            </Typography>
          )}
        </Time>
        <Track
          value={duration ? (currentTime * 100) / duration : 0}
          onChange={(e, value) => setTime((duration * (value as number)) / 100)}
        />
      </Details>
    </Wrapper>
  );
};

export default AudioPlayer;
