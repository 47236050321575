import styled from "@emotion/styled";
import { Button, Typography } from "src/components";

export const Wrapper = styled("div")({
  marginTop: "auto",
  marginBottom: "24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const Description = styled(Typography)({
  textAlign: "center",
});

export const SupportButton = styled(Button)({
  width: 152,
  marginTop: 16,
});
