import React, { PropsWithChildren, useState } from "react";
import illustration from "src/assets/images/illustration_nomembership.svg";
import { NotificationPopupLayout } from "src/components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import { useReactivation } from "src/contexts/ReactivationContext";

export const ReactivateMembership = () => {
  const navigate = useNavigate();
  const { isReactivationVisible, manageModalReactivation } = useReactivation();

  return (
    <NotificationPopupLayout
      modal={{ open: isReactivationVisible, onClose: manageModalReactivation }}
      illustration={illustration}
      title={"Reactivate membership"}
      text={`Your membership is currently paused. To schedule a session with your relationship expert, please reactivate your membership`}
      primaryAction={{
        text: "Reactivate membership",
        id: "popup_reactivation_preview_confirmed",
        onClick: () => {
          manageModalReactivation();
          navigate(ROUTES.REACTIVATE_SUBSCRIPTION);
        },
      }}
      secondaryAction={{
        text: "Cancel",
        onClick: manageModalReactivation,
        id: "popup_reactivatemembership_declined",
      }}
    />
  );
};
