import React, { Fragment } from "react";
import { Typography } from "src/components";
import { Divider, Step, StepText, Wrapper } from "./styles";
import theme from "src/theme";
import { NextStepsItem, PreviousStepsItem } from "../Stepper/StepItems";

interface VerticalStepperProps {
  steps: { name: string; completed?: boolean | null; hidden?: boolean | null }[];
}

const VerticalStepper = ({ steps }: VerticalStepperProps) => {
  return (
    <Wrapper>
      {steps.map((item, i) => (
        <Fragment key={item.name}>
          <Step>
            {item.completed ? (
              <PreviousStepsItem />
            ) : (
              <NextStepsItem step={i + 1} />
            )}
            <StepText>
              <Typography color={theme.palette.primary.main} weight={600}>
                Step {i + 1}
              </Typography>
              <Typography color={theme.palette.grey[100]} weight={500}>
                {item.name}
              </Typography>
            </StepText>
          </Step>
          {i + 1 < steps.length && <Divider completed={!!item.completed} />}
        </Fragment>
      ))}
    </Wrapper>
  );
};

export default VerticalStepper;
