import React from "react";
import illustration from "src/assets/images/app_not_responding-illustration.webp";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import { Wrapper, Text } from "./styles";
import { Typography } from "src/components";

const ConfigurationError = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <img src={illustration} alt="error-illustration" />
      <Typography variant="headline4">Something is not right</Typography>
      <Text>
        There has been an issue configuring your pathway. Please contact our{" "}
        <span onClick={() => navigate(ROUTES.CONTACT_US)}>Support team</span>
        {"\n"}
        for assistance
      </Text>
    </Wrapper>
  );
};

export default ConfigurationError;
