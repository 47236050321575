import React from "react";
import { JournalEntryResponseType, JournalEntryResult } from "src/api/types";
import TextResponse from "./TextResponse";
import AudioResponse from "./AudioResponse";
import VideoResponse from "./VideoResponse";

const Content = ({ type, response }: JournalEntryResult) => {
  switch (type) {
    case JournalEntryResponseType.TEXT:
      return <TextResponse response={response} />;
    case JournalEntryResponseType.AUDIO:
      return <AudioResponse response={response} />;
    case JournalEntryResponseType.VIDEO:
      return <VideoResponse response={response} />;
  }
};

export default Content;
