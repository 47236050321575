import * as React from "react";

export const AudioResponse = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <mask id="a" fill="#fff">
      <path d="M8 14.62c-3.36 0-6.1-2.733-6.1-6.1V7.267c0-.26.213-.467.467-.467.253 0 .466.213.466.467V8.52a5.164 5.164 0 0 0 5.16 5.16 5.164 5.164 0 0 0 5.16-5.16V7.267c0-.26.214-.467.467-.467s.467.213.467.467V8.52c.013 3.367-2.727 6.1-6.087 6.1Z" />
    </mask>
    <path
      fill="#575961"
      d="M8 14.62c-3.36 0-6.1-2.733-6.1-6.1V7.267c0-.26.213-.467.467-.467.253 0 .466.213.466.467V8.52a5.164 5.164 0 0 0 5.16 5.16 5.164 5.164 0 0 0 5.16-5.16V7.267c0-.26.214-.467.467-.467s.467.213.467.467V8.52c.013 3.367-2.727 6.1-6.087 6.1Z"
    />
    <path
      fill="#575961"
      d="M14.087 8.52h-1v.004l1-.004ZM8 13.62a5.107 5.107 0 0 1-5.1-5.1h-2c0 3.92 3.188 7.1 7.1 7.1v-2Zm-5.1-5.1V7.267h-2V8.52h2Zm0-1.253a.532.532 0 0 1-.533.533v-2C1.57 5.8.9 6.445.9 7.267h2Zm-.533.533a.528.528 0 0 1-.534-.533h2c0-.806-.66-1.467-1.466-1.467v2Zm-.534-.533V8.52h2V7.267h-2Zm0 1.253a6.164 6.164 0 0 0 6.16 6.16v-2a4.164 4.164 0 0 1-4.16-4.16h-2Zm6.16 6.16a6.164 6.164 0 0 0 6.16-6.16h-2a4.164 4.164 0 0 1-4.16 4.16v2Zm6.16-6.16V7.267h-2V8.52h2Zm0-1.253a.532.532 0 0 1-.533.533v-2c-.796 0-1.467.645-1.467 1.467h2Zm-.533.533a.528.528 0 0 1-.533-.533h2c0-.806-.661-1.467-1.467-1.467v2Zm-.533-.533V8.52h2V7.267h-2Zm0 1.257c.01 2.81-2.278 5.096-5.087 5.096v2c3.91 0 7.102-3.18 7.087-7.104l-2 .008Z"
      mask="url(#a)"
    />
    <path
      fill="#575961"
      stroke="#575961"
      d="M9.06 7.86a.906.906 0 0 0 .24-1.782 5.205 5.205 0 0 0-2.772 0 .907.907 0 1 0 .477 1.751 3.433 3.433 0 0 1 1.816 0l.017.004.017.004c.037.007.119.023.205.023Zm1.43-2.477.001-.003a.911.911 0 0 0-.542-1.155l-.005-.002a5.966 5.966 0 0 0-4.054 0l-.006.002a.911.911 0 0 0-.542 1.155l.003.009c.176.47.696.709 1.168.533A4.098 4.098 0 0 1 9.32 5.92a.9.9 0 0 0 1.172-.537ZM4.434 5.4A3.574 3.574 0 0 1 8 1.833 3.574 3.574 0 0 1 11.567 5.4v3.127A3.574 3.574 0 0 1 8 12.093a3.574 3.574 0 0 1-3.567-3.566V5.4Z"
    />
  </svg>
);
