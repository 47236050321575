import { useEffect } from "react";
import { useGetMemberGrowthworksQuery, useGetMemberQuery } from "src/api/main";
import { MemberGrowthworkUnion } from "src/api/types";
import useLocationState from "src/hooks/useLocationState";
import { useNavigate } from "react-router-dom";
import { taskRoutesByPlatform } from "src/types/routes";
import { InfoLayout } from "src/components";

const POOLING_INTERVAL = 5000;

const PreparingPathway = () => {
  const { data: member } = useGetMemberQuery();
  const growthwork = useLocationState<MemberGrowthworkUnion>("growthwork");

  const { data: memberGrowthWork } = useGetMemberGrowthworksQuery(
    { memberId: member?.uuid || "", growthworkId: growthwork?.uuid || "" },
    {
      skip: !member?.uuid || !growthwork?.uuid,
      pollingInterval: POOLING_INTERVAL,
    }
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!growthwork) return;

    const url_hash = memberGrowthWork?.url_hash;

    if (url_hash)
      navigate(taskRoutesByPlatform[growthwork.platform], {
        state: {
          growthwork: { ...growthwork, url_hash },
        },
      });
  }, [memberGrowthWork, growthwork, navigate]);

  return <InfoLayout title="" subtitle="Preparing your Pathway..." />;
};

export default PreparingPathway;
