import React from "react";
import { GrowthworkActionProps } from "../../types";
import StartTaskButton from "../StartTaskButton";
import { Button, Typography } from "ritual-ui";
import { useGetGrowthworkDetailsQuery, useGetMemberQuery } from "src/api/main";
import { ResultProcessingWrapper, ShowcaseButtonsWrapper } from "./styles";
import theme from "src/theme";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";

const JournalActions = (props: Omit<GrowthworkActionProps, "platform">) => {
  const { completed, growthworkId } = props;

  const { data: member } = useGetMemberQuery();
  const { data: journalGrowthwork } = useGetGrowthworkDetailsQuery(
    { member_id: member?.uuid || "", cms_id: growthworkId },
    {
      skip: !completed || !member,
    }
  );

  if (!completed) {
    return <StartTaskButton {...props} />;
  }

  if (!journalGrowthwork) return <></>;

  if (journalGrowthwork.result)
    return (
      <ShowcaseActions uuid={journalGrowthwork.uuid} cmsId={growthworkId} />
    );

  if (completed && !journalGrowthwork.result) return <ResultProcessing />;

  return <></>;
};

const ShowcaseActions = ({ uuid, cmsId }: { uuid: string; cmsId: number }) => {
  const navigate = useNavigate();

  const redirectToPrompt = () => {
    navigate(ROUTES.JOURNAL_PROMPT, { state: { cmsId } });
  };

  const redirectToResponse = () => {
    navigate(ROUTES.JOURNAL_RESPONSE, {
      state: { growthwork: { uuid, cmsId } },
    });
  };

  return (
    <ShowcaseButtonsWrapper>
      <Button id="see_prompt" variant="secondary" onClick={redirectToPrompt}>
        See prompt
      </Button>
      <Button id="see_result" onClick={redirectToResponse}>
        See response
      </Button>
    </ShowcaseButtonsWrapper>
  );
};

const ResultProcessing = () => {
  return (
    <ResultProcessingWrapper>
      <Typography
        variant="body-small"
        color={theme.palette.grey[100]}
        weight={500}
      >
        We're processing your response
      </Typography>
    </ResultProcessingWrapper>
  );
};

export default JournalActions;
