import { styled } from "@mui/material";

export const Page = styled("div")({
  padding: "10px",
});

export const PlayerWrapper = styled("div")({
  height: "100%",
  display: "flex",
  overflow: "auto",
  padding: "10px",
  width: "100%",
});

export const PlayerContent = styled("div")({
  margin: "auto 0",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",

  h1: {
    fontSize: "22px",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "24px",
  },

  p: {
    marginTop: "24px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#575961",
  },
});

export const ButtonWrapper = styled("div")({
  minHeight: "52px",
  marginTop: "20px",
  marginBottom: "20px",
});
