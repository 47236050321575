import { styled } from "@mui/material";

export const PageWrapper = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

export const Content = styled("div")({
  flex: 1,
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
});

export const ContactUsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "16px 24px",
});

export const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});
