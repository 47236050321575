import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import { useGetExpertQueryResolver } from "src/api/resolvers";
import EmptyState from "./EmptyState";
import ProfileItemCard from "src/components/ProfileItemCard";
import { ChatCard, Notification } from "../styles";
import { extractMembersInfo } from "src/hooks/useGetChannels/helpers";
import { useGetMemberQuery } from "src/api/main";
import ChatAvatar from "src/components/ChatAvatar";
import { useStream } from "src/pages/Chat/contexts/StreamContext";
import theme from "src/theme";
import { ChatType } from "src/types/chat";
import GroupChatDisabled from "./GroupChatDisabled";
import {useGetPlanCategory} from "src/hooks/useGetPlanCategory";

const Chats = () => {
  const navigate = useNavigate();
  const { data: member } = useGetMemberQuery();
  const { data: expert } = useGetExpertQueryResolver();
  const { unreadCount, channels } = useStream();
  const planCategory = useGetPlanCategory();

  if (expert?.uuid === process.env.REACT_APP_RITUAL_EXPERT) {
    return <EmptyState />;
  }

  return (
    <>
      {channels.map(({ channel, details }) => {
        const { title, images } = channel
          ? extractMembersInfo(channel, member?.uuid)
          : details.error;
        const unread = unreadCount[channel?.id || ""] || 0;

        return (
          <ChatCard
            key={details.channelId}
            id={details.clickId}
            onClick={() => {
              if (channel) {
                navigate(ROUTES.CHAT, {
                  state: {
                    chat: { ...details, title, images, channelId: channel.id },
                  },
                });
              }
            }}
          >
            {!!unread && <Notification>{unread}</Notification>}
            <ProfileItemCard
              name={details?.description}
              title={title}
              ImageComponent={<ChatAvatar images={images} />}
              titleColor={
                channel ? theme.palette.grey.A200 : theme.palette.error.dark
              }
            />
          </ChatCard>
        );
      })}
      {planCategory.isCouples &&
        !channels.find(({ details }) => details.type === ChatType.GROUP)
          ?.channel && <GroupChatDisabled />}
    </>
  );
};

export default Chats;
