import { Typography, styled } from "@mui/material";
import theme from "src/theme";

export const Title = styled(Typography)({
  fontFamily: "Garnett",
  fontSize: "26px",
  lineHeight: "35px",
  color: "black",
});

export const Subtitle = styled(Typography)({
  marginTop: "24px",
  textTransform: "uppercase",
  color: theme.palette.grey[100],
});

export const ChatCard = styled("div")({
  position: "relative",

  h4: {
    fontFamily: "Avenir Next",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  img: {
    borderRadius: "50%",
  },
});

export const Notification = styled("div")({
  position: "absolute",
  right: "20px",
  top: "50%",
  transform: "translateY(-50%)",
  width: "16px",
  height: "16px",
  borderRadius: "50%",
  backgroundColor: "#FF3742",
  color: "white",
  fontSize: "11px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
