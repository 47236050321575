import React from "react";
import { Button } from "ritual-ui";
import InfoLayout from "src/components/InfoLayout";
import illustration from "src/assets/images/error-illustration.svg";
import { useAuth0 } from "@auth0/auth0-react";
import BackButton from "../ErrorLoginPaymentLayout/components/BackButton";

export const PartnerInfoMismatch = () => {
  const { logout, error } = useAuth0();

  const errorParams = error?.message.split(":")[1];
  const details = Object.fromEntries(new URLSearchParams(errorParams));
  const { login_method, phone_number, email } = details;

  const emailText = (
    <>
      email address <span>{email}</span>
    </>
  );

  const phoneText = (
    <>
      phone number <span>{phone_number}</span>
    </>
  );

  const oppositeLoginMethod =
    login_method === "email" ? "phone number" : "email";

  const loginText = login_method === "email" ? emailText : phoneText;

  const onClick = async () => {
    const oppositeLoginParam =
      login_method === "email" ? "sign_with_sms" : "sign_with_email";
    logout({
      returnTo: `${window.location.origin}/login?${oppositeLoginParam}=true`,
    });
  };

  return (
    <InfoLayout
      illustration={illustration}
      title="Couldn’t Log in"
      subtitle={
        <>
          Your {loginText} didn’t match the {login_method} your partner shared.
          For the first login only, please use your {oppositeLoginMethod} to
          protect both your&nbsp;privacies.
        </>
      }
      hideLogo
    >
      <BackButton />
      <Button id="partner_info_mismatch" onClick={onClick}>
        Log in with {oppositeLoginMethod}
      </Button>
    </InfoLayout>
  );
};
