import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useGetPartGrowthworksResolver } from "src/api/resolvers";
import { VideoAskEmbed } from "src/components";
import useLocationState from "src/hooks/useLocationState";
import { ROUTES } from "src/types/routes";
import { Close } from "./styles";

const JournalPrompt = () => {
  const navigate = useNavigate();

  const cmsId = useLocationState<number>("cmsId");

  const growthworks = useGetPartGrowthworksResolver();
  const journalGrowthworkCms = growthworks.find(
    ({ relation_cms_id }) => cmsId === relation_cms_id
  );

  const backToHome = () => {
    navigate(ROUTES.HOME);
  };

  if (!journalGrowthworkCms) return <Navigate to={ROUTES.HOME} />;

  return (
    <>
      <Close stroke="white" onClick={backToHome} />
      <VideoAskEmbed
        link={`${journalGrowthworkCms.baselink}?justvideo&fullscreen`}
      />
    </>
  );
};

export default JournalPrompt;
