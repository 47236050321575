import React from "react";
import { Button } from "src/components";
import {
  Illustration,
  MainContent,
  ScheduleSessionText,
  Wrapper,
  styles,
} from "../styles";
import image from "src/assets/images/illustration_bubble.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";

const ScheduleNotAvailable = () => {
  const navigate = useNavigate();

  return (
    <Wrapper sx={styles.scheduleSession}>
      <MainContent>
        <Illustration src={image} sx={{ height: "auto" }} />
        <ScheduleSessionText>
          Whoops, scheduling is not available right now, sorry. Please reach out
          to support.
        </ScheduleSessionText>
      </MainContent>
      <Button
        id="scheduling-contact-support"
        color="outlined-secondary"
        onClick={() => {
          navigate(ROUTES.CONTACT_US);
        }}
      >
        Contact support
      </Button>
    </Wrapper>
  );
};

export default ScheduleNotAvailable;
