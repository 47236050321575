import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round">
      <path
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M13 7.001v3M21 7.001v3M8.5 14.09h17M26 13.5V22c0 3-1.5 5-5 5h-8c-3.5 0-5-2-5-5v-8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5Z"
      />
      <path
        strokeWidth={2}
        d="M20.695 18.7h.009M20.695 21.7h.009M16.996 18.7h.009M16.996 21.7h.009M13.295 18.7h.008M13.295 21.7h.008"
      />
    </g>
    <rect width={33} height={33} x={0.5} y={0.5} stroke="#000" rx={16.5} />
  </svg>
);
export default SvgComponent;
