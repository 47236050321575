import { useAppSelector } from "./hooks";

export function useGetDrawersState() {
  return useAppSelector((state) => state.drawers);
}

export function useGetTokenState() {
  return useAppSelector((state) => state.token);
}

export function useGetFeedbackState() {
  return useAppSelector((state) => state.feedback);
}

export function useGetSupportChatState() {
  return useAppSelector((state) => state.supportChat);
}

export function useGetModuleRelationState() {
  return useAppSelector((state) => state.moduleRelation);
}

export function useGetLibraryState() {
  return useAppSelector((state) => state.library);
}
