import { styled } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")({
  height: "100%",


  marginTop: "16px",

  [theme.breakpoints.down("sm")]: {
    marginTop: "0",
    flex: 1,
  },

  button: {
    marginTop: "40px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
});

