import { styled } from "@mui/material";
import theme from "src/theme";
import Typography from "../../../../components/Typography";

export const Wrapper = styled("div")({
  margin: "10px 20px",
  border: `1px solid #fff`,
  padding: "12px 16px 16px",
  position: "relative",
  backgroundColor: "#fff",
  borderRadius: "24px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const Illustration = styled("img")({
  maxWidth: "155px",
  margin: "auto",
});

export const Title = styled(Typography)({
  marginTop: "8px",
  textAlign: "center",
});

export const Description = styled(Typography)({
  margin: "8px 0",
  textAlign: "center",
});

export const TimeDisplay = styled(Typography)({
  color: theme.palette.grey[600],
});
