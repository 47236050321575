import React from "react";
import { Typography } from "ritual-ui";
import { Wrapper } from "./styles";
import theme from "src/theme";

interface LabelProps {
  Icon: () => JSX.Element;
  title: string;
}

const Label = ({ Icon, title }: LabelProps) => {
  return (
    <Wrapper>
      <Icon />
      <Typography
        variant="body-small"
        weight={600}
        color={theme.palette.grey[100]}
      >
        {title}
      </Typography>
    </Wrapper>
  );
};

export default Label;
