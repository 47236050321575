import { Channel } from "stream-chat";

export type ChatMember = { id: string; name?: string; picture?: string };

export enum ChatType {
  INDIVIDUAL = "individual",
  GROUP = "group",
}

export interface Chat {
  channelId: string;
  description: string;
  images: string[];
  members: string[];
  readOnly: boolean;
  title: string;
}

export interface ChannelInfo {
  channel: Channel | null;
  details: {
    channelId: string;
    members: string[];
    description: string;
    readOnly: boolean;
    show: boolean;
    membersCount: number;
    clickId: string;
    error: {
      title: string;
      images: string[];
    };
    type: ChatType;
  };
}
