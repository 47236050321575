import React from "react";
import {
  FooterButton,
  FooterSubtitle,
  FooterTitle,
  FooterWrapper,
} from "./styles";

interface FooterProps {
  title: string;
  subtitle: string;
  onClick: () => void;
  button: string;
  src?: string;
}

const Footer = ({
  title,
  subtitle,
  onClick,
  button,
  src,
}: FooterProps) => {
  return (
    <FooterWrapper>
      <FooterTitle>{title}</FooterTitle>
      <FooterSubtitle>{subtitle}</FooterSubtitle>
      <FooterButton
        id="footer-click"
        color="outlined-primary"
        onClick={onClick}
      >
        {button}
      </FooterButton>
      <img src={src} alt="footer-illustration" />
    </FooterWrapper>
  );
};

export default Footer;
