import { styled } from "@mui/material";
import { Typography } from "src/components";
import theme from "src/theme";

export const Wrapper = styled("div")({
  backgroundColor: theme.palette.background.default,
});

export const PartDescription = styled(Typography)({
  fontFamily: "Avenir Next",
  fontWeight: 400,
  marginTop: "10px",
  color: theme.palette.grey.A200,
});

export const GrowthworksContainer = styled("div")({
  marginTop: "28px",
  flex: 1,
  backgroundColor: theme.palette.background.default,
});

export const Title = styled(Typography)({
  marginTop: "16px",
  marginBottom: "12px",
  fontSize: "26px",
  lineHeight: "35px",
  marginLeft: "20px",
});

export const PartTitleWrapper = styled("div")({
  padding: "16px 24px",
});
