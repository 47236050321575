import { styled } from "@mui/material";
import { Typography } from "src/components";
import theme from "src/theme";

export const Content = styled("div")({
  backgroundColor: "white",
  padding: "24px",
  paddingBottom: 0,
});

export const GrowthworkWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  alignItems: "center",
  paddingBottom: "24px",
  pointerEvents: "none",
});

export const GrowthworkImage = styled("img")({
  height: "80px",
  width: "88px",
  borderRadius: "16px",
  objectFit: "cover",
});

export const TextWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "4px",
  alignItems: "center",
  marginTop: "2px",

  p: {
    color: theme.palette.grey[400],
  },
});

export const Title = styled(Typography)({
  maxWidth: "180px",
  color: theme.palette.grey.A200,
  fontFamily: "Avenir Next",
  fontWeight: 600,
});

export const Label = styled("div")({
  display: "flex",
  padding: "1px 8px",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  borderRadius: "6px",
  background: theme.palette.primary.main,
  color: "white",
  fontFamily: "Avenir Next",
  fontSize: "10px",
  fontWeight: "500",
  lineHeight: "16px",
  marginLeft: "4px",
});
