import * as React from "react";

export const TextResponse = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      fill="#575961"
      stroke="#575961"
      d="m13.555 3.037.007.01c.385.53.605 1.246.605 2.16v5.586c0 1.114-.33 1.943-.88 2.493-.55.55-1.38.88-2.494.88H5.207c-.118 0-.238-.005-.364-.012-.743-.049-1.339-.258-1.794-.59v-.001l-.012-.008a2.342 2.342 0 0 1-.592-.592l-.007-.01c-.384-.53-.605-1.246-.605-2.16V5.207c0-1.054.296-1.852.791-2.399.493-.545 1.232-.892 2.221-.956h.01l.01-.002a3.29 3.29 0 0 1 .342-.017h5.586c.914 0 1.63.22 2.16.605l.01.007c.23.159.433.362.592.592ZM13 5.933v-.5h-.007v-.286a1.886 1.886 0 0 0-1.886-1.887h-6.22A1.886 1.886 0 0 0 3 5.147v.786c0 .55.45 1 1 1s1-.45 1-1V5.26h2v5.48h-.687c-.55 0-1 .45-1 1s.45 1 1 1h3.374c.55 0 1-.45 1-1s-.45-1-1-1H9V5.26h2v.673c0 .55.45 1 1 1s1-.45 1-1Z"
    />
  </svg>
);
