import React from "react";
import { Wrapper } from "./style";
import { LeftArrow } from "src/assets/icons";
import { useAuth0 } from "@auth0/auth0-react";

const BackButton = () => {
  const { logout } = useAuth0();

  return (
    <Wrapper>
      <LeftArrow
        id="back-button"
        onClick={() => logout({ returnTo: window.location.origin })}
      />
    </Wrapper>
  );
};

export default BackButton;
