import * as React from "react";
import { styled } from "@mui/material";
import { SVGProps } from "react";
import { useGetSupportChatState } from "src/store/selectors";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
  const { unreadMessagesCount } = useGetSupportChatState();

  return (
    <div style={{ position: "relative" }}>
      {!!unreadMessagesCount && <Indication />}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path
          stroke="#575961"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M18 8.809c0 4.317-3.808 7.809-8.5 7.809l-1.054 1.25-.623.735a1.148 1.148 0 0 1-1.904-.256L4.4 15.056C2.337 13.628 1 11.363 1 8.809 1 4.492 4.808 1 9.5 1c3.423 0 6.38 1.863 7.707 4.54.51.993.793 2.098.793 3.269Z"
        />
        <path
          stroke="#575961"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M9 17.5c1 2 4.08 3.36 6.5 3.36l1.48 1.78c.47.57 1.37.44 1.68-.23L20 19.46c1.82-1.28 3-3.31 3-5.6 0-3.59-2.5-6.36-5-6.86M9.5 10.422v-.253c0-.82.506-1.253 1.012-1.603.494-.337.988-.77.988-1.566 0-1.108-.892-2-2-2s-2 .892-2 2M9.496 12.5h.008"
        />
      </svg>
    </div>
  );
};

const Indication = styled("div")({
  width: "8px",
  height: "8px",
  borderRadius: "4px",
  backgroundColor: "red",
  position: "absolute",
  right: 0,
  zIndex: 100,
});

export default SvgComponent;
