import React, { useEffect } from "react";
import LayoutWithoutHeader from "src/components/LayoutWithoutHeader";
import { useGetModuleCms } from "src/api/resolvers";
import { Title } from "./styles";
import PathwayPicker from "./components/PathwayPicker";
import WistiaGrowthworks from "./components/WistiaGrowthworks";
import { setCurrentPathway } from "src/store/libraryReducer";
import { useGetLibraryState } from "src/store/selectors";
import { useAppDispatch } from "src/store/hooks";

const Library = () => {
  const dispatch = useAppDispatch();
  const { data: pathway } = useGetModuleCms();
  const { currentPathway } = useGetLibraryState();

  useEffect(() => {
    if (!currentPathway) {
      dispatch(setCurrentPathway(pathway));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathway, currentPathway]);

  return (
    <LayoutWithoutHeader
      sx={{
        "#content": {
          padding: 0,
          backgroundColor: "white",
        },
      }}
    >
      <div>
        <Title variant="headline2">Library</Title>
        <PathwayPicker />
        <WistiaGrowthworks />
      </div>
    </LayoutWithoutHeader>
  );
};

export default Library;
