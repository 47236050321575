import React, { useEffect, useState } from "react";
import { useGetUpcomingSessions } from "src/api/resolvers";
import { BookSessionCalendar } from "src/assets/icons";
import SessionCard from "../SessionCard";
import BookSessionButton from "../../BookSessionButton";
import { Typography, styled } from "@mui/material";
import FiltersRow from "../FiltersRow";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";

const UpcomingSessions = () => {
  const { data: upcomingSessions } = useGetUpcomingSessions();
  const [sessionsToShow, setSessionsToShow] = useState(upcomingSessions);
  const [type, setType] = useState<number | null>(null);
  const isMatchingCategory = useIsMatchingPlanCategory();


  useEffect(() => {
    if (type) {
      setSessionsToShow(upcomingSessions?.filter((item) => item.type === type));
    } else {
      setSessionsToShow(upcomingSessions);
    }
  }, [type, upcomingSessions]);

  return (
    <Wrapper>
      <Typography variant="h2">Upcoming sessions</Typography>
      <FiltersRow type={type} setType={setType} />
      {!isMatchingCategory && (
        <BookSessionButton color="outlined-secondary">
          <BookSessionCalendar />
          Book your next session
        </BookSessionButton>
      )}
      {sessionsToShow?.map((session) => (
        <SessionCard key={session.uuid} session={session} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  marginTop: 40
});

export default UpcomingSessions;
