import { styled } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

export const Header = styled("div")({
  padding: "34px 24px 0 24px",
  minHeight: "309px",
  display: "flex",
  alignItems: "flex-end",

  img: {
    width: "100%",
  },
});

export const Content = styled("div")({
  padding: "24px 32px",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  background: theme.palette.grey.A700,
  button: {
    marginTop: "auto",
  },
});

export const RitualLogo = styled("div")({
  display: "flex",
  width: "100%",
  padding: "17px 0",
  backgroundColor: theme.palette.grey[700],
  justifyContent: "center",
});
