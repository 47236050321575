import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.3 9h-4.05v6.25c0 .41-.34.75-.75.75s-.75-.34-.75-.75V9H7.7c-3.2 0-5.2 2-5.2 5.2v2.59C2.5 20 4.5 22 7.7 22h9.59c3.2 0 5.2-2 5.2-5.2v-2.6C22.5 11 20.5 9 17.3 9ZM13.25 4.56l2.07 2.07c.15.15.34.22.53.22s.38-.07.53-.22c.29-.29.29-.77 0-1.06l-3.35-3.35a.754.754 0 0 0-1.06 0L8.62 5.57c-.29.29-.29.77 0 1.06.29.29.77.29 1.06 0l2.07-2.07V9h1.5V4.56Z"
      fill="#F76666"
    />
  </svg>
);

export default SvgComponent;
