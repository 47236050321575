import React from "react";
import CardTemplate from "./CardTemplate";
import { Session } from "src/api/types";
import dayjs from "dayjs";
import { useGetSessionEventDetails } from "src/api/resolvers";
import Participants from "../../Participants";
import ManageSessionButtons from "./ManageSessionButtons";

const SessionCard = ({ session }: { session: Session }) => {
  const dayjsDate = dayjs(session.next_session);
  const sessionEventDetails = useGetSessionEventDetails(session.type);

  return (
    <CardTemplate
      dayOfWeek={dayjsDate.format("ddd")}
      date={dayjsDate.format("MMM DD")}
      title={`${sessionEventDetails?.short_title} session`}
      duration={sessionEventDetails?.duration || ""}
      color={sessionEventDetails?.color || ""}
      time={dayjsDate.format("hh:mm A ({}Z)").replace("{}", "GMT")}
    >
      <Participants type={session.type} />
      <ManageSessionButtons session={session} />
    </CardTemplate>
  );
};
export default SessionCard;
