import { styled } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")({
  marginTop: "40px",
});

export const Section = styled("div")({
  marginBottom: "32px",
});

export const ProfileItem = styled("div")<{ last: boolean }>(({ last }) => ({
  padding: "16px 0",
  borderBottomColor: theme.palette.grey[50],
  borderBottomWidth: last ? "1px" : "0px",
  borderBottomStyle: "solid",
  flexDirection: "row",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ActionSection = styled("div")({
  display: "flex",
});
