import { useCompletePart } from "src/api/resolvers";
import { Drawer } from "src/api/types";
import DefaultDrawer from "./DefaultDrawer";
import { useAppDispatch } from "src/store/hooks";
import { closeDrawer } from "src/store/drawersReducer";

const CompletePartDrawer = ({ drawer }: { drawer: Drawer }) => {
  const completePart = useCompletePart();
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    await completePart();
    dispatch(closeDrawer());
  };

  return <DefaultDrawer drawer={drawer} onClick={handleClick} />;
};

export default CompletePartDrawer;
