import React from "react";
import { Widget } from "@typeform/embed-react";
import {
  useGetCompletedWelcomeSessionQueryResolver,
  useGetExpertQueryResolver,
  useGetScheduledSessionQueryResolver,
} from "src/api/resolvers";
import { mainApi, useGetMemberQuery } from "src/api/main";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import { SessionType } from "src/api/types";
import { useAppDispatch } from "src/store/hooks";

const ADD_PARTNER_TYPEFORM_ID = "yrVGmjf8";

const AddPartnerForm = () => {
  const { data: expert } = useGetExpertQueryResolver();
  const { data: member } = useGetMemberQuery();
  const { data: scheduledSession } = useGetScheduledSessionQueryResolver();
  const { data: isWelcomeSessionEnded } =
    useGetCompletedWelcomeSessionQueryResolver();

  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const setPartner = () => {
    appDispatch(
      mainApi.util.updateQueryData("getMember", undefined, (draft) => {
        if (draft) {
          draft.partner = {} as any;
        }
      })
    );
  };

  const onSubmit = () => {
    setPartner();
    navigate(ROUTES.HOME);
  };

  if (!member) return <></>;

  return (
    <Widget
      id={ADD_PARTNER_TYPEFORM_ID}
      style={{ width: "100%", height: "100%" }}
      onSubmit={onSubmit}
      hidden={{
        expert_email: expert?.email || "",
        partnerrefer: member.email,
        ...(process.env.REACT_APP_DEV_MODE ? { funnel_mode: "test" } : {}),
        partner_email: member.email,
        partner_uuid: member.uuid,
        partner_first_name: member.first_name,
        partner_last_name: member.last_name,
        expert_name: expert?.first_name || "",
        expert_id: expert?.uuid || "",
        primary_member_id: member.uuid,
        has_scheduled_welcome_session: String(
          scheduledSession?.type === SessionType.WELCOME
        ),
        welcome_session_done: String(!!isWelcomeSessionEnded),
        get_partner_details_form: "xxx",
      }}
    />
  );
};

export default AddPartnerForm;
