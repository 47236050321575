import { useEffect, useState } from "react";
import { useGetMemberGrowthworksCmsId } from "src/api/resolvers";
import { PageLayout } from "src/components";
import { useGetVideoGrowthWorksQuery } from "src/api/cms";
import { buildQuery } from "./utils/buildQuery";
import Loading from "src/components/Loading";
import WistiaGrowthworks from "./components/WistiaGrowthworks";
import { Typography } from "@mui/material";
import Library from "../Library";
import { useFlags } from "launchdarkly-react-client-sdk";

const Journey = () => {
  const { data } = useGetMemberGrowthworksCmsId();
  const [query, setQuery] = useState("");
  const { data: growthworks } = useGetVideoGrowthWorksQuery(query, {
    skip: !query,
  });

  const { unlockPastVideos } = useFlags();

  useEffect(() => {
    if (!data?.length) return;
    setQuery(buildQuery(data));
  }, [data]);

  if (unlockPastVideos) {
    return <Library />;
  }

  if (data?.length === 0)
    return (
      <PageLayout>
        <Typography variant="h2" sx={{ p: "24px" }}>
          You haven’t watched any videos yet
        </Typography>
      </PageLayout>
    );

  if (!growthworks) return <Loading />;

  return (
    <PageLayout>
      {growthworks && <WistiaGrowthworks growthworks={growthworks} />}
    </PageLayout>
  );
};

export default Journey;
