import { Theme } from "@mui/material";
import { WeeksLineItem, WeeksLineWrapper } from "../../styles";
import { useEffect } from "react";

const getStyle = (part: number, currentPart: number, theme: Theme) => {
  if (part === currentPart)
    return {
      background: "white",
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    };

  if (part > currentPart)
    return {
      color: theme.palette.grey[500],
      background: theme.palette.grey[700],
    };

  return {
    color: "rgba(255,255,255,0.8)",
    background: theme.palette.primary.main,
  };
};

interface WeeksLineProps {
  length: number;
  currentPart: number;
}

const WeeksLine = ({ length, currentPart }: WeeksLineProps) => {
  const LEFT_PADDING = 20;

  useEffect(() => {
    const wrapper = document.getElementById("part_items-wrapper");
    const item = document.getElementById(`part_item-${currentPart}`);

    if (item && wrapper) {
      const centerPosition =
        item.offsetLeft -
        LEFT_PADDING -
        wrapper.clientWidth / 2 +
        item.clientWidth / 2;

      wrapper.scrollLeft = centerPosition;
    }
  }, [currentPart]);

  return (
    <WeeksLineWrapper id="part_items-wrapper">
      {new Array(length).fill(0).map((_, i) => (
        <WeeksLineItem
          key={i}
          id={`part_item-${i + 1}`}
          sx={(theme) => ({
            ...getStyle(i + 1, currentPart, theme),
          })}
        >
          <p>Part {i + 1}</p>
        </WeeksLineItem>
      ))}
    </WeeksLineWrapper>
  );
};

export default WeeksLine;
