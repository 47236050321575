import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={9}
    fill="none"
    {...props}
  >
    <path stroke="#000" strokeLinecap="round" d="M11 4.5H2M5 8.5l-4-4 4-4" />
  </svg>
)
export default SvgComponent
