import React, { useEffect, useState } from "react";
import {
  Chat,
  Channel,
  Thread,
  MessageList,
  MessageInput,
  Window,
} from "stream-chat-react";
import Loading from "src/components/Loading";
import { styled } from "@mui/material";
import "stream-chat-react/dist/css/index.css";
import { useStream } from "./contexts/StreamContext";
import ChatHeader from "./components/ChatHeader";
import useLocationState from "src/hooks/useLocationState";
import { Chat as ChatType } from "src/types/chat";
import { Channel as ChannelType } from "stream-chat";

const StreamChat = () => {
  const { chatClient, isChatInitialized } = useStream();
  const [channel, setChannel] = useState<ChannelType | undefined>();
  const chat = useLocationState<ChatType>("chat");

  useEffect(() => {
    if (!chat || !chatClient || !isChatInitialized) return;

    const newChannel = chatClient.channel("messaging", chat.channelId, {
      members: chat.members,
    });

    (async function () {
      await newChannel.watch();
      setChannel(newChannel);
    })();

    return () => {
      newChannel.stopWatching();
    };
  }, [chat, chatClient, isChatInitialized]);

  if (!chatClient || !channel) {
    return <Loading />;
  }

  return (
    <ChatWrapper>
      <Chat client={chatClient}>
        <Channel channel={channel}>
          <Window>
            <ChatHeader />
            <MessageList />
            {!chat?.readOnly && <MessageInput />}
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </ChatWrapper>
  );
};

const ChatWrapper = styled("div")({
  flex: 1,
});

export default StreamChat;
