import React from "react";
import { useGetLibraryGrowthworksQueryResolver } from "src/api/resolvers";
import { LibraryGrowthworksLoader } from "./Loaders";
import { ModuleGrowthworksList } from "./ModuleGrowthworksList";
import { useGetLibraryState } from "src/store/selectors";
import EmptyState from "./EmptyState";

const WistiaGrowthworks = () => {
  const { currentPathway } = useGetLibraryState();

  const { data: moduleParts, isFetching } =
    useGetLibraryGrowthworksQueryResolver(currentPathway?.id || "");

  if (isFetching || !currentPathway) {
    return <LibraryGrowthworksLoader />;
  }

  if (!moduleParts?.length) {
    return <EmptyState />;
  }

  return <ModuleGrowthworksList moduleParts={moduleParts} />;
};

export default WistiaGrowthworks;
