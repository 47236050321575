import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getTimeDifference } from "src/pages/Home/components/SessionSection/utils/helpers";
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export const SESSION_START_GAP = 300000; // 5 min

export const getTimeUntilSession = (sessionTime: string) =>
  new Date(sessionTime).getTime() - new Date().getTime();

export const isSessionStarted = (timeLeft: number) =>
  dayjs.duration(timeLeft).milliseconds() < 0;

export const formatSessionDate = (sessionTime: string) => {
  const dayjsTime = dayjs(sessionTime);

  return {
    sessionDate: dayjsTime.format("dddd, MMM DD"),
    sessionTime: `${dayjsTime
      .format("h:mm a ({}Z")
      .replace("{}", "GMT")} ${dayjs.tz.guess()})`,
  };
};

export const formatTime = (timeLeft: number) => {
  const duration = dayjs.duration(timeLeft);

  return `${formatTimeDigits(duration.hours())}:${formatTimeDigits(
    duration.minutes()
  )}:${formatTimeDigits(duration.seconds())}`;
};

export const formatTimeDigits = (time: number) =>
  time.toString().padStart(2, "0");

export const isSessionInMoreThanAnHour = (sessionTime: string) =>
  getTimeDifference(sessionTime) >= 1;
