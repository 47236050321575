import { useEffect } from "react";
import { PopupModal } from "react-calendly";
import useActions from "../utils/useActions";
import { useSessionManagerContext } from "../utils/reducer";

const CalendlyPopup = () => {
  const { state } = useSessionManagerContext();
  const { closeCalendly } = useActions();

  useEffect(() => {
    //prevent scroll on modal background
    document.body.style.overflow = !!state.calendlyLink ? "hidden" : "auto";
  }, [state.calendlyLink]);

  return (
    <PopupModal
      url={state.calendlyLink || ""}
      onModalClose={closeCalendly}
      open={!!state.calendlyLink}
      rootElement={document.getElementById("root") as HTMLElement}
    />
  );
};

export default CalendlyPopup;
