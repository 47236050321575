import {
  useGetAssignedPartQueryResolver,
  useGetCompletedWelcomeSessionQueryResolver,
  useGetScheduledSessionQueryResolver,
} from "src/api/resolvers";

const useRefetchSensitiveData = () => {
  const { refetch: refetchAssignedPart } = useGetAssignedPartQueryResolver();
  const { refetch: refetchScheduledSession } =
    useGetScheduledSessionQueryResolver();
  const { refetch: refetchWelcomeSessionStatus } =
    useGetCompletedWelcomeSessionQueryResolver();

  const refetch = () => {
    refetchAssignedPart();
    refetchScheduledSession();
    refetchWelcomeSessionStatus();
  };

  return refetch;
};

export default useRefetchSensitiveData;
