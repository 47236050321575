import { LeftArrow } from "src/assets/icons";
import { Box, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import useLocationState from "src/hooks/useLocationState";

interface LayoutWithBackButtonProps {
  children: ReactNode;
  onBack?: () => void;
  className?: string;
  buttonId?: string;
}

const LayoutWithBackButton = ({
  children,
  onBack,
  className,
  buttonId = "back-button",
}: LayoutWithBackButtonProps) => {
  const referer = useLocationState<string>("referer", true);
  const navigate = useNavigate();

  const onBackClick = () => {
    if (onBack) return onBack();

    if (referer) return navigate(referer);

    navigate(-1);
  };

  return (
    <Wrapper className={className}>
      <IconWrapper>
        <LeftArrow id={buttonId} onClick={onBackClick} />
      </IconWrapper>
      <>{children}</>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  padding: "10px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

const IconWrapper = styled("div")({
  padding: "16px 0",
});

export default LayoutWithBackButton;
