import { useEffect, useState } from "react";

const useOfflineImage = (path: string) => {
  const [image, setImage] = useState<string>("");

  useEffect(() => {
    fetchImage(path).then((dataURI) => {
      setImage(dataURI || "");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const fetchImage = async (url: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const dataURI = await blobToDataURI(blob);
      return dataURI;
    } catch (error) {
      console.error("Failed to fetch image:", error);
      return null;
    }
  };

  const blobToDataURI = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  return image;
};

export default useOfflineImage;
