import { Dispatch, createContext, useContext } from "react";

export enum AlertTypes {
  RESCHEDULE = "reschedule",
  CANCEL = "cancel",
}

export interface State {
  isManagerOpened: boolean;
  calendlyLink: string | null;
  isSelectTypeModalOpened: boolean;
  alertType: AlertTypes | null;
  alertLink: string | null;
}

export const initialState: State = {
  isManagerOpened: false,
  calendlyLink: null,
  isSelectTypeModalOpened: false,
  alertType: null,
  alertLink: null,
};

export enum Actions {
  OPEN_MANAGER = "session/open_manager",
  CLOSE_MANAGER = "session/close_manager",

  SET_CALENDLY_LINK = "calendly_popup/set-link",

  OPEN_SELECT_TYPE = "select_type_popup/open",
  CLOSE_SELECT_TYPE = "select_type_popup/close",

  SHOW_ALERT = "alert/show",
  HIDE_ALERT = "alert/hide",
}

export const reducer = (
  state: State,
  action: { type: Actions; payload: any }
): State => {
  switch (action.type) {
    case Actions.OPEN_MANAGER: {
      return { ...state, isManagerOpened: true };
    }
    case Actions.CLOSE_MANAGER:
      return { ...state, isManagerOpened: false };

    case Actions.SET_CALENDLY_LINK: {
      return {
        ...state,
        calendlyLink: action.payload,
        alertType: null,
        alertLink: null,
        isSelectTypeModalOpened: false,
      };
    }
    case Actions.OPEN_SELECT_TYPE: {
      return {
        ...state,
        isSelectTypeModalOpened: true,
      };
    }
    case Actions.CLOSE_SELECT_TYPE:
      return {
        ...state,
        isSelectTypeModalOpened: false,
      };
    case Actions.SHOW_ALERT:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.HIDE_ALERT:
      return {
        ...state,
        alertType: null,
        alertLink: null,
      };
    default:
      return state;
  }
};

export const SessionManagerContext = createContext(null as any);

export const useSessionManagerContext = (): {
  state: State;
  dispatch: Dispatch<{
    type: Actions;
    payload?: any;
  }>;
} => useContext(SessionManagerContext);
