import dayjs from "dayjs";
import { Subscription } from "src/api/types";

export const transformSubscription = (subscription: Subscription) => {
  return {
    ...subscription,
    started_date: transformDate(subscription.started_date),
    next_billing_date: transformDate(subscription.next_billing_date),
    renew_date: transformDate(subscription.renew_date || ""),
  };
};

const transformDate = (date: string) => dayjs(date).format("MMM DD, YYYY");
