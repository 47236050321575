import { Typography, styled } from "@mui/material";
import { SessionType } from "src/api/types";

const sessionsColor = {
  [SessionType.CHAT]: "#DBD3F2",
  [SessionType.GROUP]: "#CBE1F3",
};

export const Wrapper = styled("div")({
  borderRadius: "10px",
  padding: "15px 20px",
  flex: 1,

  button: {
    marginTop: "15px",
  },
});

export const styles = {
  scheduleSession: {
    backgroundColor: "white",
    boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.07)",
  },
  coupleSession: {
    backgroundColor: sessionsColor[SessionType.GROUP],
  },
  individualSession: {
    backgroundColor: sessionsColor[SessionType.CHAT],
  },
};

export const Title = styled(Typography)({
  fontFamily: "Avenir Next",
  fontWeight: 600,
  color: "black",
});

export const Heading = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

export const ScheduleSessionText = styled(Typography)({
  fontFamily: "Avenir Next",
  fontWeight: 400,
  color: "black",
  fontSize: "14px",
  lineHeight: "20px",
  flex: 1,
});

export const Illustration = styled("img")({
  width: "90px",
  height: "80px",
});

export const MainContent = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "15px",
  width: "100%",
  marginTop: "15px",
});
