import React from "react";
import { CalendarGridItem, Text } from "./styles";
import { useGetSessionEventDetails } from "src/api/resolvers";

const CalendarItem = ({ data }: { data: any }) => {
  const details = useGetSessionEventDetails(data?.type);

  return (
    <CalendarGridItem
      sx={[
        details && { backgroundColor: details.color },
        data.backgroundColor && { backgroundColor: data.backgroundColor },
      ]}
    >
      {!!data && <Text variant="h5">{data?.item || data}</Text>}
    </CalendarGridItem>
  );
};

export default CalendarItem;
