import { useEffect, useState } from "react";
import { Drawer } from "src/api/types";
import { useSheet, BottomPopup } from "src/components/BottomPopup";
import BottomPopupUI from "src/components/BottomPopupUI";
import { closeDrawer } from "src/store/drawersReducer";
import { useGetDrawersState } from "src/store/selectors";
import { useAppDispatch } from "src/store/hooks";

interface DefaultDrawerProps {
  drawer: Drawer;
  onClick?: () => Promise<void> | void;
}

const DefaultDrawer = ({ drawer, onClick = () => {} }: DefaultDrawerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { activeDrawer } = useGetDrawersState();
  const dispatch = useAppDispatch();

  const sheet = useSheet();

  const handleClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
    sheet.close();
  };

  useEffect(() => {
    if (activeDrawer === drawer.query_param) sheet.open();
    // eslint-disable-next-line
  }, [drawer, activeDrawer]);

  return (
    <BottomPopup {...sheet} onCloseEnd={() => dispatch(closeDrawer())}>
      <BottomPopupUI
        Icon={() => <img src={drawer.icon} alt="couple" />}
        title={drawer.title}
        subtitle={drawer.text}
        buttonId={drawer.buttonId}
        onClick={handleClick}
        buttonText={isLoading ? "Loading..." : drawer.button}
        disabled={isLoading}
      />
    </BottomPopup>
  );
};

export default DefaultDrawer;
