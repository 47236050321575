import { Typography } from "@mui/material";
import { Wrapper, Title, Content } from "./styles";
import { GrowthworkLabel, VideoTaskCard } from "src/components";
import imageActive from "src/assets/images/couple2.png";
import imageDisabled from "src/assets/images/couple3.png";
import { GWPlatforms } from "src/api/types";
import GrowthworkAction from "./actions";

interface GrowthworkCardProps {
  title: string;
  time: string;
  disabled?: boolean;
  type: GWPlatforms;
  link: string;
  id: string;
  onClick: () => void;
  completed: boolean;
  image?: string;
  growthworkId: number;
}

const GrowthworkCard = ({
  title,
  time,
  type,
  link,
  disabled,
  id,
  onClick,
  completed,
  image,
  growthworkId,
}: GrowthworkCardProps) => {
  if (type === GWPlatforms.WISTIA)
    return (
      <VideoTaskCard
        title={title}
        url={link}
        time={time}
        id={id}
        onClick={onClick}
        disabled={disabled}
        image={image}
      />
    );

  return (
    <Wrapper disabled={disabled} id={id}>
      <Content>
        <Title disabled={disabled}>
          <GrowthworkLabel type={type} disabled={disabled} />
          <Typography variant="h4">{title}</Typography>
          {time && <Typography>{time} min</Typography>}
        </Title>
        <img
          id={`card_image-${type.toLowerCase()}`}
          src={disabled ? imageDisabled : imageActive}
          alt="couple"
        />
      </Content>
      <GrowthworkAction
        platform={type}
        onClick={onClick}
        completed={completed}
        disabled={!!disabled}
        growthworkId={growthworkId}
      />
    </Wrapper>
  );
};

export default GrowthworkCard;
