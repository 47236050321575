import React from "react";
import {  useGetScheduledSessionsTypes } from "src/api/resolvers";
import { Wrapper } from "./styles";
import { Typography } from "@mui/material";

const FiltersRow = ({
  type,
  setType,
}: {
  type: number | null;
  setType: (prop: number | null) => void;
}) => {
  const sessionEvents = useGetScheduledSessionsTypes();

  if (!sessionEvents || sessionEvents?.length <= 1) {
    return <></>;
  }

  return (
    <Wrapper>
      <div id="filter-sessions-all" onClick={() => setType(null)}>
        <Typography style={{ textDecorationLine: type ? "none" : "underline" }}>
          All sessions
        </Typography>
      </div>
      {sessionEvents?.map((session) => (
        <div
          id={`filter-sessions-${session.short_title}`}
          key={session.type}
          onClick={() => setType(session.type)}
        >
          <Typography
            style={{
              textDecorationLine: type === session.type ? "underline" : "none",
            }}
          >
            {session.short_title} sessions
          </Typography>
        </div>
      ))}
    </Wrapper>
  );
};

export default FiltersRow;
