import SessionPageLayout from "src/components/SessionPageLayout";
import {
  InvalidButton,
  InvalidLogo,
  InvalidText,
  InvalidTitle,
  InvalidWrapper,
} from "./styles";
import { ErrorStatus, ErrorType } from "./utils";
import { SUPPORT_EMAIL } from "src/constants/support";
import { useEffect } from "react";
import RitualAnalytics from "client-analytics";
import { useGetMemberQuery } from "src/api/main";
import { useGetExpertQueryResolver } from "src/api/resolvers";

const InvalidSession = ({
  errorStatus,
  image,
}: {
  errorStatus: ErrorStatus;
  image?: string;
}) => {
  const { data: member } = useGetMemberQuery();
  const { data: expert } = useGetExpertQueryResolver();

  useEffect(() => {
    const eventPayload = {
      type: ErrorType[errorStatus].slug,
      member_uuid: member?.uuid,
      expert_id: expert?.uuid,
      expert_name: `${expert?.first_name} ${expert?.last_name}`,
    };

    RitualAnalytics.track("member_transition_error", eventPayload);
  }, []);

  return (
    <SessionPageLayout>
      <InvalidWrapper>
        <InvalidLogo
          src={image || ErrorType[errorStatus].img}
          alt="page_illustration"
        />
        <InvalidTitle>{ErrorType[errorStatus].title}</InvalidTitle>
        <InvalidText>{ErrorType[errorStatus].text}</InvalidText>
        {errorStatus === ErrorStatus.INVALID_LINK && (
          <InvalidButton
            id="contact-support"
            color="primary-transparent"
            variant="secondary"
            onClick={() => (window.location.href = SUPPORT_EMAIL)}
          >
            Contact Support
          </InvalidButton>
        )}
      </InvalidWrapper>
    </SessionPageLayout>
  );
};

export default InvalidSession;
