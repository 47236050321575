import styled from "@emotion/styled";
import theme from "src/theme";

export const Wrapper = styled("div")({
  padding: "50px 80px 80px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  [theme.breakpoints.down("lg")]: {
    padding: "15px 24px",

    "h1,h3,h4": {
      textAlign: "center",
    },
  },
});

export const QRCodeContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "left",

  span: {
    color: theme.palette.primary.main,
  },
});

export const Footer = styled("div")({
  display: "flex",
  gap: "8px",
});
