import React from "react";
import { Footer, FooterLink } from "./styles";
import theme from "src/theme";
import { Typography } from "src/components";
import RitualAnalytics from "client-analytics";

const MATCHING_FILLED_QP = "matching=true";

const NewToRitual = () => {
  if (window.location.search.includes(MATCHING_FILLED_QP)) {
    return <></>;
  }

  const onClick = () => {
    window.analytics?.track("member_questionnaire_start");
    RitualAnalytics.track("member_questionnaire_start");
    window.location.href = process.env.REACT_APP_MATCHING_DOMAIN || "";
  };

  return (
    <Footer>
      <Typography color={theme.palette.grey.A200} weight={600}>
        Not yet an OurRitual Member?
      </Typography>
      <FooterLink>
        <Typography>Take your assessment now</Typography>
        <div id="member_questionnaire_start" onClick={onClick}>
          <Typography color={theme.palette.primary.main} weight={600}>
            Get started
          </Typography>
        </div>
      </FooterLink>
    </Footer>
  );
};

export default NewToRitual;
