import React from "react";
import LayoutWithImage from "../Layout/LayoutWithImage";
import VerticalStepper from "../VerticalStepper";
import useOnboardingSteps, {
  useGetOnboardingNextStep,
} from "../../utils/useOnboardingSteps";
import { useGetMemberQuery } from "src/api/main";
import LoadingScreen from "../LoadingScreen";
import {
  useGetCompletedMatchingSessionQueryResolver,
  useGetOnboardingStatusQueryResolver,
} from "src/api/resolvers";
import { Button } from "src/components";
import { isMobile } from "src/utils/isMobile";

const InitialStep = () => {
  const steps = useOnboardingSteps();
  const { isLoading: onboardingLoading } =
    useGetOnboardingStatusQueryResolver();
  const { isLoading: matchingSessionLoading } =
    useGetCompletedMatchingSessionQueryResolver();
  const { data: member } = useGetMemberQuery();
  const queryParams = new URLSearchParams(window.location.search);

  const redirectToNextStep = useGetOnboardingNextStep();

  const firstName = member?.first_name || queryParams.get("first_name");

  if (onboardingLoading && matchingSessionLoading) {
    return <LoadingScreen />;
  }

  return (
    <LayoutWithImage
      image={require(`src/assets/images/onboarding_image${
        isMobile() ? "-mobile" : ""
      }.webp`)}
      title="You’re one step closer to a better relationship."
      subtitle={`${firstName}, we're so excited for you to start your OurRitual journey! Thousands of couples have benefitted from our innovative support system, and we're certain you will too. Let's dive in and get you all set up!`}
    >
      <VerticalStepper steps={steps} />
      <Button id={`onboarding_step-initial`} onClick={redirectToNextStep}>
        Continue
      </Button>
    </LayoutWithImage>
  );
};

export default InitialStep;
