import appStoreImage from "src/assets/images/app_store_download.webp";
import googlePlayImage from "src/assets/images/google_play_download.webp";
import { getMobileOperatingSystem } from "src/utils/getMobileOperatingSystem";

export const NativeAppDownloadButton = () => {
  const imageSrc = getStoreImage();

  if (!imageSrc) {
    return <></>;
  }

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <a href={process.env.REACT_APP_NATIVE_APP_STORE_LINK}>
        <img src={imageSrc} alt="store-download" style={{ width: "140px" }} />
      </a>
    </div>
  );
};

function getStoreImage() {
  const operatingSystem = getMobileOperatingSystem();

  switch (operatingSystem) {
    case "IOS":
      return appStoreImage;

    case "Android":
      return googlePlayImage;

    default:
      return null;
  }
}
