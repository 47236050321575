import React from "react";
import { Typography } from "@mui/material";
import { useGetMemberQuery } from "src/api/main";
import { useGetExpertQueryResolver } from "src/api/resolvers";

const ExpertAndPartner = () => {
  const { data: expert } = useGetExpertQueryResolver();
  const { data: member } = useGetMemberQuery();
  const partner = member?.partner;

  const getInitials = ({
    first_name,
    last_name,
  }: {
    first_name: string;
    last_name: string;
  }) => `${first_name} ${last_name[0]}.`.toUpperCase();

  if (!expert || !partner) {
    return <></>;
  }

  return (
    <Typography fontWeight={400}>
      {getInitials(expert)} & {getInitials(partner)}
    </Typography>
  );
};

export default ExpertAndPartner;
