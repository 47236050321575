import { useMemo } from "react";
import Drawers from "src/components/Drawers";
import { NotificationsWrapper } from "../../../styles";
import useStateResolver from "../utils/stateResolver";
import CmsBanner from "./CmsBanner";
import { content as banners } from "./content";

const CmsBanners = () => {
  const prioritizedBanner = useMemo(
    () => banners?.find((item) => item.prioritized),
    [banners]
  );

  const state = useStateResolver(prioritizedBanner?.state);

  const filteredBanners = useMemo(() => {
    if (!banners) return [];
    if (prioritizedBanner && state) return [prioritizedBanner];
    return banners;
  }, [banners, state, prioritizedBanner]);

  return (
    <NotificationsWrapper>
      {filteredBanners?.map((banner) => (
      <CmsBanner banner={banner} key={banner.id} />
      ))}
      <Drawers />
    </NotificationsWrapper>
  );
};

export default CmsBanners;
