import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from "react";
import { useGetStreamTokenQueryResolver } from "src/api/resolvers";
import { StreamChat } from "stream-chat";

import { useGetMemberQuery } from "src/api/main";
import { ContextValue, StreamChatType } from "./types";
import { useGetChannels } from "src/hooks/useGetChannels";

const StreamContext = createContext<ContextValue>({} as ContextValue);

export const useStream = () => useContext(StreamContext);

const StreamProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useGetStreamTokenQueryResolver();
  const { data: member } = useGetMemberQuery();

  const [chatClientState, setChatClient] = useState<StreamChatType>(null);
  const [isChatInitialized, setIsChatInitialized] = useState(false);
  const [unreadCount, setUnreadCount] = useState<ContextValue["unreadCount"]>(
    {}
  );
  const { channels, loading } = useGetChannels({
    chatClient: chatClientState,
    isChatInitialized,
  });

  const eventListener = useRef<any>();

  const cleanChatState = () => {
    setIsChatInitialized(false);
    setChatClient(null);
  };

  useEffect(() => {
    if (!member || !data || member.email.includes("e2e")) {
      return;
    }

    const chatClient = StreamChat.getInstance(
      process.env.REACT_APP_STREAM_KEY || ""
    );

    setChatClient(chatClient);
  }, [member, data]);

  useEffect(() => {
    (async () => {
      if (chatClientState && data) {
        await connectUserToStream();
        setEventListener();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatClientState, data]);

  const connectUserToStream = async () => {
    if (!member || isChatInitialized) {
      return;
    }

    const userData = {
      id: member.uuid,
      name: `${member.first_name} ${member.last_name}`,
      image: member.picture,
    };

    try {
      await chatClientState?.connectUser(userData, data?.token);
      await initialSetUnreadCount();
      setIsChatInitialized(true);
    } catch (error) {
      console.log(error);
      setIsChatInitialized(false);
    }
  };

  const initialSetUnreadCount = async () => {
    if (member) {
      const memberChannels = await chatClientState?.queryChannels(
        {
          type: "messaging",
          members: { $in: [member.uuid] },
        },
        undefined,
        { watch: false }
      );

      const unreadByChannel: { [key: string]: number } = {};
      memberChannels?.forEach((channel) => {
        if (channel.id) {
          unreadByChannel[channel.id] =
            channel.state.read[member.uuid].unread_messages;
        }
      });

      setUnreadCount(unreadByChannel);
    }
  };

  const setEventListener = () => {
    eventListener.current = chatClientState?.on((event) => {
      const { type, channel_id } = event;
      if (!channel_id) return;

      if (type === "notification.message_new") {
        setUnreadCount((prev) => ({
          ...prev,
          [channel_id]: prev[channel_id] + 1,
        }));
      }
      if (type === "notification.mark_read") {
        setUnreadCount((prev) => ({ ...prev, [channel_id]: 0 }));
      }
    });
  };

  const disconnect = async () => {
    eventListener.current?.unsubscribe();
    await chatClientState?.disconnectUser();
    cleanChatState();
  };

  const value = {
    isChatInitialized,
    chatClient: chatClientState,
    unreadCount,
    disconnect,
    channels,
    channelsLoading: loading,
  };

  return (
    <StreamContext.Provider value={value}>{children}</StreamContext.Provider>
  );
};

export default StreamProvider;
