import React from "react";
import { Button } from "src/components";
import { GrowthworkActionProps } from "../../types";

const StartTaskButton = ({
  disabled,
  completed,
  onClick,
}: Omit<GrowthworkActionProps, "platform">) => {
  if (disabled || completed) return <></>;

  return (
    <Button id="start-task" color="outlined-primary" onClick={onClick}>
      Start task
    </Button>
  );
};

export default StartTaskButton;
