const usePrefetch = (
  ...callbacks: { isLoading: boolean; isFetching: boolean; isError: boolean }[]
) => {
  return {
    isLoading: callbacks.find(({ isLoading }) => isLoading),
    isFetching: callbacks.find(({ isFetching }) => isFetching),
    isError: callbacks.find(({ isError }) => isError),
  };
};

export default usePrefetch;
