import React, { SVGProps } from "react";
import { styled } from "@mui/material";
import { ChatNavigation } from "src/assets/icons";
import { useStream } from "src/pages/Chat/contexts/StreamContext";

const ChatNavigationIcon = (props?: SVGProps<SVGSVGElement>) => {
  const { unreadCount } = useStream();

  return (
    <div>
      {!!Object.values(unreadCount).find((value) => value > 0) && (
        <Indication />
      )}
      <ChatNavigation {...props} />
    </div>
  );
};

const Indication = styled("div")({
  width: "8px",
  height: "8px",
  borderRadius: "4px",
  backgroundColor: "red",
  position: "absolute",
  right: 0,
  zIndex: 100,
});

export default ChatNavigationIcon;
