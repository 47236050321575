import { SessionStatus } from "src/api/types";

import canceledIllustration from "src/assets/images/session_canceled-illustration.webp";
import CanceledSession from "./CanceledSession";
import { EndStatus } from "./CanceledSession/utils";
import SessionNotStarted from "./SessionNotStarted";

export const pageContent = {
  [SessionStatus.ONGOING]: {
    Component: SessionNotStarted,
  },

  [SessionStatus.ENDED]: {
    Component: () => <CanceledSession endStatus={EndStatus.COMPLETED} />,
  },

  [SessionStatus.SCHEDULED]: {
    Component: SessionNotStarted,
  },

  [SessionStatus.CANCELED]: {
    Component: () => <CanceledSession endStatus={EndStatus.CANCELLED} />,
  },
  [SessionStatus.RESCHEDULED]: {
    props: {
      TitleText: (
        <>
          Session has been <span>rescheduled</span>
        </>
      ),
      SubtitleText:
        "Your video session with your relationship expert has been rescheduled.",
      illustration: canceledIllustration,
    },
  },
  [SessionStatus.NOT_STARTED]: {
    Component: SessionNotStarted,
  },
};
