import React from "react";

const CONTAINER_SIZE = 32;
const OFFSET = 8;

const ChatAvatar = ({ images }: { images: string[] }) => {
  return (
    <div
      style={{
        position: "relative",
        width: `${CONTAINER_SIZE}px`,
        height: `${CONTAINER_SIZE}px`,
      }}
    >
      {images
        .filter((img) => img)
        .map((image, i) => {
          const size = CONTAINER_SIZE - (images.length - 1) * OFFSET;
          return (
            <img
              key={image}
              src={image}
              alt="chat_avatar"
              style={{
                position: "absolute",
                width: `${size}px`,
                height: `${size}px`,
                top: OFFSET * i,
                left: OFFSET * i,
                borderRadius: "50%",
              }}
            />
          );
        })}
    </div>
  );
};

export default ChatAvatar;
