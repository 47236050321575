import React, {useEffect, useState} from "react";
import Loading from "src/components/Loading";
import {Buttons, Subtitle, Title, Wrapper} from "src/pages/NewLogin/styles";
import {OurRitualLogoMark} from "src/assets/icons";
import {LoginWithEmail, LoginWithPhone} from "src/components/LoginButtons";
import NewToRitual from "src/pages/NewLogin/NewToRitual";
import {useAuth0} from "@auth0/auth0-react";

const Authorization = () => {
  const { loginWithRedirect } = useAuth0();
  const [redirectProcessing, setRedirectProcessing] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sign_with_email = queryParams.get("sign_with_email");
    const sign_with_sms = queryParams.get("sign_with_sms");

    if (sign_with_email || sign_with_sms) {
      setRedirectProcessing(true);
      loginWithRedirect({
        scope: "openid profile email",
        redirectUri: window.location.origin,
        sign_with_email,
        sign_with_sms,
      });
    } else {
      setRedirectProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirectProcessing) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <OurRitualLogoMark />
      <Title variant="headline2">Welcome to OurRitual</Title>
      <Subtitle>
        Log in to access your account{`\n`}and see your progress.
      </Subtitle>
      <Buttons>
        <LoginWithEmail />
        <LoginWithPhone />
      </Buttons>
      <NewToRitual />
    </Wrapper>
  );
};

export default Authorization;
