import { LoadingWrapper } from "src/components/Loading/styles";
import loaderAnimation from "src/assets/animations/loader_animation.json";
import Lottie from "lottie-react";

const Loading = () => (
  <LoadingWrapper>
    <Lottie animationData={loaderAnimation} loop={true} />
  </LoadingWrapper>
);

export default Loading;
