import { useMemo } from "react";
import { useGetSessionDetailsQueryResolver } from "src/api/resolvers";
import IllustrationPageLayout from "src/components/IllustrationPageLayout";
import Loading from "src/components/Loading";
import useSessionTitle from "src/hooks/useSessionTitle";
import ScheduleSession from "./ScheduleSession";
import InvalidSession from "./SessionStates/InvalidSession";
import { ErrorStatus } from "./SessionStates/InvalidSession/utils";
import { pageContent } from "./SessionStates/pageContent";

const JoinSession = () => {
  useSessionTitle("OurRitual Session");

  const {
    data: session,
    isFetching,
    isError,
  } = useGetSessionDetailsQueryResolver();

  const content = useMemo(
    () => session && pageContent[session.status],
    [session],
  );

  if (isFetching) {
    return <Loading />;
  }

  if (isError || !content) {
    return <InvalidSession errorStatus={ErrorStatus.INVALID_LINK} />;
  }

  if ("Component" in content) {
    return <content.Component />;
  }

  return (
    <IllustrationPageLayout {...content.props}>
      <ScheduleSession />
    </IllustrationPageLayout>
  );
};

export default JoinSession;
