/* eslint-disable no-useless-computed-key */
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF5B4C",
      dark: "#31232F",
      light: "#FFFEFB",
    },
    info: {
      main: "#5B2FDB",
      light: "#CBE1F3",
      dark: "#dbd3f24d",
      [100]: "#F9EEC5",
    },
    success: {
      main: "#37AC84",
      light: "#DCF3D9",
    },
    background: {
      default: "#F6F6F8",
      paper: "#F8F5ED",
    },
    error: {
      main: "#D60D0D",
      dark: "#EA2121",
      light: "#FFB4AD",
    },
    grey: {
      [50]: "#DFE1E6",
      [100]: "#7C7F87",
      [200]: "#EAEBEF",
      [300]: "#EDEDE9",
      [400]: "#9EA0A8",
      [500]: "#BDBFC7",
      [600]: "#575961",
      [700]: "#F6F6F8",
      [800]: "#E4EFF3",
      [900]: "#F7F7F4",
      A100: "#292A30",
      A200: "#1F2222",
      A400: "rgba(246, 246, 248, 0.5)",
      A700: "#FCFCFC",
    },
  },
  typography: {
    fontFamily: "Avenir Next",
    h1: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "28px",
      fontFamily: "Garnett",
    },
    h2: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "24px",
      fontFamily: "Garnett",
    },
    h3: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#9EA0A8",
    },

    h4: {
      color: "#131414",
      fontFamily: "Garnett",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "22px",
    },

    h5: {
      color: "#9EA0A8",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "16px",
    },

    body1: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: 500,
    },

    body2: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      color: "#EDEDE9",
    },
  },
});

export const avatarColors = [
  { background: "CBE1F3", color: "575961" },
  { background: "E4DEF8", color: "575961" },
  { background: "F76464", color: "FFF" },
  { background: "FAE8FF", color: "575961" },
  { background: "DCF3D9", color: "575961" },
  { background: "F9EEC5", color: "F76464" },
];

export default theme;
const FIGMA_MOCKUP_HEIGHT = 768;
export const DESKTOP_MOCKUP_HEIGHT = 1024;
//gets the value as a percentage of the screen size compared to designs in Figma
export const getResponsiveValue = (
  originalValue: number,
  mockupValue = FIGMA_MOCKUP_HEIGHT,
  multiplierValue = 1.25,
): string => {
  if (mockupValue < window.innerHeight) return `${originalValue}px`;

  let responsiveValue = (originalValue * 100) / mockupValue;

  if (mockupValue > window.innerHeight) {
    const multiplier = (mockupValue / window.innerHeight) * multiplierValue;
    responsiveValue /= multiplier;
  }

  return `${responsiveValue}vh`;
};
