import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

export const ContentWrapper = styled("div")({
  height: "calc(var(--vh, 1vh) * 100 - 92px)",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
});

export const Content = styled("div")({
  flex: 1,
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "26px",
});
