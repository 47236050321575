import React, { useEffect } from "react";
import { useGetMemberQuery } from "src/api/main";

const PlansModal = () => {
  const { data: member } = useGetMemberQuery();

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data.type === "REDIRECT" && event.data.final_link) {
        window.location.href = event.data.final_link;
      }
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const pricingPageUrl = process.env.REACT_APP_PRICING_URL || "";

  const coupon = process.env.MATCHING_COUPON || "MatchingOnce";

  const email = member?.email.replace(/\+/g, "%2B");

  const params = `plans?matching=true&first_name=${member?.first_name}&email=${email}&name=${member?.first_name} ${member?.last_name}&uuid=${member?.uuid}&phone=${member?.phone_number}&funnelid=tiers-v1&funnel_start=get_started&coupon=${coupon}&ending=main`;

  const url = pricingPageUrl + params;

  return (
    <iframe
      style={{ width: "100%", height: "100%", border: "none" }}
      src={url}
    ></iframe>
  );
};

export default PlansModal;
