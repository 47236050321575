import { styled } from "@mui/material";
import theme from "src/theme";
import { SessionButton } from "../../components/styles";
import { SessionText, SessionTitle, SessionWrapper } from "../../styles";

export const CanceledWrapper = styled(SessionWrapper)({
  padding: "30px 40px",
});

export const CanceledTitle = styled(SessionTitle)({
  lineHeight: "42px",
  fontFamily: "Noto Serif",
});

export const CanceledText = styled(SessionText)({
  marginBottom: "0px",
});

export const CanceledButton = styled(SessionButton)({
  marginTop: "24px",
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
});
