import React from "react";
import image from "src/assets/images/couple_illustration.png";
import { Typography } from "src/components";
import Loading from "src/components/Loading";
import { Wrapper } from "./styles";

const LoadingScreen = () => {
  return (
    <Wrapper>
      <img src={image} alt="couple" />
      <Typography variant="headline3">
        A few more short steps and we can get started.
      </Typography>
      <Loading />
    </Wrapper>
  );
};

export default LoadingScreen;
