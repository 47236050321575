import { useGetMemberPlanResolver } from 'src/api/resolvers';
import {PlanCategory} from 'src/api/types';

export interface PlanCategoryState {
  isIndividual: boolean;
  isCouples: boolean;
  isMatching: boolean;
}

export const useGetPlanCategory = () => {
  const data = useGetMemberPlanResolver();

  return {
    isIndividual: data?.category === PlanCategory.INDIVIDUAL,
    isCouples:
      data?.category === PlanCategory.GROUP ||
      data?.category === PlanCategory.MATCHING,
    isMatching: data?.category === PlanCategory.MATCHING,
  };
};