import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5 2h-8c-4 0-6 2-6 6v13c0 .55.45 1 1 1h13c4 0 6-2 6-6V8c0-4-2-6-6-6Zm-4.89 14.66c-.25.25-.71.48-1.05.53l-2.08.29c-.08.01-.16.02-.23.02-.35 0-.67-.12-.9-.35-.28-.28-.4-.69-.33-1.13l.29-2.08c.05-.34.28-.81.53-1.05l3.77-3.77a6.058 6.058 0 0 0 .51 1.09c.08.14.17.28.25.38.1.15.2.28.27.35.04.06.08.1.09.12.22.25.45.49.67.67.06.06.1.09.11.1.13.1.25.21.37.28.13.1.27.19.41.27.17.1.35.2.54.29.19.09.37.16.55.22l-3.77 3.77Zm5.44-5.43-.78.78c-.05.05-.12.08-.19.08-.02 0-.06 0-.08-.01a5.238 5.238 0 0 1-3.58-3.58c-.03-.09 0-.19.07-.26l.79-.79c1.29-1.29 2.51-1.26 3.77 0 .64.64.96 1.26.95 1.9 0 .63-.31 1.24-.95 1.88Z"
      fill="#5B2FDB"
    />
  </svg>
);

export default SvgComponent;
