import React from "react";
import { Growthwork, LibraryGrowthwork } from "src/api/types";
import { useGetWistiaDetailsQuery } from "src/api/wistia";
import { Typography } from "src/components";
import { VideoLabel, AudioLabel } from "src/assets/icons";
import { GrowthworkLoader } from "../Loaders";
import {
  Content,
  GrowthworkImage,
  GrowthworkWrapper,
  Label,
  TextWrapper,
  Title,
} from "./styles";

import audioThumbnail from "src/assets/images/library_expert_thumbnail.png";

const LibraryGrowthworksList = ({
  growthworks,
  onClick,
}: {
  growthworks: LibraryGrowthwork[];
  onClick: (growthwork: Growthwork) => void;
}) => {
  return (
    <Content>
      {growthworks.map((item) => (
        <div
          id="open-library-task"
          onClick={() => onClick(item)}
          key={item.baselink}
        >
          <GrowthworkItem growthwork={item} />
        </div>
      ))}
    </Content>
  );
};

const GrowthworkItem = ({ growthwork }: { growthwork: LibraryGrowthwork }) => {
  const { data } = useGetWistiaDetailsQuery(growthwork.baselink);
  const isAudio = growthwork.baselink.includes("async_audio");

  if (!data) {
    return <GrowthworkLoader margin={0} />;
  }

  return (
    <GrowthworkWrapper>
      <GrowthworkImage src={isAudio ? audioThumbnail : data?.thumbnail_url} />
      <div>
        <Title>{growthwork.gw_short_title}</Title>
        <TextWrapper>
          {isAudio ? <AudioLabel /> : <VideoLabel />}
          <Typography variant="body-medium">{data?.duration} min</Typography>
          {growthwork.is_new && <Label>Recently added</Label>}
        </TextWrapper>
      </div>
    </GrowthworkWrapper>
  );
};

export default LibraryGrowthworksList;
