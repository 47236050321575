import { styled } from "@mui/material";
import { Typography } from "src/components";
import theme from "src/theme";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  margin: "auto 0",
  padding: "15px 46px",

  img: {
    maxWidth: "155px",
  },
});

export const Text = styled(Typography)({
  textAlign: "center",
  whiteSpace: "break-spaces",

  span: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
});
