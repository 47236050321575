import React from "react";
import { ChatName, NameWrapper, Wrapper } from "./styles";
import useLocationState from "src/hooks/useLocationState";
import BackButton from "./components/BackButton";
import { Chat } from "src/types/chat";
import ChatAvatar from "src/components/ChatAvatar";

const ChatHeader = () => {
  const chat = useLocationState<Chat>("chat");

  if (!chat) {
    return <></>;
  }

  return (
    <Wrapper>
      <BackButton />
      <NameWrapper>
        <ChatName>{chat.title}</ChatName>
      </NameWrapper>
      <ChatAvatar images={chat.images} />
    </Wrapper>
  );
};

export default ChatHeader;
