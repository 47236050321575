import React, { PropsWithChildren } from "react";
import dayjs from "dayjs";
import CommonTemplate, { CommonTemplateProps } from "../CommonTemplate";
import { useGetScheduledSessionQueryResolver } from "src/api/resolvers";
import { SessionDay, SessionTime } from "./styles";
import useActions from "../../../utils/useActions";
import JoinSessionButton from "./JoinSessionButton";

interface NextSessionTemplateProps extends PropsWithChildren {
  image: CommonTemplateProps["image"];
  wrapperStyle: CommonTemplateProps["wrapperStyle"];
}

const NextSessionTemplate = ({
  children,
  ...rest
}: NextSessionTemplateProps) => {
  const { openManagerSheet } = useActions();

  return (
    <CommonTemplate
      title="Your upcoming session:"
      Info={<SessionDate>{children}</SessionDate>}
      onClick={openManagerSheet}
      {...rest}
    >
      <JoinSessionButton />
    </CommonTemplate>
  );
};

const SessionDate = ({ children }: PropsWithChildren) => {
  const { data: scheduledSession } = useGetScheduledSessionQueryResolver();
  const dayjsTime = dayjs(scheduledSession?.next_session);

  return (
    <div>
      <SessionDay>{dayjsTime.format("dddd, MMM DD")}</SessionDay>
      <SessionTime>
        {dayjsTime.format("hh:mm A ({}Z)").replace("{}", "GMT")}
      </SessionTime>
      {children}
    </div>
  );
};

export default NextSessionTemplate;
