import { styled } from "@mui/material";
import Typography from "src/components/Typography";
import theme from "src/theme";

export const Text = styled(Typography)({
  color: theme.palette.grey[600],
  marginTop: "40px",
  textAlign: "center",
  whiteSpace: "break-spaces",

  "span, a": {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
});
