import { styled } from "@mui/material";

export const Card = styled("div")({
  backgroundColor: "white",
  borderRadius: "24px",
  display: "flex",
  flexDirection: "row",
  padding: "20px 22px",
  gap: "10px",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "12px",
  boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.05)",
});

export const Content = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
  width: "100%",
});

export const ContentText = styled("div")({
  overflow: "hidden",

  h4: {
    marginTop: "4px",
  },
});

export const SkeletonWrapper = styled("div")({
  marginTop: "12px",
});
