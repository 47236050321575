import { GWPlatforms } from "src/api/types";
import qs from "qs";

export const buildQuery = (data: string[]) =>
  qs.stringify(
    {
      filters: {
        id: {
          $in: data,
        },
        gw: {
          platform: {
            $eq: GWPlatforms.WISTIA,
          },
        },
      },
      populate: ["module", "gw", "part"],
      pagination: {
        limit: -1,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
