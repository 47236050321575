import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import Ably from "ably";
import { useGetMemberQuery } from "src/api/main";
import { Member } from "src/api/types";

interface SubscribeProps {
  name: string;
  callback: (data: any) => void;
  field?: keyof Member;
}

interface ContextValue {
  subscribe: (props: SubscribeProps) => void;
  isReady: boolean;
}

const AblyContext = createContext<ContextValue>({} as ContextValue);

export const useAbly = () => useContext(AblyContext);

const AblyProvider = ({ children }: { children: ReactNode }) => {
  const { data: member } = useGetMemberQuery();
  const [ably, setAbly] = useState<Ably.Types.RealtimePromise | null>(null);
  const [activeSubscriptions, setActiveSubscriptions] = useState<string[]>([]);

  useEffect(() => {
    const connectAbly = async () => {
      const ablyRealtime = new Ably.Realtime.Promise(
        process.env.REACT_APP_ABLY_API_KEY || ""
      );
      await ablyRealtime.connection.once("connected");
      setAbly(ablyRealtime);
    };

    if (!ably) connectAbly();

    return () => {
      ably?.close();
    };
  }, [ably]);

  const subscribe = ({ name, callback, field = "uuid" }: SubscribeProps) => {
    if (!ably || !member) return;

    const channel = `${process.env.ABLY_CHANNEL}-${member[field]}`;
    const ablyChannel = ably.channels.get(channel);

    if (!activeSubscriptions.includes(name)) {
      ablyChannel.subscribe(name, (message) => {
        callback(JSON.parse(message.data));
      });
      setActiveSubscriptions((prev) => [...prev, name]);
    }
  };

  const value = { subscribe, isReady: !!ably };

  return <AblyContext.Provider value={value}>{children}</AblyContext.Provider>;
};

export default AblyProvider;
