import { styled } from "@mui/material";
import theme, { DESKTOP_MOCKUP_HEIGHT, getResponsiveValue } from "src/theme";
import Typography from "../Typography";
import { Button } from "ritual-ui";

export const Wrapper = styled("div")({
  background: theme.palette.background.paper,
  height: "100vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "40px 24px 24px 24px",
  textAlign: "center",
  gap: "15px",
  overflowY: "auto",
  span: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
});

export const Header = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: "40px",
  [theme.breakpoints.down("md")]: {
    marginBottom: 0,
  },
});

export const Logo = styled("div")<{ memberInfo: boolean }>(
  ({ memberInfo }) => ({
    width: memberInfo ? "calc(100% - 400px)" : "100%",
    [theme.breakpoints.down("md")]: {
      width: memberInfo ? "calc(100% - 40px)" : "100%",
    },
  }),
);

export const Info = styled("div")({
  display: "flex",
  alignItems: "center",
  fontFamily: "Noto Serif",
  top: getResponsiveValue(46, DESKTOP_MOCKUP_HEIGHT),
  right: "80px",
  [theme.breakpoints.down("md")]: {
    right: "25px",
    top: getResponsiveValue(54, DESKTOP_MOCKUP_HEIGHT),
  },
});

export const MemberPicture = styled("img")({
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  marginRight: "16px",
  [theme.breakpoints.down("md")]: {
    width: "32px",
    height: "32px",
    marginRight: 0,
  },
});

export const MemberText = styled(Typography)({
  fontWeight: 500,
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontSize: "16px",
  color: theme.palette.primary.dark,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});

export const LogoutButton = styled(Button)({
  color: theme.palette.primary.main,
  fontSize: "16px",
  marginLeft: "18px",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});
