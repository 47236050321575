import { createAction, createReducer } from "@reduxjs/toolkit";

export interface State {
  data: any[];
  count: number;
}

export const addModuleRelation = createAction<State>("module-relation/add");

const initialState: State = {
  data: [],
  count: 0,
};

const moduleRelationReducer = createReducer(initialState, (builder) => {
  builder.addCase(addModuleRelation, (state, action) => {
    state.data = [...state.data, ...action.payload.data];
    state.count = action.payload.count;
  });
});

export default moduleRelationReducer;
