import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.99 7.809v8.381c0 1.72-.51 3.034-1.394 3.916-.882.883-2.195 1.394-3.916 1.394H8.31c-1.72 0-3.034-.51-3.916-1.395C3.51 19.222 3 17.905 3 16.18V7.81c0-1.72.51-3.034 1.394-3.916C5.276 3.01 6.589 2.5 8.31 2.5h8.38c1.72 0 3.034.511 3.915 1.393.881.883 1.39 2.195 1.385 3.916ZM13.75 16v-2.75h2.75c.686 0 1.25-.564 1.25-1.25s-.564-1.25-1.25-1.25h-2.75V8c0-.686-.564-1.25-1.25-1.25s-1.25.564-1.25 1.25v2.75H8.5c-.686 0-1.25.564-1.25 1.25s.564 1.25 1.25 1.25h2.75V16c0 .686.564 1.25 1.25 1.25s1.25-.564 1.25-1.25Z"
      fill="#F76666"
      stroke="#F76666"
    />
  </svg>
);

export default SvgComponent;
