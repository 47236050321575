import CancelledIllustration from "src/assets/images/session-cancelled.svg";
import ComplitedIllustration from "src/assets/images/session-completed.svg";

export enum EndStatus {
  CANCELLED = 1,
  COMPLETED = 2,
}

export const EndType = {
  [EndStatus.CANCELLED]: {
    img: CancelledIllustration,
    title: "Seems Your Session Has Been Cancelled...",
    text: "Visit the app to schedule your session at a new time.",
  },
  [EndStatus.COMPLETED]: {
    img: ComplitedIllustration,
    title: `Great Job, You've Completed This Session`,
    text: "Visit the app to schedule your next session, and keep up the good work!",
  },
};
