import { useGetMemberQuery } from "src/api/main";
import { links } from "src/constants/first_session_links"


export const useGetFirstSessionLink = (sessionType: "welcome" | "matching") => {
  const { data: member } = useGetMemberQuery();
  
  const env = (
    process.env.QA_TEAM_EMAIL &&
    member?.email.includes(process.env.QA_TEAM_EMAIL)
      ? "qa"
      : process.env.REACT_APP_ENV
  ) as "qa" | "prod" | "dev";

  return links[sessionType][env];
};
