import { Typography } from "src/components";
import { StepItem } from "./styles";
import theme from "src/theme";
import { CircleCompleted } from "src/assets/icons";

interface StepProps {
  step: number;
}

export const CurrentStepItem = ({ step }: StepProps) => {
  return (
    <StepItem sx={{ background: theme.palette.primary.main }}>
      <Typography color="white" weight={600}>
        {step}
      </Typography>
    </StepItem>
  );
};

export const NextStepsItem = ({ step }: StepProps) => {
  return (
    <StepItem sx={{ border: `1px solid ${theme.palette.grey[500]}` }}>
      <Typography color={theme.palette.grey[100]} weight={600}>
        {step}
      </Typography>
    </StepItem>
  );
};

export const PreviousStepsItem = () => {
  return <CircleCompleted />;
};
