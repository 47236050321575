import { Typography } from "@mui/material";
import React from "react";
import { Card, Wrapper, Text } from "./styles";

interface StickyNotificationProps {
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  title: string;
  Subtitle: JSX.Element;
  color?: "primary" | "info" | "success";
  onClick?: () => void;
  definedColor?: string;
}

const StickyNotification = ({
  Icon,
  Subtitle,
  title,
  color = "primary",
  onClick = () => {},
  definedColor,
}: StickyNotificationProps) => {
  return (
    <Card
      id={`sticky-notification-${color}`}
      sx={{ backgroundColor: definedColor || `${color}.main` }}
      onClick={onClick}
    >
      <Wrapper>
        <div>
          <Icon />
        </div>
        <Text>
          <Typography variant="h3">{title}</Typography>
          <Typography>{Subtitle}</Typography>
        </Text>
      </Wrapper>
    </Card>
  );
};

export default StickyNotification;
