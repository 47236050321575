import React from "react";
import { useGetScheduledSessionQueryResolver } from "src/api/resolvers";
import { Button } from "src/components";
import useJoinSession from "src/hooks/useJoinSession";
import {
  isSessionStarted,
  useIsTransitionActivated,
} from "../../../utils/helpers";

const JoinSessionButton = () => {
  const { data: scheduledSession } = useGetScheduledSessionQueryResolver();
  const { joinSession } = useJoinSession();
  const isTransitionActivated = useIsTransitionActivated();

  if (
    !isTransitionActivated ||
    !isSessionStarted(scheduledSession?.next_session)
  ) {
    return <></>;
  }

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    joinSession(scheduledSession);
  };

  return (
    <Button id="app-join-session" onClick={onClick}>
      Join session
    </Button>
  );
};

export default JoinSessionButton;
