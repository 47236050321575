export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent;

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "IOS";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  return null;
};
