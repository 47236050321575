import { styled } from "@mui/material";

import { Typography } from "src/components";
import theme from "src/theme";

export const Content = styled("div")({
  width: "100%",
  padding: "20px",
});

export const Title = styled(Typography)({
  marginBottom: "15px",
  fontFamily: "Avenir Next",
  fontWeight: 600,
  color: theme.palette.grey.A200,
});

export const PathwayCard = styled("div")({
  padding: "12px 20px",
  backgroundColor: theme.palette.background.default,
  marginBottom: "10px",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  pointerEvents: "none",
});

export const PathwayName = styled(Typography)<{ active: boolean }>(
  ({ active }) => ({
    fontFamily: "Avenir Next",
    fontWeight: 600,
    color: active ? "black" : theme.palette.grey[600],
    marginLeft: "10px",
  })
);

export const IconWrapper = styled("div")({
  width: "40px",
  height: "40px",

  img: {
    width: "100%",
  },
});
