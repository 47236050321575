import React from "react";
import { Wrapper } from "src/components/SupportHeader/styles";
import { Typography } from "@mui/material";
import { Close, Home } from "src/assets/icons";
import { ROUTES } from "src/types/routes";
import { useFlags } from "launchdarkly-react-client-sdk";

interface SupportHeaderProps {
  onClose: (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    route?: string
  ) => void;
}

const SupportHeader = ({ onClose }: SupportHeaderProps) => {
  const { showSunsetBanner } = useFlags();

  return (
    <Wrapper sx={{ top: showSunsetBanner ? "150px" : 0 }}>
      <Close stroke={"#ffff"} onClick={onClose} />
      <Typography variant="body1" style={{ fontWeight: 600, color: "white" }}>
        OurRitual Support
      </Typography>
      <Home
        size="small"
        stroke={"#ffff"}
        onClick={(event) => onClose(event, ROUTES.HOME)}
      />
    </Wrapper>
  );
};

export default SupportHeader;
