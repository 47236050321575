import React from "react";
import image from "src/assets/images/couple_illustration.png";
import Typography from "src/components/Typography";
import Loading from "src/components/Loading";
import { Wrapper } from "./styles";

const LoadingScreen = ({
  text,
  illustration = image,
}: {
  text: string;
  illustration?: string;
}) => {
  return (
    <Wrapper>
      <img src={illustration} alt="couple" />
      <Typography variant="headline3">{text}</Typography>
      <Loading />
    </Wrapper>
  );
};

export default LoadingScreen;
