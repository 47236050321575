import { useFlags } from "launchdarkly-react-client-sdk";
import { useGetMemberQuery } from "src/api/main";
import {
  useGetActiveModuleQueryResolver,
  useGetAssignedPartQueryResolver,
} from "src/api/resolvers";
import { Banner } from "src/api/types";

const modelMapping = {
  module: useGetActiveModuleQueryResolver,
  member: useGetMemberQuery,
  assigned_part: useGetAssignedPartQueryResolver,
};

const emptyModel = () => ({ data: null });

const useStateResolver = (state?: Banner["state"]) => {
  const modelResolver = (state && modelMapping[state.model]) || emptyModel;
  const { data: model } = modelResolver();
  const flags = useFlags();

  if (!state) return false;

  const isUnderFF = state.featureFlag && !flags[state.featureFlag];

  return !isUnderFF && (model as any)?.[state.field] === state.value;
};

export default useStateResolver;
