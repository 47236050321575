import { Member } from "src/api/types";
import { CoupleChannel, IndividualChannel } from "./ChannelClasses";
import { Channel, ChannelMemberResponse } from "stream-chat";
import {PlanCategoryState} from "src/hooks/useGetPlanCategory";

export const getExpectedChannels = (member: Member, plan_category: PlanCategoryState) => {
  const channelClasses = [IndividualChannel, CoupleChannel];
  return channelClasses
    .map((ChannelType) => new ChannelType(member, plan_category).getExpectedChannel())
    .filter((channel) => !!channel);
};

export const extractMembersInfo = (channel: Channel, memberId?: string) => {
  const members = Object.values(channel?.state?.members || {}).filter(
    ({ user_id }) => user_id !== memberId
  );

  return {
    title: getTitle(members),
    images: getImages(members),
  };
};

const getTitle = (members: ChannelMemberResponse[]) => {
  return members.map(({ user }) => user?.name).join(", ");
};

const getImages = (members: ChannelMemberResponse[]) => {
  return members.map(({ user }) => user?.image) as string[];
};
