import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMemberQuery } from "src/api/main";
import { useGetFrontHashQueryResolver } from "src/api/resolvers";
import {
  INITIALIZATION_FALLBACK_TIMEOUT,
  insertFrontScript,
  SHOW_CHAT_FALLBACK_TIMEOUT,
} from "./utils";
import { updateUnreadMessagesCount } from "src/store/supportReducer";
import { useAppDispatch } from "src/store/hooks";
import Loading from "src/components/Loading";
import SupportHeader from "src/components/SupportHeader";

const FrontAppChat = () => {
  const { data: member } = useGetMemberQuery();
  const { data } = useGetFrontHashQueryResolver();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!data?.hash) return;
    insertFrontScript();
    initFrontChat();
    dispatch(updateUnreadMessagesCount(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initFrontChat = () => {
    const options = {
      chatId: process.env.FRONT_APP_CHAT_ID || "",
      useDefaultLauncher: false,
      email: member?.email,
      name: `${member?.first_name} ${member?.last_name}`,
      userHash: data?.hash,
    };

    if (window.FrontChat) {
      setTimeout(() => {
        window.FrontChat("init", options);
        openChat();
      }, INITIALIZATION_FALLBACK_TIMEOUT);
    } else {
      setTimeout(initFrontChat, INITIALIZATION_FALLBACK_TIMEOUT);
    }
  };

  const openChat = () => {
    const chatIframe = document.getElementById("front-chat-iframe");

    if (chatIframe) {
      setTimeout(() => {
        window.FrontChat("show");
      }, SHOW_CHAT_FALLBACK_TIMEOUT);
    }

    if (chatIframe?.style.width !== "100%") {
      setTimeout(openChat, SHOW_CHAT_FALLBACK_TIMEOUT);
    }
  };

  const closeChat = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    route?: string
  ) => {
    e.stopPropagation();
    window.FrontChat && window.FrontChat("shutdown");
    route ? navigate(route) : navigate(-1);
  };

  return (
    <>
      <Loading />
      <SupportHeader onClose={closeChat} />
      <div id="ritual-front-chat" />
    </>
  );
};

export default FrontAppChat;
