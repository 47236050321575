import React from "react";
import illustration from "src/assets/images/pink_profile.svg";
import { Button } from "src/components";
import {
  Description,
  Illustration,
  TimeDisplay,
  TimeWrapper,
  Title,
  Wrapper,
} from "src/pages/Home/components/CompleteProfileBanner/styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import {
  useGetMemberQuery,
  useSendClickSurveyEventMutation,
} from "src/api/main";
import {
  COMPLETE_PROFILE_FORM_ID,
  PERSONALIZATION_FORM_ID,
} from "src/constants/survey";
import { useFlags } from "launchdarkly-react-client-sdk";

export const CompleteProfileBanner = () => {
  const { data: member } = useGetMemberQuery();
  const navigate = useNavigate();
  const [sendSurveyEventMutation] = useSendClickSurveyEventMutation();
  const { appOnboardingQuestionnaire } = useFlags();

  const startSurvey = () => {
    sendSurveyEventMutation({
      uuid: member?.uuid || "",
      surveyId: appOnboardingQuestionnaire
        ? PERSONALIZATION_FORM_ID
        : COMPLETE_PROFILE_FORM_ID,
    });
    navigate(ROUTES.COMPLETE_PROFILE);
  };

  return (
    <Wrapper>
      <Illustration src={illustration} alt="complete_profile_illustration" />
      <Title variant="headline4">Complete Your Profile</Title>
      <Description>
        Get the most out of your first expert session. Complete a short
        questionnaire to help your expert get to know you better before your
        meeting.
      </Description>
      <Button id="complete-profile" onClick={startSurvey}>
        Start
      </Button>
      <TimeWrapper>
        <TimeDisplay variant="caption">1 min</TimeDisplay>
      </TimeWrapper>
    </Wrapper>
  );
};
