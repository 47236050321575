import React, { useState } from "react";
import dayjs from "dayjs";
import { ArrowButtonsRow, CalendarGrid, Heading, RightArrow } from "./styles";
import { ArrowSmall } from "src/assets/icons";
import ColorsDescription from "./ColorsDescription";
import CalendarItem from "./CalendarItem";
import { useGetUpcomingSessions } from "src/api/resolvers";
import { Typography } from "@mui/material";
import theme from "src/theme";

const Calendar = () => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
  const { data: scheduledSessions } = useGetUpcomingSessions();

  const sessionDates = scheduledSessions?.map(({ next_session, type }) => ({
    date: dayjs(next_session),
    type,
  }));

  const dayjsObject = dayjs().month(selectedMonth);
  const firstDayOfMonth = dayjsObject.date(1);
  const dayOfWeek = firstDayOfMonth.day();
  const end = dayjsObject.endOf("month");

  const cellsCount = end.date() + dayOfWeek + (7 - end.day() - 1);

  const cellsArray = new Array(cellsCount).fill(0).map((_, i) => {
    const date = i - dayOfWeek + 1;
    if (date > 0 && date <= end.date()) {
      const type = sessionDates?.find(
        ({ date: sessionDate }) =>
          sessionDate.date() === date && sessionDate.month() === selectedMonth
      )?.type;

      return {
        item: date,
        type,
        backgroundColor:
          dayjs().date() === date &&
          dayjs().month() === selectedMonth &&
          theme.palette.info.dark,
      };
    } else {
      return 0;
    }
  });

  return (
    <div>
      <ColorsDescription />
      <Heading>
        <Typography variant="h2">
          {firstDayOfMonth.format("MMMM YYYY")}
        </Typography>
        <ArrowButtonsRow>
          <div
            onClick={() => setSelectedMonth((prev) => prev - 1)}
            id="calendar-prev"
          >
            <ArrowSmall />
          </div>
          <RightArrow
            onClick={() => setSelectedMonth((prev) => prev + 1)}
            id="calendar-next"
          >
            <ArrowSmall />
          </RightArrow>
        </ArrowButtonsRow>
      </Heading>
      <CalendarGrid>
        {[
          ...["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
          ...cellsArray,
        ].map((item, i) => (
          <CalendarItem data={item} key={i} />
        ))}
      </CalendarGrid>
    </div>
  );
};

export default Calendar;
