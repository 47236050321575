import React, { useEffect, useState } from "react";
import { ArrowDown } from "src/assets/icons";
import { ButtonText, ButtonWrapper } from "./styles";

interface DropDownButtonProps {
  onOpen: () => void;
  text: string;
  sx: any;
  isOpened?: boolean;
  id: string;
}

export const DropDownButton = ({
  onOpen,
  text,
  sx,
  isOpened,
  id,
}: DropDownButtonProps) => {
  const [opened, setOpened] = useState(isOpened);

  useEffect(() => {
    setOpened(isOpened);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  const onPress = () => {
    if (!opened) {
      onOpen();
    }

    setOpened((prev) => !prev);
  };

  return (
    <div onClick={onPress} id={id}>
      <ButtonWrapper sx={sx}>
        <ButtonText variant="body3">{text}</ButtonText>
        <ArrowDown />
      </ButtonWrapper>
    </div>
  );
};
