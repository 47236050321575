import { Typography } from "@mui/material";
import { Edit, Export, PlusSquare } from "src/assets/icons";
import { Button } from "../Button";
import { Wrapper, Steps, Step } from "./style";

const Instruction = ({ onClose }: any) => {
  return (
    <Wrapper>
      <Typography variant="h2">OurRitual on your home screen</Typography>
      <Typography>
        No more looking for links! Follow these three easy steps using Safari:
      </Typography>
      <Steps>
        <Step>
          <Export />
          <Typography>Tap this icon{"\n"}(bottom center)</Typography>
        </Step>
        <Step>
          <PlusSquare />
          <Typography>Scroll down and tap the + icon</Typography>
        </Step>
        <Step>
          <Edit />
          <Typography>Tap “Add”{"\n"}(top right)</Typography>
        </Step>
      </Steps>
      <Button id="add_to_homescreen_instruction" onClick={onClose}>
        Got it
      </Button>
    </Wrapper>
  );
};

export default Instruction;
