import React from "react";
import { Title, Layout, WrapperButton } from "./styles";
import { Button } from "src/components";

import {
  useGetSubscriptionResolver,
  useSubscriptionCmsDetails,
} from "src/api/resolvers";
import { Caption } from "src/pages/Subscription/styles";
import { ROUTES } from "src/types/routes";
import { useNavigate } from "react-router-dom";
import { useReactivation } from "src/contexts/ReactivationContext";
import {
  useGetMemberQuery,
  useSubscriptionReactivateMutation,
} from "src/api/main";
import Loader from "src/pages/Subscription/SubscriptionDetails/Loader";
import SubscriptionDetails from "src/pages/Subscription/SubscriptionDetails";

const SubscriptionReactivateModal = () => {
  const { data: details, isLoading: isCmsDetailsLoading } =
    useSubscriptionCmsDetails();
  const { data: subscription, isLoading: isSubscriptionLoading } =
    useGetSubscriptionResolver();
  const navigate = useNavigate();
  const { manageModalWelcomeBack } = useReactivation();
  const { data: member } = useGetMemberQuery();
  const [reactivateSubscription] = useSubscriptionReactivateMutation();

  const processModal = () => {
    reactivateSubscription(member?.uuid || "");
    navigate(ROUTES.HOME);
    manageModalWelcomeBack();
  };
  if (isCmsDetailsLoading || isSubscriptionLoading) {
    return <Loader />;
  }
  return (
    <Layout buttonId="popup_reactivatemembership_declined">
      <Title variant="headline2">Reactivate your membership</Title>
      <SubscriptionDetails isReactivate={true}/>
      <Caption variant="caption">{`By clicking “Reactivate membership”, you agree to our terms & will re-authorize a monthly $${subscription?.price} charge to any stored payment method.`}</Caption>
      <WrapperButton>
        <Button
          id="popup_reactivatemembership_confirmed"
          onClick={processModal}
        >
          Reactivate membership
        </Button>
      </WrapperButton>
    </Layout>
  );
};

export default SubscriptionReactivateModal;
