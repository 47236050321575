import { styled, Typography } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "16px",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingBottom: "30px",
  backgroundColor: theme.palette.info.light,
  position: "relative",
});

export const Title = styled(Typography)({
  fontSize: "26px",
  lineHeight: "35px",
  width: "100%",
  textAlign: "center",
  color: "black",
  zIndex: 10,
});

export const Icon = styled("img")({
  width: "96px",
  height: "96px",
  marginBottom: "8px",
  zIndex: 10,
});

export const Illustration = styled("img")({
  position: "absolute",
  width: "100%",
  bottom: 0,
  opacity: 0.6,
  objectFit: "contain",
});
