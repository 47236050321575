import { useLocation, useNavigate } from "react-router-dom";
import { Growthwork, GWPlatforms, MemberGrowthworkUnion } from "src/api/types";
import { ROUTES, taskRoutesByPlatform } from "src/types/routes";

const useRedirectToTask = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const redirectToTask = (growthwork: Growthwork | MemberGrowthworkUnion) => {
    let platform = growthwork.platform;
    if (growthwork?.baselink.includes("async_audio"))
      platform = GWPlatforms.AUDIO;

    const isGwReady =
      growthwork.platform !== GWPlatforms.WISTIA &&
      !(growthwork as any)?.url_hash;

    const redirectLink = isGwReady
      ? ROUTES.PREPARING_PATHWAY
      : taskRoutesByPlatform[platform];

    navigate(redirectLink, {
      state: {
        growthwork,
        referer: pathname,
      },
    });
  };

  return redirectToTask;
};

export default useRedirectToTask;
