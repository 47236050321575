import { styled, Typography } from "@mui/material";

export const Wrapper = styled("div")({
  paddingBottom: "116px",
});

export const Title = styled(Typography)({
  fontFamily: "Avenir Next",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "21px",
  width: "100%",
  marginTop: "40px",
  marginLeft: "10px",
});

export const Subtitle = styled(Typography)({
  fontFamily: "Avenir Next",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  marginTop: "4px",
  marginLeft: "10px",
});
