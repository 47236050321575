import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import QRCode from "react-qr-code";
import { Wrapper } from "src/components/QRCode/styles";

interface QRCodeProps {
  url: string;
  size?: number;
}

const QRCodeScreen = ({ url, size }: QRCodeProps) => {
  const { showNativeAppQr } = useFlags();

  const nativeAppStoresLink = `${process.env.REACT_APP_NATIVE_APP_STORE_LINK}`;

  return (
    <Wrapper>
      <QRCode
        value={showNativeAppQr ? nativeAppStoresLink : url}
        size={size || 300}
      />
    </Wrapper>
  );
};

export default QRCodeScreen;
