import { styled } from "@mui/material";
import theme from "src/theme";
import Typography from "../../../../components/Typography";

export const Wrapper = styled("div")({
  margin: "10px 20px",
  border: `1px solid ${theme.palette.primary.main}`,
  padding: "12px 16px 16px",
  position: "relative",
  backgroundColor: "#fff",
  borderRadius: "24px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const Illustration = styled("img")({
  maxWidth: "155px",
  margin: "auto",
});

export const Title = styled(Typography)({
  marginTop: "8px",
  textAlign: "center",
});

export const Description = styled(Typography)({
  margin: "8px 0",
  textAlign: "center",
});

export const TimeWrapper = styled(Typography)({
  margin: "8px 0",
  padding: "1px 6px",
  textAlign: "center",
  backgroundColor: theme.palette.success.light,
  position: "absolute",
  top: "5%",
  right: "4%",
  borderRadius: "6px",
});

export const TimeDisplay = styled(Typography)({
  color: theme.palette.grey[600],
});
