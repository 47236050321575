import useJoinSession from "src/hooks/useJoinSession";
import { SessionButton } from "./styles";

const JoinSessionButton = ({ disabled = false }: { disabled?: boolean }) => {
  const { joinSession } = useJoinSession();

  return (
    <SessionButton
      id="join-session"
      onClick={() => joinSession()}
      disabled={disabled}
    >
      Join session
    </SessionButton>
  );
};

export default JoinSessionButton;
