import { styled, Typography } from "@mui/material";
import theme from "src/theme";

export const Title = styled(Typography)({
  width: "100%",
  textAlign: "center",
  fontFamily: "Garnett",
  fontSize: "22px",
  lineHeight: "30px",
  marginBottom: "16px",
  marginTop: "16px",
});

export const Subtitle = styled(Typography)({
  width: "100%",
  textAlign: "center",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "18px",
  color: theme.palette.grey[600],
});
