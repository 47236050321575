import * as React from "react";
import { SVGProps } from "react";
export const VideoLabel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#575961"
      stroke="#575961"
      d="m14.32 7.57-.004.002-9.907 5.974a.38.38 0 0 1-.576-.321V6.558c0-2.523 2.725-4.1 4.918-2.841l3.824 2.2 1.739.997a.382.382 0 0 1 .005.656ZM14.826 12.45h-.001L11.45 14.4l-3.365 1.942a2.795 2.795 0 0 1-3.03-.125l-.004-.003a.274.274 0 0 1-.116-.255.348.348 0 0 1 .17-.278l.002-.002L15.7 9.33c.218-.13.467.008.499.209.179 1.107-.278 2.284-1.372 2.912Z"
    />
  </svg>
);
