import React from "react";
import { useGetMemberQuery } from "src/api/main";
import { Typography } from "src/components";
import theme from "src/theme";
import { LoginTypes } from "src/types/login";

export const FinishedOnboardingTitle = () => (
  <Typography variant="headline">Welcome to OurRitual!</Typography>
);

export const FinishedOnboardingDescription = () => {
  const { data: member } = useGetMemberQuery();

  return (
    <Typography variant="headline4" color={theme.palette.grey[600]}>
      Switch to your mobile device to begin your journey. We texted you a link
      to <span>{member?.phone_number}</span>.
    </Typography>
  );
};

export const DesktopViewTitle = ({ type }: { type?: string }) => {
  const title =
    type === LoginTypes.ACCESSLINK
      ? "Request an access link. "
      : "Are you a Member? ";

  return (
    <Typography variant="headline">
      {title}
      <span>Switch to mobile</span>
    </Typography>
  );
};

export const DesktopViewDescription = ({ type }: { type?: string }) => {
  const isAccessLinkLogin = type === LoginTypes.ACCESSLINK;

  return (
    <Typography variant="headline4" color={theme.palette.grey[600]}>
      Enter your {isAccessLinkLogin ? "email address" : "phone number"}; if
      you're an OurRitual member, we'll{" "}
      {isAccessLinkLogin ? "send you a link" : "generate a QR code"} for
      seamless access and login to the app
    </Typography>
  );
};
