import React from "react";
import { Text } from "./styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";

const SupportFooter = () => {
  const navigate = useNavigate();

  return (
    <Text variant="body-medium">
      If the problem continues and you're sure it's not a network issue, reach
      out to our{" "}
      <span onClick={() => navigate(ROUTES.CONTACT_US)}>Support team</span>
    </Text>
  );
};

export default SupportFooter;
