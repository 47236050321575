import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMemberQuery } from "src/api/main";
import {
  useGetActiveModuleQueryResolver,
  useGetMemberModulesQueryResolver,
  useGetOnboardingStatusQueryResolver,
} from "src/api/resolvers";
import { AppStatuses } from "src/api/types";
import useOnboardingSteps from "src/pages/Onboarding/utils/useOnboardingSteps";
import { ROUTES } from "src/types/routes";

const useRedirection = () => {
  const navigate = useNavigate();
  const { data: module, isFetching: isActiveModuleFetching } =
    useGetActiveModuleQueryResolver();
  const { data: member, isFetching: isMemberFetching } = useGetMemberQuery();
  const { isFetching: isStepsFetching } = useGetOnboardingStatusQueryResolver();
  const onboardingSteps = useOnboardingSteps();
  const { data: modules, isFetching: isModulesFetching } =
    useGetMemberModulesQueryResolver();
  const [isRedirectProcessed, setIsRedirectProcessed] = useState(false);

  const isFetching = useMemo(
    () =>
      isModulesFetching ||
      isMemberFetching ||
      isStepsFetching ||
      isActiveModuleFetching,
    [
      isActiveModuleFetching,
      isMemberFetching,
      isModulesFetching,
      isStepsFetching,
    ]
  );

  useEffect(() => {
    if (isFetching) return;

    if (member?.app_status === AppStatuses.ONBOARDING) {
      const onboardingAppCompleted = onboardingSteps.every(
        ({ completed }) => completed
      );
      const isSecondaryMember = member.primary_member;
      navigate(
        onboardingAppCompleted || isSecondaryMember
          ? ROUTES.CAROUSEL
          : ROUTES.ONBOARDING_INITIAL
      );
    } else if (modules && !module) {
      navigate(ROUTES.MODULE_SELECTION);
    }
    setIsRedirectProcessed(true);
  }, [member, onboardingSteps, navigate, isFetching, modules, module]);

  return {
    isFetching: !isRedirectProcessed,
  };
};

export default useRedirection;
