import { Member } from "src/api/types";

type shortLinkGeneratorArgs = {
  domain: string;
  originalURL: string;
  expiresAt?: number;
  allowDuplicates?: boolean;
  expiredURL?: string;
  title?: string;
  cloaking?: boolean;
  // There are many more args that can come handy. Check this URL for more info
  // https://developers.short.io/reference/linkspostpublic
};

const DOMAIN = process.env.REACT_APP_CONNECT_DOMAIN || "";

const shortLinkGenerator = async (
  data: shortLinkGeneratorArgs,
  apiKey: string
) => {
  let shortLink = "";
  await fetch("https://api.short.io/links/public", {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      Authorization: apiKey,
    },
    body: JSON.stringify(data),
  })
    .then((r) => r.json())
    .then((d) => {
      shortLink = d.shortURL;
    })
    .catch((err) => console.error(err));
  return shortLink;
};

export default shortLinkGenerator;

export const dataForShortLink = {
  domain: DOMAIN,
  originalURL: window.location.origin,
  expiresAt: Date.now() + 7 * 24 * 60 * 60 * 1000,
  expiredURL: window.location.origin,
};

export const getMemberEnrichedUrl = (url: string, member: Member) => {
  let originalURL = new URL(url);
  const queryParams = new URL(originalURL).searchParams;
  queryParams.set("email", member.email);
  queryParams.set("phone", member.phone_number);

  return (
    originalURL.origin + originalURL.pathname + "?" + queryParams.toString()
  );
};
