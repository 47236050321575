import React, { useEffect } from "react";
import { BottomPopup, useSheet } from "src/components/BottomPopup";
import { DropDownButton } from "src/components/DropDownButton";
import PathwaysLibraryList from "./PathwaysLibraryList";
import { PathwayPickerLoader } from "./Loaders";
import { useGetLibraryState } from "src/store/selectors";

const PathwayPicker = () => {
  const sheet = useSheet();
  const { currentPathway } = useGetLibraryState();

  useEffect(() => {
    if (sheet.isOpen) {
      sheet.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPathway]);

  if (!currentPathway) {
    return <PathwayPickerLoader />;
  }

  return (
    <>
      <DropDownButton
        id="open-library-pathway-list"
        isOpened={sheet.isOpen}
        onOpen={sheet.open}
        text={currentPathway.name}
        sx={{ marginLeft: "20px" }}
      />
      <BottomPopup {...sheet}>
        <PathwaysLibraryList />
      </BottomPopup>
    </>
  );
};

export default PathwayPicker;
