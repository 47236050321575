import styled from "@emotion/styled";
import theme from "src/theme";
import { Button } from "src/components";

export const SessionButton = styled(Button)({
  maxWidth: "450px",
  borderRadius: "1000px",
  background: theme.palette.primary.main,

  "&:disabled": {
    backgroundColor: theme.palette.error.light,
  },
});
