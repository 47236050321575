import { Typography } from "@mui/material";
import couple1 from "src/assets/images/couple1.png";
import { ModuleBannerWrapper } from "../../styles";

interface ModuleBannerProps {
  partDescription: string;
  partGoal: string;
}

const ModuleBanner = ({ partDescription, partGoal }: ModuleBannerProps) => {
  return (
    <ModuleBannerWrapper>
      <div>
        <Typography variant="h4">{partGoal}</Typography>
        <Typography sx={{ color: "grey.600" }}>{partDescription}</Typography>
      </div>
      <img src={couple1} alt="couple" />
    </ModuleBannerWrapper>
  );
};

export default ModuleBanner;
