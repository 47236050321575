import { Typography, styled } from "@mui/material";
import { Button } from "src/components";

export const Wrapper = styled("div")({
  backgroundColor: "white",
  padding: "25px",
  borderRadius: "10px",
  width: "285px",
});

export const AlertTitle = styled(Typography)({
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "22px",
  textAlign: "center",
});

export const AlertText = styled(Typography)({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  marginTop: "8px",
  marginBottom: "5px",
  textAlign: "center",
});

export const AlertButton = styled(Button)({
  marginTop: "10px",
  borderRadius: "5px",
});
