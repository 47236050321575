import * as React from "react";

export const OurRitualLogoMark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={73} height={64} fill="none">
    <g
      fillRule="evenodd"
      clipPath="url(#ourritual-logo-mark)"
      clipRule="evenodd"
    >
      <path
        fill="#FF5B4C"
        d="M23 0C0 0 0 28 0 32s0 32 23 32 23-28 23-32S46 0 23 0Z"
      />
      <path
        fill="#FF5B4C"
        d="M47 0C27 0 23 8 23 18c0 10.667 7.976 25.667 23.928 45h25.269L55 36c13 0 16-12 16-18S67 0 47 0Z"
      />
      <path
        fill="#8C2C38"
        d="M32.713 2.104c12.808 6.35 13.27 25.143 13.286 29.473v.847c-.012 3.194-.266 14.26-5.54 22.394C28.818 39.383 23 27.11 23 18c0-6.9 1.904-12.847 9.655-15.872l.058-.024Z"
      />
    </g>
    <defs>
      <clipPath id="ourritual-logo-mark">
        <path fill="#fff" d="M0 0h73v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
