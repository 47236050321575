import { Typography } from "@mui/material";
import { useGetMemberQuery } from "src/api/main";
import { ReddishLogoSm } from "src/assets/icons";
import { Wrapper } from "./styles";

const Header = () => {
  const { data: member } = useGetMemberQuery();
  
  return (
    <Wrapper>
      <ReddishLogoSm />
      <Typography variant="h2">Hello {member?.first_name}</Typography>
    </Wrapper>
  );
};

export default Header;
