import dayjs from "dayjs";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import { useGetExpertQueryResolver } from "src/api/resolvers";

const SESSION_START_GAP = 2;

export const isSessionStarted = (sessionStartTime?: string) => {
  const sessionTime = dayjs(sessionStartTime);
  const currentTime = dayjs(new Date());

  const timeDiff = sessionTime.diff(currentTime, "minute");

  return timeDiff < SESSION_START_GAP;
};

export const useIsTransitionActivated = () => {
  const { transitionPageActivated } = useFlags();
  const { data: expert } = useGetExpertQueryResolver();

  const isTransitionActivated = useMemo(
    () => transitionPageActivated?.experts.includes(expert?.email),
    [transitionPageActivated, expert]
  );

  return isTransitionActivated;
};

export const getTimeDifference = (sessionTime?: string) => {
  const dayjsSessionTime = dayjs(sessionTime);
  const currentTime = dayjs(new Date());

  return dayjsSessionTime.diff(currentTime, "hour");
};
