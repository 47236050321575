import { FC } from "react";

interface VideoAskEmbedProps {
  link: string;
}

const VideoAskEmbed: FC<VideoAskEmbedProps> = ({ link }) => {
  return (
    <iframe
      title="videoAskIFrame"
      id="videoAsk-iframe"
      style={{
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100%",
        border: 0,
      }}
      src={link}
      allowFullScreen
      allow="gyroscope; accelerometer; microphone; camera; geolocation;"
    />
  );
};

export default VideoAskEmbed;
