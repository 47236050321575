import { styled } from "@mui/material";
import { Typography } from "src/components";

export const Wrapper = styled("div")({
  backgroundColor: "white",
  padding: "24px",
  borderRadius: "20px",
  marginTop: "24px",
  boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.05)",
});

export const DetailsHeader = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const Title = styled(Typography)({
  marginTop: "4px",
  marginBottom: "8px",
});

export const BillingDetails = styled("div")({
  padding: "16px 0",

  span: {
    fontWeight: 600,
  },
});

export const Details = styled("div")({
  padding: "16px 0",
  borderTop: "1px solid #DFE1E6",
});

export const Price = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "4px",
  alignItems: "center",
});

export const ListItem = styled("div")({
  marginTop: "6px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
});

export const Illustration = styled("img")({
  width: "85px",
  height: "72px",
});
