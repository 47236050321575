import React from "react";
import { CloseButton, Wrapper } from "./styles";
import { Typography } from "@mui/material";

const Header = ({ onClose }: { onClose: () => void }) => {
  return (
    <Wrapper>
      <CloseButton id="close-session-manager" onClick={() => onClose()} />
      <Typography variant="h2">Your schedule</Typography>
    </Wrapper>
  );
};

export default Header;
