import React, { PropsWithChildren } from "react";
import Navigation from "../Navigation";
import { Content, ContentWrapper, Wrapper } from "./styles";
import { SxProps, Theme } from "@mui/material";

interface LayoutWithoutHeaderProps extends PropsWithChildren {
  Footer?: any;
  sx?: SxProps<Theme>;
}

const LayoutWithoutHeader = ({
  children,
  Footer,
  sx,
}: LayoutWithoutHeaderProps) => {
  return (
    <Wrapper sx={sx}>
      <ContentWrapper>
        <Content id="content">{children}</Content>
        {Footer && <Footer />}
      </ContentWrapper>
      <Navigation />
    </Wrapper>
  );
};

export default LayoutWithoutHeader;
