import { useEffect } from "react";
import { useGetMemberQuery } from "src/api/main";
import { useAbly } from "src/contexts/AblyContext";
import { useAppDispatch } from "src/store/hooks";
import { updateUnreadMessagesCount } from "src/store/supportReducer";

const SUPPORT_REPLY_EVENT = "support-replied";

const useSubscribeToSupportReply = () => {
  const { subscribe, isReady } = useAbly();
  const dispatch = useAppDispatch();

  const { data: member } = useGetMemberQuery();

  useEffect(() => {
    if (!isReady || !member) return;

    subscribe({
      name: SUPPORT_REPLY_EVENT,
      callback: () => {
        dispatch(updateUnreadMessagesCount(1));
      },
      field: "email",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, member]);
};

export default useSubscribeToSupportReply;
