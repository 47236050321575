import { styled } from "@mui/material";
import { Typography, Button } from "src/components";
import theme from "src/theme";

export const Wrapper = styled("div")({
  backgroundColor: "#FCE1D0",
  padding: "16px 20px",
  position: "sticky",
  top: 0,
  zIndex: 10000000000,
});

export const Title = styled(Typography)({
  fontWeight: 600,
  color: theme.palette.grey.A200,
});

export const Description = styled(Typography)({
  marginTop: "6px",
  color: theme.palette.grey.A200,
});

export const ActionButton = styled(Button)({
  lineHeight: "20px",
  fontSize: "14px",
  width: "fit-content",
  marginTop: "16px",
  borderRadius: "10px",

  [theme.breakpoints.down("lg")]: {
    padding: "6px 45px",
  },
});
