import React from "react";
import ControlledAudioPlayer from "src/components/ControlledAudioPlayer";
import Label from "../Label";
import { AudioResponse as AudioResponseIcon } from "src/assets/icons";
import { Wrapper } from "./styles";

const AudioResponse = ({ response }: { response: string }) => {
  return (
    <div>
      <Label title="Audio response" Icon={AudioResponseIcon} />
      <Wrapper>
        <ControlledAudioPlayer src={response} title="Your response" />
      </Wrapper>
    </div>
  );
};

export default AudioResponse;
