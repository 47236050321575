import { createSlice } from "@reduxjs/toolkit";
import { FeedbackTypes } from "src/api/types";

export interface FeedbackState {
  opened: boolean;
  type: FeedbackTypes | null;
  event_fields?: object;
}

const initialState = {
  opened: false,
  type: null,
  event_fields: {},
} as FeedbackState;

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    openFeedback(
      state,
      { payload }: { payload: { type: FeedbackTypes; event_fields?: object } }
    ) {
      state.opened = true;
      state.type = payload.type;
      state.event_fields = payload.event_fields;
    },
    closeFeedback(state) {
      state.opened = false;
      state.type = null;
    },
  },
});

export const { openFeedback, closeFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
