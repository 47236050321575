import { ModuleRelation } from "src/api/types";

export const getPartGrowthworks = (
  list: ModuleRelation[],
  expertId: string
) => {
  const { commonGrowthworks, expertsGrowthworks } = splitGrowthworks(list);
  const uniqueExpertsGrowthworks = getUniqueExpertGrowthworks(
    expertsGrowthworks,
    expertId
  );
  return [...commonGrowthworks, ...uniqueExpertsGrowthworks];
};

const splitGrowthworks = (list: ModuleRelation[]) => {
  const commonGrowthworks: ModuleRelation[] = [];
  const expertsGrowthworks: { [key: number]: ModuleRelation[] } = {};

  list.forEach((growthwork) => {
    const expertData = growthwork.gw.data.attributes.expert?.data;
    if (!expertData) commonGrowthworks.push(growthwork);
    else {
      expertsGrowthworks[growthwork.gw_order] = [
        ...(expertsGrowthworks?.[growthwork.gw_order] || []),
        growthwork,
      ];
    }
  });

  return { commonGrowthworks, expertsGrowthworks };
};

const getUniqueExpertGrowthworks = (
  list: {
    [key: number]: ModuleRelation[];
  },
  expertId: string
) => {
  const uniqueExpertGrowthworks: ModuleRelation[] = [];
  Object.values(list).forEach((growthwork) => {
    const expertGW = growthwork.find(
      ({ gw }) =>
        gw.data.attributes.expert.data.attributes.mgmt_uid === expertId
    );
    uniqueExpertGrowthworks.push(expertGW || growthwork[0]);
  });
  return uniqueExpertGrowthworks;
};
