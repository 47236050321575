import React, { PropsWithChildren } from "react";
import { OurRitualLogo } from "src/assets/icons";
import { Logo, Subtitle, Title, Wrapper } from "./styles";

interface IllustrationPageLayoutProps extends PropsWithChildren {
  illustration: string;
  TitleText: JSX.Element | string;
  SubtitleText: JSX.Element | string;
}

const IllustrationPageLayout = ({
  illustration,
  TitleText,
  SubtitleText,
  children,
}: IllustrationPageLayoutProps) => {
  return (
    <Wrapper>
      <Logo>
        <OurRitualLogo />
      </Logo>
      <img src={illustration} alt="page_illustration" />
      <Title variant="headline">{TitleText}</Title>
      <Subtitle>{SubtitleText}</Subtitle>
      {children}
    </Wrapper>
  );
};

export default IllustrationPageLayout;
