import React from "react";
import { ROUTES } from "src/types/routes";
import LoaderWithIllustration from "../LoaderWithIllustration";
import Loading from "../Loading";

const loaders: { [key: string]: JSX.Element } = {
  [ROUTES.ONBOARDING_BOOK_WELCOME_SESSION]: (
    <LoaderWithIllustration text="Verifying your account" />
  ),
};

const MemberLoader = () => {
  const path = window.location.pathname;
  if (path in loaders) {
    return loaders[path];
  } else {
    return <Loading />;
  }
};

export default MemberLoader;
