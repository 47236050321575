import styled from "@emotion/styled";
import theme from "src/theme";

export const PageWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  backgroundColor: "white",
});

export const Wrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  flex: 1,
  backgroundColor: "white",

  img: {
    height: "35vh",
    marginTop: "20px",
  },
});

export const TextWrapper = styled("div")({
  maxWidth: "680px",
  span: {
    color: theme.palette.primary.main,
  },

  "h1, h4": {
    textAlign: "center",
  },

  h1: {
    marginTop: "50px",
  },

  h4: {
    marginTop: "15px",
  },
});

export const PhoneInputWrapper = styled("div")({
  width: "100%",
  marginTop: "10px",
  maxWidth: "580px",

  p: {
    textAlign: "center",
    color: theme.palette.grey[100],
  },
});

export const PhoneInputForm = styled("div")({
  display: "flex",
  width: "100%",
  gap: "16px",

  marginTop: "16px",

  button: {
    maxWidth: "168px",
  },

  "> div": {
    width: "100%",
  },
});

export const Footer = styled("div")({
  marginTop: "20px",
  marginBottom: "30px",
});
