import React from "react";
import { GWPlatforms } from "src/api/types";
import StartTaskButton from "./components/StartTaskButton";
import JournalActions from "./components/JournalActions";
import { useFlags } from "launchdarkly-react-client-sdk";
import { GrowthworkActionProps } from "./types";

const GrowthworkAction = ({ platform, ...rest }: GrowthworkActionProps) => {

  switch (platform) {
    case GWPlatforms.VIDEOASK:
      return <JournalActions {...rest} />;
    default:
      return <StartTaskButton {...rest} />;
  }
};

export default GrowthworkAction;
