import { Fragment, useMemo } from "react";
import NavigationItem from "./NavigationItem";
import { Wrapper } from "./styles";
import { Home, Journey } from "src/assets/icons";
import { ROUTES } from "src/types/routes";
import UserAvatar from "../UserAvatar";
import ChatNavigationIcon from "../ChatNavigationIcon";

const Navigation = () => {
  const navigationItems = useMemo(
    () => [
      {
        Icon: Home,
        title: "Home",
        disabled: false,
        redirectTo: ROUTES.HOME,
      },
      {
        Icon: ChatNavigationIcon,
        title: "Chat",
        disabled: false,
        redirectTo: ROUTES.CHAT_INITIAL,
        hidden: false,
      },
      {
        Icon: Journey,
        title: "Journey",
        disabled: false,
        redirectTo: ROUTES.JOURNEY,
      },
      {
        Icon: UserAvatar,
        title: "Profile",
        disabled: false,
        redirectTo: ROUTES.PROFILE,
      },
    ],
    []
  );

  return (
    <Wrapper>
      {navigationItems.map((item) => (
        <Fragment key={item.title}>
          {!item?.hidden && <NavigationItem {...item} />}
        </Fragment>
      ))}
    </Wrapper>
  );
};

export default Navigation;
