import React from "react";
import {
  useGetExpertCmsDetails,
  useGetExpertQueryResolver,
} from "src/api/resolvers";
import ProfileItemCard from "..";

const ExpertCard = ({ Icon }: { Icon?: (props: any) => JSX.Element }) => {
  const { data: expertCMS } = useGetExpertCmsDetails();
  const { data: expert } = useGetExpertQueryResolver();

  return (
    <ProfileItemCard
      name="Expert"
      title={expert ? `${expert?.first_name} ${expert?.last_name}` : ""}
      image={expertCMS?.avatar}
      Icon={Icon}
    />
  );
};

export default ExpertCard;
