import React from "react";
import { useSessionManagerContext } from "../../utils/reducer";
import useActions from "../../utils/useActions";

import {
  useGetAllowedSessionTypes,
  useGetSessionLinksQueryResolver,
} from "src/api/resolvers";
import { ScheduleButton, StyledModal, Wrapper } from "./styles";
import { useGetSessionParams } from "../../utils/useGetSessionLinks";
import { Typography } from "@mui/material";
import { SessionType } from "src/api/types";

const SelectSessionTypeModal = () => {
  const {
    state: { isSelectTypeModalOpened },
  } = useSessionManagerContext();
  const { closeTypeSelectModal, setCalendlyLink } = useActions();
  const { data: sessionLinks } = useGetSessionLinksQueryResolver();
  const sessionTypes = useGetAllowedSessionTypes();

  const getSessionParams = useGetSessionParams();

  const onClick = (type: SessionType) => () => {
    const sessionLink = sessionLinks?.find(
      (sessionEvent) => type === sessionEvent.type
    );
    setCalendlyLink(`${sessionLink?.link}${getSessionParams(type)}`);
  };

  return (
    <StyledModal open={isSelectTypeModalOpened} onClose={closeTypeSelectModal}>
      <Wrapper>
        <Typography>I would like to book a session...</Typography>
        {sessionTypes?.map(({ book_session_text, type }) => (
          <ScheduleButton
            key={book_session_text}
            id="session-type-selected"
            onClick={onClick(type)}
          >
            {book_session_text}
          </ScheduleButton>
        ))}
      </Wrapper>
    </StyledModal>
  );
};

export default SelectSessionTypeModal;
