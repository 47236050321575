import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "15px",
  marginBottom: "20px",

  p: {
    fontWeight: 600
  }
});
