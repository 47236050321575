import { styled } from "@mui/material";
import theme, { DESKTOP_MOCKUP_HEIGHT, getResponsiveValue } from "src/theme";
import Typography from "../Typography";

export const Wrapper = styled("div")({
  background: "white",

  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",

  padding: "24px",
  textAlign: "center",
  img: {
    maxWidth: "100%",
    maxHeight: getResponsiveValue(280, DESKTOP_MOCKUP_HEIGHT, 0.75),
  },

  span: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
});

export const Logo = styled("div")({
  position: "absolute",
  top: getResponsiveValue(50, DESKTOP_MOCKUP_HEIGHT),
  left: "50%",
  transform: "translateX(-50%)",
});

export const Title = styled(Typography)({
  marginTop: getResponsiveValue(65, DESKTOP_MOCKUP_HEIGHT),

  [theme.breakpoints.down("sm")]: {
    marginTop: "40px",
  },
});

export const Subtitle = styled(Typography)({
  fontWeight: 500,
  marginTop: "16px",
  whiteSpace: "break-spaces",
  textAlign: "center",
  color: theme.palette.grey.A200,

  [theme.breakpoints.down("sm")]: {
    marginTop: "8px",
  },
});
