import React from "react";
import { OurRitualLogo } from "src/assets/icons";
import theme from "src/theme";
import { Typography } from "src/components";
import illustration from "src/assets/images/email.svg";
import {
  ContactUsWrapper,
  Illustration,
  TextWrapper,
  Wrapper,
} from "src/pages/CheckEmail/styles";
import { SUPPORT_EMAIL } from "src/constants/support";

const CheckEmail = () => {
  return (
    <Wrapper>
      <OurRitualLogo />
      <Illustration src={illustration} alt="check-email-illustration" />
      <TextWrapper>
        <Typography variant="headline">
          Check
          <span style={{ color: theme.palette.primary.main }}> your email</span>
        </Typography>
        <Typography variant="headline4" color={theme.palette.grey[600]}>
          We’ve sent you an email with the access link. Open it from your phone
          and follow the instructions to access the OurRitual app.
        </Typography>
        <ContactUsWrapper>
          <Typography variant="headline4" color={theme.palette.grey[600]}>
            If you don’t receive any email, check your spam folder or
          </Typography>
          <a href={SUPPORT_EMAIL}>
            <Typography variant="headline4" color={theme.palette.primary.main}>
              Contact us
            </Typography>
          </a>
        </ContactUsWrapper>
      </TextWrapper>
    </Wrapper>
  );
};

export default CheckEmail;
