import React from "react";
import { Button } from "../Button";
import {
  Content,
  Illustration,
  SecondaryButton,
  Subtitle,
  Title,
  Wrapper,
  CloseButton,
} from "./styles";
import { Modal } from "@mui/material";
import { Close } from "src/assets/icons";

interface Action {
  text: string;
  id: string;
  onClick: () => void;
}

interface NotificationPopupLayoutProps {
  illustration: string;
  title: string;
  text: string;
  primaryAction: Action;
  secondaryAction?: Action;
  modal: { open: boolean; onClose: () => void };
}

export const NotificationPopupLayout = ({
  illustration,
  title,
  text,
  primaryAction,
  secondaryAction,
  modal,
}: NotificationPopupLayoutProps) => {
  return (
    <Modal {...modal}>
      <Wrapper>
        <Content>
          <CloseButton onClick={modal.onClose}>
            <Close />
          </CloseButton>
          <Illustration src={illustration} alt="notification_illustration" />
          <Title variant="headline3">{title}</Title>
          <Subtitle>{text}</Subtitle>
          <Button id={primaryAction.id} onClick={primaryAction.onClick}>
            {primaryAction.text}
          </Button>
          {secondaryAction && (
            <SecondaryButton
              id={secondaryAction.id}
              onClick={secondaryAction.onClick}
              color="borderless"
            >
              {secondaryAction.text}
            </SecondaryButton>
          )}
        </Content>
      </Wrapper>
    </Modal>
  );
};
