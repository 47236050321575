import { styled, Typography } from "@mui/material";
import theme from "src/theme";

export const Content = styled("div")({
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1,
});

export const Description = styled(Typography)({
  marginTop: "8px",
  marginBottom: "24px",
  fontWeight: 400,
  color: theme.palette.grey.A200,
});

export const ButtonContainer = styled("div")({
  marginTop: "40px",
});

export const ReturnButton = styled(Typography)({
  marginTop: "30px",
  width: "100%",
  textAlign: "center",
  color: theme.palette.grey[600],
  fontWeight: 600,
});
