import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  marginTop: "17px",
  marginRight: "13px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  svg: {
    margin: "8px auto",
  },
});

export const Circle = styled("div")(({ theme }) => ({
  margin: "8px auto",
  width: "16px",
  height: "16px",
  borderRadius: "50%",
  border: "2px solid",
}));

export const Divider = styled("div")<{ disabled: boolean }>(
  ({ theme, disabled }) => ({
    width: "2px",
    background: disabled ? theme.palette.grey[50] : theme.palette.primary.main,
  })
);
