import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "@reduxjs/toolkit/dist/query/core/apiState";
import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";

import {
  LibraryGrowthworkParts,
  MemberGrowthWork,
  MemberGrowthWorkStatus,
} from "src/api/types";
import { LibraryState } from "src/store/libraryReducer";

interface Args {
  member: string;
  growthworkId: string;
  status?: MemberGrowthWorkStatus;
}

interface Api {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  getState: () => RootState<any, any, "main">;
  queryFulfilled: Promise<{ data: MemberGrowthWork }>;
}

export const updateLibraryGrowthworksQuery =
  (api: any) =>
  async (
    { member, status }: Args,
    { dispatch, queryFulfilled, getState }: Api
  ): Promise<void> => {
    try {
      if (!isVideoTask(status)) return;

      const { data } = await queryFulfilled;
      const state = getState() as unknown as { library: LibraryState };
      const pathwayId = state?.library?.currentPathway?.id || "";

      if (!pathwayId) return;

      dispatch(
        api.util.updateQueryData(
          "getLibraryGrowthworks",
          { memberId: member, pathwayId },
          updateLibraryGrowthwork(data)
        )
      );
    } catch (err) {}
  };

const isVideoTask = (status?: MemberGrowthWorkStatus) =>
  status === MemberGrowthWorkStatus.REVIEWED;

export const updateLibraryGrowthwork =
  (data: MemberGrowthWork) =>
  (draft: MaybeDrafted<LibraryGrowthworkParts[]>) => {
    let targetPartIndex = -1;
    let targetGrowthworkIndex = -1;

    for (let i = 0; i < draft.length; i++) {
      const currentPart = draft[i];
      const foundIndex = currentPart.growthworks.findIndex(
        (gw) => gw.relation_cms_id.toString() === data.gw_cms_id
      );

      if (foundIndex !== -1) {
        targetPartIndex = i;
        targetGrowthworkIndex = foundIndex;
        break;
      }
    }

    if (targetPartIndex !== -1 && targetGrowthworkIndex !== -1) {
      draft[targetPartIndex].growthworks[targetGrowthworkIndex].is_new = false;
    }
  };
