import React from "react";
import Skeleton from "@mui/material/Skeleton";

const Loader = () => {
  return (
    <Skeleton
      variant="rounded"
      width="100%"
      height="124px"
      sx={{ borderRadius: "32px" }}
    />
  );
};

export default Loader;
