import { datadogLogs } from "@datadog/browser-logs";
import { useEffect } from "react";

const useDataDogLogs = () => {
  useEffect(() => {
    if(window.location.origin.includes("localhost")) return;
  
    if(process.env.REACT_APP_DATADOG_CLIENT_TOKEN && process.env.REACT_APP_DATADOG_SITE){
      datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
        site: process.env.REACT_APP_DATADOG_SITE as string,
        env: process.env.DATADOG_RUM_ENV,
        forwardErrorsToLogs: true,
        sampleRate: 100,
        useCrossSiteSessionCookie: true,
        silentMultipleInit: true,
        beforeSend: (log) => {
          if (log.message.includes("XHR")) {
            return false;
          }
        },
      });
      datadogLogs.setGlobalContext({
        host: window.location.origin,
        service: "browser",
      });
    }
  }, []);
};

export default useDataDogLogs;