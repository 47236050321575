import { styled } from "@mui/material";
import theme from "src/theme";
import { isMobile } from "src/utils/isMobile";

export const Wrapper = styled("div")<{ large: boolean }>(({ large }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  padding: "20px",

  "> div": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },

  button: {
    zIndex: 3,
  },

  ".action_button": {
    marginTop: "40px",
  },

  ".illustration": {
    maxWidth: "280px",
  },

  ...(large
    ? {
        h2: {
          fontSize: "22px",
        },
        h3: {
          color: theme.palette.grey[600],
          lineHeight: "24px",
        },
      }
    : {}),
}));

export const Title = styled("div")({
  maxWidth: !isMobile() ? "680px" : "100%",
  h1: {
    marginTop: "32px",
    marginBottom: "24px",
    textAlign: "center",
    span: {
      color: theme.palette.primary.main,
    },
  },
  h2: {
    marginTop: "32px",
    marginBottom: "24px",
    textAlign: "center",
    span: {
      color: theme.palette.primary.main,
    },
  },

  h3: {
    textAlign: "center",
    whiteSpace: "break-spaces",

    span: {
      fontWeight: 600,
      color: "#131414",
    },
  },

  margin: "auto 0",

  "h2,h3,svg,img": {
    zIndex: 3,
  },

  img: {
    maxWidth: "180px",
    display: "block",
  },
});
