import { useNavigate } from "react-router-dom";
import { LayoutWithBackButton } from "src/components";
import useLocationState from "src/hooks/useLocationState";
import { ROUTES } from "src/types/routes";
import { Player } from "./components";
import { GrowthworkProps } from "./components/Player";
import Loading from "src/components/Loading";
import {
  useGetPartCompletedTasks,
  useGetPartGrowthworksResolver,
} from "src/api/resolvers";
import { GWPlatforms } from "src/api/types";

const VideoTask = () => {
  const growthwork = useLocationState<GrowthworkProps>("growthwork");
  const referer = useLocationState<string>("referer");
  const allGrowthWorks = useGetPartGrowthworksResolver();
  const wistiaGrowthworks = allGrowthWorks.filter(
    ({ platform, relation_cms_id }) =>
      platform === GWPlatforms.WISTIA &&
      relation_cms_id !== growthwork?.relation_cms_id
  );
  const { data: completedTasks } = useGetPartCompletedTasks();

  const navigate = useNavigate();

  if (!growthwork) {
    return <Loading />;
  }

  const onComplete = () => {
    if (referer === ROUTES.JOURNEY) {
      return navigate(referer);
    }

    const isAudio = growthwork?.baselink.includes("async_audio");
    const isLastVideo = !wistiaGrowthworks.length
      ? growthwork.platform === GWPlatforms.WISTIA
      : wistiaGrowthworks.every((gw) => completedTasks?.[gw.relation_cms_id]);
    const props = !isAudio
      ? {
          state: { growthwork },
        }
      : undefined;

    navigate(
      isAudio || !isLastVideo ? ROUTES.HOME : ROUTES.TASK_COMPLETED,
      props
    );
  };

  return (
    <LayoutWithBackButton onBack={() => referer && navigate(referer)}>
      <Player onComplete={onComplete} />
    </LayoutWithBackButton>
  );
};

export default VideoTask;
