import * as React from "react";
import { SVGProps } from "react";
export const AudioLabel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#575961"
      stroke="#575961"
      d="M2.167 10A7.836 7.836 0 0 1 10 2.165a7.836 7.836 0 0 1 7.834 7.833A7.836 7.836 0 0 1 10 17.833a7.836 7.836 0 0 1-7.833-7.834ZM5 12.907a1.13 1.13 0 0 0 1.125-1.125V8.216A1.13 1.13 0 0 0 5 7.091a1.13 1.13 0 0 0-1.125 1.125v3.567A1.13 1.13 0 0 0 5 12.908Zm2.5 1.191a1.13 1.13 0 0 0 1.125-1.125v-5.95A1.13 1.13 0 0 0 7.5 5.9a1.13 1.13 0 0 0-1.125 1.125v5.95A1.13 1.13 0 0 0 7.5 14.1Zm2.5 1.192a1.13 1.13 0 0 0 1.125-1.125V5.833A1.13 1.13 0 0 0 10 4.708a1.13 1.13 0 0 0-1.125 1.125v8.333A1.13 1.13 0 0 0 10 15.291Zm2.5-1.192a1.13 1.13 0 0 0 1.125-1.125v-5.95A1.13 1.13 0 0 0 12.5 5.9a1.13 1.13 0 0 0-1.125 1.125v5.95A1.13 1.13 0 0 0 12.5 14.1Zm2.5-1.191a1.13 1.13 0 0 0 1.125-1.125V8.216A1.13 1.13 0 0 0 15 7.091a1.13 1.13 0 0 0-1.125 1.125v3.567A1.13 1.13 0 0 0 15 12.908Z"
    />
  </svg>
);
