import { styled, Typography } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")({
  display: "flex",
  marginTop: "16px",
});

export const StatusWrapper = styled("div")({
  backgroundColor: theme.palette.grey[50],
  padding: "4px 8px",
  borderRadius: "6px",
  textAlign: "center",
});

export const Caption = styled(Typography)({
  color: theme.palette.grey[600],
  fontSize: "12px",
});

export const CaptionWrapper = styled(Caption)({
  marginTop: "3px",
  marginLeft: "16px",
});
