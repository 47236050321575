import { useEffect, useRef, useMemo } from "react";
import { useSheet, BottomPopup } from "../BottomPopup";
import { Button } from "../Button";
import { AddSquare } from "src/assets/icons";
import Instruction from "./Instruction";

const AddToHomeShortcut = () => {
  const addShortcut = useRef<any>();
  const isIphone = useMemo(() => /iPhone/i.test(navigator.userAgent), []);
  const isStandaloneMode = useMemo(
    () =>
      (navigator as any)?.standalone ||
      window.matchMedia("(display-mode: standalone)").matches,
    []
  );
  const sheet = useSheet();

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      addShortcut.current = e;
    });
  }, []);

  const handleClick = async () => {
    if (isIphone) sheet.open();
    else addShortcut.current?.prompt();
  };

  return (
    <>
      {(isIphone || addShortcut.current) && !isStandaloneMode && (
        <Button
          id="add-to-homescreen"
          onClick={handleClick}
          color="secondary"
          sx={{ gap: "8px", mt: "24px" }}
        >
          <AddSquare />
          Add app to home screen
        </Button>
      )}
      <BottomPopup {...sheet}>
        <Instruction onClose={() => sheet.close()} />
      </BottomPopup>
    </>
  );
};

export default AddToHomeShortcut;
