import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  padding: "20px",
});

export const WeeksLineWrapper = styled("div")({
  display: "flex",
  gap: "6px",
  maxWidth: "100vw",
  overflow: "auto",
  marginBottom: "30px",
});

export const WeeksLineItem = styled("div")({
  fontSize: "12px",
  lineHeight: "16px",
  borderRadius: "6px",
  padding: "2px 16px",
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
});

export const ModuleBannerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "5px",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "32px",
  background: theme.palette.grey[800],
  padding: "10px 16px",

  img: {
    maxWidth: "134px",
  },
}));

export const GrowthworksWrapper = styled("div")(({ theme }) => ({
  background: theme.palette.grey[900],
  padding: "24px 24px 24px 14px",
}));

export const NotificationsWrapper = styled("div")({
  padding: "0 24px",
});

export const TasksWrapper = styled("div")({
  display: "flex",
  marginTop: "16px",
})
