import { styled } from "@mui/material";

export const Layout = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  flexDirection: "column",
});
