import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

interface StepProps {
  isActive?: boolean;
}

export const Step = styled("div")<StepProps>(({ theme, isActive }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: "12px",
  height: "4px",
  opacity: isActive ? 1 : 0.2,
  width: isActive ? "16px" : "10px",
}));
