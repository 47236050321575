import React, { PropsWithChildren } from "react";
import { Card, Content, ContentText, SkeletonWrapper } from "./styles";
import { Skeleton } from "@mui/material";
import Typography from "../Typography";

interface ProfileItemCardProps {
  name: string;
  title: string;
  image?: string;
  ImageComponent?: JSX.Element;
  Icon?: (props: any) => JSX.Element;
  onClick?: () => void;
  titleColor?: string;
}

const ProfileItemCard = ({
  name,
  title,
  image,
  ImageComponent,
  onClick,
  Icon,
  titleColor,
}: ProfileItemCardProps) => {
  if (!name || !title) {
    return (
      <SkeletonWrapper>
        <Skeleton
          variant="rounded"
          width="100%"
          height={90}
          sx={{ borderRadius: "24px" }}
        />
      </SkeletonWrapper>
    );
  }

  return (
    <Wrapper onClick={onClick}>
      <Card>
        <Content>
          {ImageComponent}
          {image && (
            <img
              src={image}
              alt="item-icon"
              style={{ width: "24px", height: "24px" }}
            />
          )}
          <ContentText>
            <Typography variant="headline5">{name}</Typography>
            <Typography variant="body-medium" color={titleColor}>
              {title}
            </Typography>
          </ContentText>
        </Content>
        {Icon && <Icon />}
      </Card>
    </Wrapper>
  );
};

interface WrapperProps extends PropsWithChildren {
  onClick?: () => void;
}

const Wrapper = ({ onClick, children }: WrapperProps) => {
  if (onClick) {
    return (
      <div id="profile-item" onClick={onClick}>
        {children}
      </div>
    );
  } else {
    return <div>{children}</div>;
  }
};

export default ProfileItemCard;
