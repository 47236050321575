import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  padding: "20px",

  h3: {
    marginTop: "15px",
    marginBottom: "5px",
  },
});
