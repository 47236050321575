import React from "react";
import { Typography } from "src/components";
import { NavLink, useLocation } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import { LoginTypes } from "src/types/login";
import theme from "src/theme";

const LinkToEmailRequest = () => {
  const { state } = useLocation() as { state: { email?: string } };

  return (
    <>
      <Typography weight={600}>QR code doesn’t work?</Typography>
      <NavLink
        to={ROUTES.DESKTOP_VIEW}
        state={{ type: LoginTypes.ACCESSLINK, email: state?.email || "" }}
      >
        <Typography color={theme.palette.primary.main} weight={600}>
          Request a link to your email
        </Typography>
      </NavLink>
    </>
  );
};

export default LinkToEmailRequest;
