import { styled } from "@mui/material";

export const Card = styled("div")({
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)",
  borderRadius: "24px",
  padding: "14px 18px",
});

export const Wrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

export const Text = styled("div")({
  h3: {
    color: "white",
    lineHeight: "24px",
  },

  p: { color: "white", opacity: "0.8" },
});
