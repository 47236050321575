import { styled } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")({
  zIndex: 100000000,
  width: "100%",
  height: "72px",
  position: "absolute",
  top: 0,
  background: theme.palette.primary.main,
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px",
});
