import React, { useEffect, useState } from "react";
import { useGetMemberQuery, useUpdateMemberPartMutation } from "src/api/main";
import {
  useCompletePart,
  useGetAssignedPartQueryResolver,
} from "src/api/resolvers";
import { CurrentPartStatuses } from "src/api/types";
import illustration from "src/assets/images/Illustration_new_part.webp";
import { NotificationPopupLayout } from "src/components";

const NewPartAssigned = () => {
  const [opened, setOpened] = useState(false);
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);

  const completePart = useCompletePart();
  const [updatePart] = useUpdateMemberPartMutation();

  const { data: assignedPart, isFetching } = useGetAssignedPartQueryResolver();
  const { data: member } = useGetMemberQuery();

  useEffect(() => {
    if (assignedPart?.status === CurrentPartStatuses.ASSIGNED && !isFetching) {
      setOpened(true);
    }
  }, [assignedPart, isFetching]);

  if (!opened) return <></>;

  const closeModal = () => {
    updatePart({
      partId: assignedPart?.uuid || "",
      memberId: member?.uuid || "",
      status: CurrentPartStatuses.ON_HOLD,
    });
    setOpened(false);
  };

  const unlockNewPart = async () => {
    setIsRequestProcessing(true);
    await completePart();
    setIsRequestProcessing(false);
    setOpened(false);
  };

  return (
    <NotificationPopupLayout
      modal={{ open: opened, onClose: closeModal }}
      illustration={illustration}
      title={"New Part assigned"}
      text={`Good news! Your expert has opened up a new Part in your Pathway. Would you like to unlock it\u00A0now?`}
      primaryAction={{
        text: isRequestProcessing ? "Loading..." : "Unlock new Part",
        id: "unlock_new_part",
        onClick: unlockNewPart,
      }}
      secondaryAction={{
        text: "Stay in current Part",
        onClick: closeModal,
        id: "stay_in_current_part",
      }}
    />
  );
};

export default NewPartAssigned;
