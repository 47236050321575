import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.69 2H8.31C4.67 2 2.5 4.17 2.5 7.81v8.37C2.5 19.83 4.67 22 8.31 22h8.37c3.64 0 5.81-2.17 5.81-5.81V7.81C22.5 4.17 20.33 2 16.69 2Zm-.19 10.75h-3.25V16c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-3.25H8.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h3.25V8c0-.41.34-.75.75-.75s.75.34.75.75v3.25h3.25c.41 0 .75.34.75.75s-.34.75-.75.75Z"
      fill="#37AC84"
    />
  </svg>
);

export default SvgComponent;
