import { useEffect, useRef, useState } from "react";
import AudioPlayer from "../AudioPlayer";

interface ControlledAudioPlayerProps {
  src: string;
  title: string;
}

const CURRENT_TIME_BIG_NUMBER = 1e101;

const ControlledAudioPlayer = ({ src, title }: ControlledAudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!audioRef.current) return;

    audioRef.current.ontimeupdate = () => {
      if (!audioRef.current) return;

      if (audioRef.current.duration !== Infinity && !duration) {
        audioRef.current.currentTime = 0;
        setDuration(audioRef.current.duration);
      }
      setTime(audioRef.current.currentTime || 0);
    };

    audioRef.current.addEventListener("loadedmetadata", () => {
      if (!audioRef.current) return;

      // for some reason duration is Infinity before audio start
      // workaround is to set big number to current time
      // https://stackoverflow.com/questions/21522036/html-audio-tag-duration-always-infinity

      const audioDuration = audioRef.current?.duration;
      if (audioDuration === Infinity) {
        audioRef.current.currentTime = CURRENT_TIME_BIG_NUMBER;
      } else {
        setDuration(audioDuration || 0);
      }
    });

    audioRef.current.onended = () => {
      setIsPlaying(false);
    };

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (audioRef.current) audioRef.current.ontimeupdate = null;
    };
  }, [duration]);

  const handlePlay = () => {
    if (isPlaying) {
      audioRef?.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef?.current?.play();
      setIsPlaying(true);
    }
  };

  const updateTime = (time: number) => {
    if (audioRef.current) audioRef.current.currentTime = time;
    setTime(time);
  };

  return (
    <>
      <audio src={src} ref={audioRef} />
      <AudioPlayer
        title={title}
        isPlaying={isPlaying}
        duration={duration || 0}
        currentTime={time}
        onPlay={handlePlay}
        setTime={updateTime}
      />
    </>
  );
};

export default ControlledAudioPlayer;
