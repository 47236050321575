import { useMemo } from "react";
import { Typography, Box } from "@mui/material";
import { GWPlatforms } from "src/api/types";
import { useGetWistiaDetailsQuery } from "src/api/wistia";
import {
  Wrapper,
  Title,
  ContentWrapper,
} from "src/components/GrowthworkCard/styles";
import GrowthworkLabel from "../GrowthworkLabel";
import audioThumbnail from "src/assets/images/audio_thumbnail.png";

interface VideoTaskCardProps {
  url: string;
  title: string;
  time: string;
  onClick: any;
  id: string;
  disabled?: boolean;
  image?: string;
}

const VideoTaskCard = ({
  url,
  title,
  time,
  id,
  onClick,
  disabled,
  image,
}: VideoTaskCardProps) => {
  const { data } = useGetWistiaDetailsQuery(url);

  const isAudio = url.includes("async_audio");

  const duration = useMemo(() => {
    if (!data) return 0;
    return time || data?.duration;
  }, [time, data]);

  return (
    <>
      {data && (
        <Wrapper
          sx={{ padding: "10px", pb: "20px", position: "relative" }}
          onClick={() => !disabled && onClick()}
          id={id}
        >
          <ContentWrapper>
            <Box sx={{ position: "absolute", left: "20px", top: "20px" }}>
              <GrowthworkLabel
                type={isAudio ? GWPlatforms.AUDIO : GWPlatforms.WISTIA}
                disabled={disabled}
              />
            </Box>
            <img
              id={`card_image-${isAudio ? "audio" : "video"}`}
              style={{
                width: "100%",
                borderRadius: "32px",
              }}
              src={image || (isAudio ? audioThumbnail : data?.thumbnail_url)}
              alt="preview"
            />
            <Title sx={{ width: "100%", mt: "12px" }}>
              <Typography variant="h4">{title}</Typography>
              {duration && <Typography>{duration} min</Typography>}
            </Title>
          </ContentWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default VideoTaskCard;
