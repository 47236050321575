import { FirstMeetingTypes, Session, SessionStatus } from "src/api/types";
import { useEffect } from "react";
import { mainApi, useGetMemberQuery } from "src/api/main";
import { useAbly } from "src/contexts/AblyContext";
import { useAppDispatch } from "src/store/hooks";
import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { useGetExpertQueryResolver } from "src/api/resolvers";

const SESSION_UPDATE_EVENT = "session_update";
const CALENDLY_UPDATE_EVENT = "calendly-event-sent";

enum EventTypes {
  SCHEDULED = "scheduled",
  CANCELED = "canceled",
}

const useSubscribeToSessionUpdate = () => {
  const { data: member } = useGetMemberQuery();
  const appDispatch = useAppDispatch();
  const { subscribe, isReady } = useAbly();
  const { refetch: refetchExpert } = useGetExpertQueryResolver();

  useEffect(() => {
    if (member && isReady) {
      subscribeToCalendlyUpdate();
      subscribeToSessionUpdate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, isReady]);

  const updateSession = (
    updateCallback: (draft: MaybeDrafted<Session[]>) => void
  ) => {
    appDispatch(
      mainApi.util.updateQueryData(
        "getScheduledSession",
        member?.uuid || "",
        updateCallback
      )
    );
  };

  const subscribeToCalendlyUpdate = () => {
    subscribe({
      name: CALENDLY_UPDATE_EVENT,
      callback: updateSessionFromCalendly,
      field: "email",
    });
  };

  const updateSessionFromCalendly = (data: Session & { event: EventTypes }) => {
    if (
      data.event === EventTypes.SCHEDULED &&
      FirstMeetingTypes.includes(data.type)
    ) {
      refetchExpert();
    }

    updateSession((draft) => {
      switch (data.event) {
        case EventTypes.SCHEDULED:
          draft.push({ ...data, created: new Date().toISOString() });
          draft.sort(
            (a, b) =>
              new Date(a.next_session).getTime() -
              new Date(b.next_session).getTime()
          );
          break;
        case EventTypes.CANCELED:
          return draft.filter(
            ({ next_session_event_id }) =>
              next_session_event_id !== data.next_session_event_id
          );
        default:
          return draft;
      }
    });
  };

  const subscribeToSessionUpdate = () => {
    subscribe({
      name: SESSION_UPDATE_EVENT,
      callback: updateScheduledSession,
      field: "email",
    });
  };

  const updateScheduledSession = ({
    sessionId,
    status,
  }: {
    sessionId: string;
    status: Session["status"];
  }) => {
    if (status === SessionStatus.ENDED) {
      setWelcomeSessionCompleted();
    }
    updateSession((draft) => draft.filter(({ uuid }) => sessionId !== uuid));
  };

  const setWelcomeSessionCompleted = () => {
    appDispatch(
      mainApi.util.updateQueryData(
        "getCompletedWelcomeSession",
        member?.uuid || "",
        () => true
      )
    );
  };
};

export default useSubscribeToSessionUpdate;
