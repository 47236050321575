import { styled } from "@mui/material";

export const Label = styled("div")<{
  disabled?: boolean;
}>(({ disabled, theme }) => ({
  background: disabled ? theme.palette.grey[500] : theme.palette.grey[600],
  borderRadius: "6px",
  padding: "4px 8px",
  color: "white",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "19px",
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  gap: "4px",
}));

export const LabelWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "4px",

  img: {
    maxWidth: "27px",
  },
});
