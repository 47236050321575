import { Session, SessionStatus } from "src/api/types";

export const transformSession = (session: Session) => {
  const status = session.status;

  // from webapp perspective Ongoing session is same as scheduled
  return {
    ...session,
    status: status === SessionStatus.ONGOING ? SessionStatus.SCHEDULED : status,
  };
};

export const transformSessionDetails = (session: Session) => {
  const transformedSession = transformSession(session);
  const { next_session, status } = transformedSession;

  const currentTime = new Date().getTime();
  const sessionTime = new Date(next_session).getTime();

  if (status === SessionStatus.SCHEDULED && currentTime < sessionTime) {
    return { ...transformedSession, status: SessionStatus.NOT_STARTED };
  } else {
    return transformedSession;
  }
};
