import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store/types";

export function useAppDispatch() {
  return useDispatch<AppDispatch>();
}

export function useAppSelector<TSelected = unknown>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
  return useSelector<RootState, TSelected>(selector, equalityFn);
}
