import { styled } from "@mui/material";
import theme, { DESKTOP_MOCKUP_HEIGHT, getResponsiveValue } from "src/theme";

export const StepItem = styled("div")({
  width: "28px",
  height: "28px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const Divider = styled("div")<{ completed: boolean }>(
  ({ completed }) => ({
    flex: "1 1 auto",
    height: "1px",
    margin: "0 8px",
    backgroundColor: completed
      ? theme.palette.primary.main
      : theme.palette.grey[500],
  })
);

export const Wrapper = styled("div")({
  marginTop: "16px",
  marginBottom: getResponsiveValue(95, DESKTOP_MOCKUP_HEIGHT),

  [theme.breakpoints.down("sm")]: {
    marginTop: 0,
    marginBottom: "26px",
  },
});

export const StepDescription = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "16px",

  p: {
    fontWeight: 600,
  },
});

export const ItemWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
});
