import React from "react";
import illustration from "src/assets/images/welcome-back-popup.svg";
import { NotificationPopupLayout } from "src/components";
import { useReactivation } from "src/contexts/ReactivationContext";
import useBookSessionAction from "src/pages/Home/components/SessionSection/utils/useBookSessionAction";

export const WelcomeBack = () => {
  const { isWelcomeBackVisible, manageModalWelcomeBack } = useReactivation();
  const action = useBookSessionAction();
  const handlePrimaryActionClick = () => {
    manageModalWelcomeBack();
    action();
  };
  return (
    <NotificationPopupLayout
      modal={{ open: isWelcomeBackVisible, onClose: manageModalWelcomeBack }}
      illustration={illustration}
      title={"Welcome back!"}
      text={`Your membership has been successfully renewed. Feel free to schedule a session with your relationship expert.`}
      primaryAction={{
        text: "Schedule a session",
        id: "popup_welcomeback_confirmed",
        onClick: handlePrimaryActionClick,
      }}
      secondaryAction={{
        text: "Cancel",
        onClick: manageModalWelcomeBack,
        id: "popup_welcomeback_canceled",
      }}
    />
  );
};
