import React from "react";
import NewPartAssigned from "./NewPartAssigned";
import { ProfileComplete } from "src/pages/Home/components/Notifications/PopUpNotifications/ProfileComplete";
import { ReactivateMembership } from "src/pages/Home/components/Notifications/PopUpNotifications/ReactivateMembership";

const PopUpNotifications = () => {
  return (
    <>
      <NewPartAssigned />
      <ProfileComplete />
      <ReactivateMembership />
    </>
  );
};

export default PopUpNotifications;
