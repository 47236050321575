import { Typography, styled } from "@mui/material";
import { Button } from "../Button";
import theme from "src/theme";

export const FooterWrapper = styled("div")({
  backgroundColor: "white",
  padding: "15px 20px",
  position: "relative",

  img: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    left: 0,
  },
});

export const FooterTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "21px",
  zIndex: 100,
});

export const FooterSubtitle = styled(Typography)({
  color: theme.palette.grey[100],
  fontWeight: 400,
  marginTop: "4px",
  zIndex: 100,
});

export const FooterButton = styled(Button)({
  padding: "4px 16px",
  borderRadius: "8px",
  alignSelf: "flex-start",
  width: "auto",
  marginTop: "16px",
  backgroundColor: "white",
  zIndex: 100,
  position: "relative",
});
