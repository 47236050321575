import {
    useGetCompletedMatchingSessionQueryResolver,
    useGetMemberAccountQueryResolver
} from 'src/api/resolvers';

const useIsMatchingExpertChanged = () => {
  const { data: matchingSessionDetails } =
    useGetCompletedMatchingSessionQueryResolver();
    const { data: memberAccountData } =
    useGetMemberAccountQueryResolver();
    const is_matching_upgrade_avalable = memberAccountData?.show_subscription_update_option && matchingSessionDetails?.is_matching && matchingSessionDetails?.is_matching_session_done;
    return is_matching_upgrade_avalable;
};

export default useIsMatchingExpertChanged;
