import { ROUTES } from "src/types/routes";

export enum FieldTypes {
  IN_APP = "IN_APP",
  EXTERNAL = "EXTERNAL",
}

export const mappedSections = (isMembershipPaused: boolean = false, isMatching: boolean = false) => {
  return [
    {
      name: "ACCOUNT",
      fields: [
        {
          name: "Subscription plan",
          url: ROUTES.SUBSCRIPTION,
          id: "subscription_plan",
          type: FieldTypes.IN_APP,
          caption: {
            text: "Paused",
            condition: isMembershipPaused,
          },
        },
        ...(isMatching ? [
          {
            name: "Upgrade",
            url: ROUTES.PLANS,
            id: "pricing_matchingsessionupgrade_profile_clicked",
            type: FieldTypes.IN_APP,
            caption: {
              matching: isMatching,
            },
          }
        ] : []),
      ],
    },
    {
      name: "HELP",
      fields: [
        {
          name: "Support",
          url: ROUTES.CONTACT_US,
          id: "support",
          type: FieldTypes.IN_APP,
        },
        {
          name: "FAQ",
          url: `${process.env.REACT_APP_MAIN_SITE}/faq`,
          id: "faq",
          type: FieldTypes.EXTERNAL,
        },
      ],
    },
    {
      name: "OTHER",
      fields: [
        {
          name: "Privacy Policy",
          url: `${process.env.REACT_APP_MAIN_SITE}/privacy-policy`,
          id: "privacy_policy",
          type: FieldTypes.EXTERNAL,
        },
        {
          name: "Terms of Use",
          url: `${process.env.REACT_APP_MAIN_SITE}/terms-and-conditions`,
          id: "terms_of_use",
          type: FieldTypes.EXTERNAL,
        },
      ],
    },
  ];
};
