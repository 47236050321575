import React from "react";
import illustration from "src/assets/images/matching_subscribe.svg";
import { Button } from "src/components";
import {
  Description,
  Illustration,
  Title,
  Wrapper,
} from "src/pages/Home/components/MatchingSubscribeBanner/styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";

export const PartCompletedMatchingBanner = () => {
  const navigate = useNavigate();

  const navigatePlans = () => {
    navigate(ROUTES.PLANS);
  };

  return (
    <Wrapper>
      <Illustration src={illustration} alt="complete_profile_illustration" />
      <Title variant="headline4">Upgrade to unlock new Part</Title>
      <Description>
        To unlock a new Part in your Pathway and continue your journey with your expert, upgrade to one of our memberships.
      </Description>
      <Button id="pricing_matchingsessionupgrade_banned_clicker" onClick={navigatePlans}>
        Upgrade
      </Button>
    </Wrapper>
  );
};
