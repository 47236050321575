import { Growthwork } from "src/api/types";
import GrowthworkCard from "../GrowthworkCard";
import ProgressLine from "../ProgressLine";
import { TasksWrapper } from "./styles";

interface GrowthworksListProps {
  growthworks: Growthwork[];
  prefix?: string;
  completedCount: number;
  completedTasks?: { [key: string]: boolean };
  disabled?: (gw: Growthwork, i: number) => boolean;
  onClick: (gw: Growthwork) => void;
}

const GrowthworksList = ({
  growthworks,
  prefix = "",
  completedTasks,
  disabled = () => false,
  onClick,
}: GrowthworksListProps) => {
  const orderedCompletedTasks = growthworks.map(({ relation_cms_id }) => ({
    relation_cms_id,
    completed: completedTasks ? completedTasks[relation_cms_id] : false,
  }));

  return (
    <TasksWrapper>
      <ProgressLine prefix={prefix} completedTasks={orderedCompletedTasks} />
      <div>
        {growthworks.map((gw, i) => (
          <GrowthworkCard
            id={`${prefix}growthwork_card-${i}`}
            growthworkId={gw.relation_cms_id}
            title={gw.gw_short_title}
            time={gw.length_minutes}
            type={gw.platform}
            key={gw.gw_short_title}
            link={gw.baselink}
            image={gw.image}
            disabled={disabled(gw, i)}
            completed={
              completedTasks ? completedTasks[gw?.relation_cms_id] : false
            }
            onClick={() => onClick(gw)}
          />
        ))}
      </div>
    </TasksWrapper>
  );
};

export default GrowthworksList;
