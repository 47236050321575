import { styled } from "@mui/material";

export const POPUP_HEIGHT = 675;

export const Wrapper = styled("div")(({ theme }) => ({
  padding: "20px 48px",
  display: "flex",
  flexDirection: "column",
  height: POPUP_HEIGHT,

  h2: {
    textAlign: "center",
    whiteSpace: "break-spaces",
    marginBottom: "24px",
  },

  p: { color: theme.palette.grey[600] },
}));

export const Content = styled("div")({
  marginTop: "24px",
  width: "100%",
});

export const ReadButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  marginTop: "23px",

  h3: {
    color: theme.palette.grey[100],
    fontWeight: 600,
  },

  svg: {
    transition: "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));

export const TextWrapper = styled("div")(({ theme }) => ({
  marginTop: "24px",
  marginBottom: "110px",
  fontSize: "16px",
  color: theme.palette.grey[600],

  h2: {
    fontFamily: "Garnett",
    fontWeight: 500,
    color: "black",
    fontSize: "22px",
    lineHeight: "30px",
    margin: "24px 0",
    letterSpacing: "0.01em",
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  background: theme.palette.grey.A400,
  width: "100%",
  display: "flex",
  justifyContent: "center",

  button: {
    marginBottom: "24px",
    width: "calc(100% - 96px)",
  },
}));
