import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  padding: "50px",

  img: {
    maxWidth: "100%",
  },

  h3: {
    marginTop: "50px",
    marginBottom: "75px",
    textAlign: "center",
  },

  " > div": {
    maxHeight: "50px",
    width: "350px",
    height: "fit-content",
  },
});
