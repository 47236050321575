import { styled } from "@mui/material";
import theme from "src/theme";

export const HEIGHT = 355;

export const Wrapper = styled("div")({
  padding: "20px 25px",
  paddingTop: 0,
  height: `${HEIGHT}px`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  button: {
    width: "calc(100% - 48px)",
  },

  "& > h2,p": {
    textAlign: "center",
  },

  h2: {
    marginBottom: "24px",
  },

  p: {
    color: "#9EA0A8",
    maxWidth: "289px",
    margin: "0 auto",
    whiteSpace: "break-spaces",
  },
});

export const Steps = styled("div")({
  marginTop: "24px",
  marginBottom: "32px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gap: "6px",
});

export const Step = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: theme.palette.grey[900],
  borderRadius: "12px",
  gap: "8px",
  padding: "8px 0",

  p: {
    textAlign: "center",
  },
});
