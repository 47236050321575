import { Expert, Member } from "src/api/types";
import seedrandom from "seedrandom";
import { avatarColors } from "src/theme";

export const transformMember = (member: Member) => {
  const transformedData = transformUser(member);
  return {
    ...transformedData,
    picture: getUserPicture(transformedData.full_name),
  };
};

const getUserPicture = (fullName: string) => {
  const rng = seedrandom(fullName);
  const randomInt = Math.abs(rng.int32());
  const randomIndex = randomInt % avatarColors.length;
  const { color, background } = avatarColors[randomIndex];
  return `https://ui-avatars.com/api/?background=${background}&color=${color}&name=${encodeURIComponent(
    fullName
  )}&bold=true&size=128`;
};

export const transformUser = (user: Member | Expert): any => ({
  ...user,
  full_name: getFullName(user),
});

const getFullName = ({
  first_name,
  last_name,
}: {
  first_name: string;
  last_name: string;
}) => `${first_name} ${last_name}`;
