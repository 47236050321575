import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import { useAppDispatch } from "src/store/hooks";
import { addModuleRelation } from "src/store/moduleRelationsReducer";
import { useGetModuleRelationState } from "src/store/selectors";
import {
  useGetCurrentPartQuery,
  useGetExpertCmsQuery,
  useGetModuleRelationQuery,
  useGetModulesQuery,
  useGetPartGrowthworksQuery,
  useGetSessionEventsQuery,
  useGetSubscriptionProfileDetailsQuery,
  useGetYoureOnYourWayQuery,
} from "./cms";
import {
  useGetMemberQuery,
  useGetMemberModulesQuery,
  useGetCompletedCountQuery,
  useGetScheduledSessionQuery,
  useUpdateModuleMutation,
  useGetExpertQuery,
  useGetAssignedPartQuery,
  useGetCompletedWelcomeSessionQuery,
  useGetCompletedMatchingSessionQuery,
  useGetMemberGrowthworksCmsIdQuery,
  useGetSessionLinksQuery,
  useGetOnboardingStatusQuery,
  useGetStreamTokenQuery,
  useGetFrontHashQuery,
  useGetSessionDetailsQuery,
  useGetLibraryGrowthworksQuery,
  useGetCompletedTasksQuery,
  useGetMemberSubscriptionStatusQuery,
  useGetMemberFeedbacksQuery,
  useGetCompletedSessionsCountQuery,
  useGetSurveyQuery,
  useGetMemberPlanDetailsQuery,
  useGetMemberPlanQuery,
  useGetMemberAccountDetailsQuery,
  useGetRecommendationsQuery,
} from "./main";
import {
  CurrentPartStatuses,
  GWPlatforms,
  PlanCategory,
  SessionType,
  TaskCompletedScreen,
} from "./types";
import { useParams } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import useLocationState from "src/hooks/useLocationState";

export const useGetMemberModulesQueryResolver = () => {
  const { data: member } = useGetMemberQuery();
  return useGetMemberModulesQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetActiveModuleQueryResolver = () => {
  const response = useGetMemberModulesQueryResolver();

  const activeModuleResponse = useMemo(
    () => ({
      ...response,
      data: response?.data?.find(({ selected }) => selected),
    }),
    [response]
  );

  return activeModuleResponse;
};

export const useGetModuleCms = () => {
  const { data: module, isFetching: isActiveFetching } =
    useGetActiveModuleQueryResolver();
  const { data: modules, isFetching: isModulesFetching } = useGetModulesQuery();

  const moduleCms = useMemo(
    () =>
      modules?.find(({ module_id }) => module_id === module?.program.cms_id),
    [modules, module]
  );

  return useMemo(
    () => ({
      data: moduleCms,
      isFetching: isActiveFetching || isModulesFetching,
    }),
    [moduleCms, isActiveFetching, isModulesFetching]
  );
};

export const useGetCurrentPart = () => {
  const { data: module } = useGetActiveModuleQueryResolver();

  return useGetCurrentPartQuery(
    {
      partOrder: String(module?.current_part),
      moduleId: module?.program?.cms_id || "",
    },
    { skip: !module }
  );
};

export const useGetPartGrowthworksResolver = () => {
  const { data: currentPart } = useGetCurrentPart();
  const { data: member } = useGetMemberQuery();
  const { data: module } = useGetActiveModuleQueryResolver();

  const { data: cmsData } = useGetPartGrowthworksQuery(
    {
      partId: String(currentPart?.part_id),
      expertId: member?.expert || "",
      moduleId: module?.program?.cms_id || "",
    },
    { skip: !currentPart?.part_id || !member || !module }
  );

  return cmsData || [];
};

export const useGetPartCompletedTaskCount = () => {
  const module_relation_cms = useModuleRelationCMS();
  const { data: member } = useGetMemberQuery();
  const params = module_relation_cms
    .map(({ id }: any) => `gw_id=${id}`)
    .join("&");

  const { data: completedCount } = useGetCompletedCountQuery(
    {
      memberId: member?.uuid || "",
      gwList: params,
    },
    { skip: !member?.uuid || !params }
  );

  return completedCount || 0;
};

export const useGetPartCompletedTasks = () => {
  const module_relation_cms = useModuleRelationCMS();
  const { data: member } = useGetMemberQuery();
  const params = module_relation_cms
    .map(({ id }: any) => `gw_id=${id}`)
    .join("&");

  return useGetCompletedTasksQuery(
    {
      memberId: member?.uuid || "",
      gwList: params,
    },
    { skip: !member?.uuid || !params }
  );
};

export const useModuleRelationCMS = () => {
  const partGrowthworks = useGetPartGrowthworksResolver();
  const module_relation_cms = useMemo(
    () =>
      partGrowthworks.map((item) => ({
        id: item.relation_cms_id,
        gw_link_platform: item.platform,
        gw_link_url: item.baselink,
      })),
    [partGrowthworks]
  );

  return module_relation_cms;
};

export const useGetScheduledSessionQueryResolver = () => {
  const { data: member } = useGetMemberQuery();

  const response = useGetScheduledSessionQuery(member?.uuid || "", {
    skip: !member,
  });

  const result = useMemo(() => {
    const data = response?.data;
    return { ...response, data: data?.[0] };
  }, [response]);

  return result;
};

export const useCompletePart = () => {
  const { data: member } = useGetMemberQuery();
  const { data: module } = useGetActiveModuleQueryResolver();
  const { data: moduleCms } = useGetModuleCms();
  const navigate = useNavigate();
  const [updateModule] = useUpdateModuleMutation();
  const { data: assignedPart } = useGetAssignedPartQueryResolver();

  const completePart = async () => {
    if (!member || !module) return;

    if (!assignedPart && module.current_part === moduleCms?.weeks_length)
      return navigate(ROUTES.MODULE_COMPLETED);

    const current_part = assignedPart?.num || module.current_part + 1;

    await updateModule({
      memberId: member.uuid,
      moduleId: module.program.cms_id,
      body: {
        current_part_status: CurrentPartStatuses.STARTED,
        current_part,
      },
    });
  };

  return completePart;
};

export const useGetExpertQueryResolver = () => {
  const { data: member } = useGetMemberQuery();
  return useGetExpertQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetYoureOnYourWayQueryResolver = () => {
  const planDetails = useGetMemberPlanResolver();
  return useGetYoureOnYourWayQuery(
    planDetails?.category || PlanCategory.INDIVIDUAL,
    {
      skip: !planDetails,
    }
  );
};

export const useGetAssignedPartQueryResolver = () => {
  const { data: member } = useGetMemberQuery();
  return useGetAssignedPartQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetCompletedWelcomeSessionQueryResolver = () => {
  const { data: member } = useGetMemberQuery();
  return useGetCompletedWelcomeSessionQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetCompletedMatchingSessionQueryResolver = () => {
  const { data: member } = useGetMemberQuery();
  return useGetCompletedMatchingSessionQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetModuleRelationQueryResolver = () => {
  const { data, count } = useGetModuleRelationState();
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const { data: moduleRelation } = useGetModuleRelationQuery(String(page), {
    skip: Boolean(count && data.length >= count),
  });

  useEffect(() => {
    if (!moduleRelation) return;
    dispatch(
      addModuleRelation({
        data: moduleRelation,
        count: moduleRelation[0]?.total || 0,
      })
    );
    setPage((prev) => prev + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleRelation]);

  return data;
};

export const useGetMemberGrowthworksCmsId = () => {
  const { data: member } = useGetMemberQuery();
  return useGetMemberGrowthworksCmsIdQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetExpertCmsDetails = () => {
  const { data: member } = useGetMemberQuery();
  return useGetExpertCmsQuery(member?.expert || "", { skip: !member });
};

export const useGetSessionLinksQueryResolver = () => {
  const { data: member } = useGetMemberQuery();
  return useGetSessionLinksQuery(member?.uuid || "", { skip: !member?.uuid });
};

export const useGetUpcomingSessions = () => {
  const { data: member } = useGetMemberQuery();

  return useGetScheduledSessionQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetSessionEventDetails = (type?: number) => {
  const { data: sessions } = useGetSessionEventsQuery();

  if (!type) {
    return null;
  }

  const defaultSession = sessions?.find(
    (session) => session.type === SessionType.CHAT
  );
  return sessions?.find((session) => session.type === type) || defaultSession;
};

export const useGetAllowedSessionTypes = () => {
  const { data: member } = useGetMemberQuery();
  const { data: sessions } = useGetSessionEventsQuery();
  const { data: sessionLinks } = useGetSessionLinksQueryResolver();
  const planDetails = useGetMemberPlanResolver();
  const allowedSessionEvents = useMemo(() => {
    return sessions?.filter(({ allowed_plan_category, type }) => {
      const isExpertHasLink = sessionLinks?.some(
        (sessionLink) => sessionLink.type === type
      );

      const isAllowedCategory =
        !allowed_plan_category?.length ||
        allowed_plan_category.includes(String(planDetails?.category));

      return member && isAllowedCategory && isExpertHasLink;
    });
  }, [sessions, member, sessionLinks]);

  return allowedSessionEvents;
};

export const useGetScheduledSessionsTypes = () => {
  const { data: upcomingSessions } = useGetUpcomingSessions();
  const { data: sessionTypes } = useGetSessionEventsQuery();

  const scheduleSessionsTypes = useMemo(
    () =>
      sessionTypes?.filter(({ type }) =>
        upcomingSessions?.some((session) => type === session.type)
      ),
    [sessionTypes, upcomingSessions]
  );

  return scheduleSessionsTypes;
};

export const useGetOnboardingStatusQueryResolver = () => {
  const { data: member } = useGetMemberQuery();

  return useGetOnboardingStatusQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetStreamTokenQueryResolver = () => {
  const { data: member } = useGetMemberQuery();
  return useGetStreamTokenQuery(member?.uuid || "", { skip: !member });
};

export const useGetFrontHashQueryResolver = () => {
  const { data: member } = useGetMemberQuery();
  return useGetFrontHashQuery(member?.email || "", { skip: !member });
};

export const useGetSessionDetailsQueryResolver = () => {
  const { session_id } = useParams();
  return useGetSessionDetailsQuery(session_id || "", {
    skip: !session_id,
  });
};

export const useGetMemberPlanResolver = () => {
  const { data: member } = useGetMemberQuery();

  return useGetMemberPlanQuery(member?.uuid || "", { skip: !member }).data;
};

export const useGetSubscriptionResolver = () => {
  const { data: member } = useGetMemberQuery();


  const memberPlanDetailsResponse = useGetMemberPlanDetailsQuery(
    member?.uuid || "",
    { skip: !member }
  );

  return memberPlanDetailsResponse;
};

export const useSubscriptionCmsDetails = () => {

  const subscriptionProfileResponse =
    useGetSubscriptionProfileDetailsQueryResolver();

  return subscriptionProfileResponse;
};

export const useGetSubscriptionProfileDetailsQueryResolver = () => {
  const { data: plan } = useGetSubscriptionResolver();

  return useGetSubscriptionProfileDetailsQuery(plan?.cms_id || "", {
    skip: !plan?.cms_id,
  });
};

export const useGetLibraryGrowthworksQueryResolver = (pathwayId: string) => {
  const { data: member } = useGetMemberQuery();

  return useGetLibraryGrowthworksQuery(
    { memberId: member?.uuid || "", pathwayId },
    {
      skip: !member || !pathwayId,
    }
  );
};

export const useGetMemberSubscriptionStatusQueryResolver = () => {
  const { data: member } = useGetMemberQuery();

  return useGetMemberSubscriptionStatusQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetSurveyQueryResolver = (surveyId: string) => {
  const { data: member } = useGetMemberQuery();

  return useGetSurveyQuery(
    { uuid: member?.uuid || "", surveyId: surveyId },
    {
      skip: !member,
    }
  );
};

export const useGetMemberFeedbacksQueryResolver = () => {
  const { data: member } = useGetMemberQuery();

  return useGetMemberFeedbacksQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetMemberAccountQueryResolver = () => {
  const { data: member } = useGetMemberQuery();

  return useGetMemberAccountDetailsQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetCompletedSessionsCountQueryResolver = () => {
  const { data: member } = useGetMemberQuery();

  return useGetCompletedSessionsCountQuery(member?.uuid || "", {
    skip: !member,
  });
};

export const useGetRecommendationsQueryResolver = (props?: any) => {
  const { data: member } = useGetMemberQuery();
  const isDefaultRecommendation = useLocationState<boolean>(
    "isDefaultRecommendation",
    true
  );

  return useGetRecommendationsQuery(
    { memberId: member?.uuid || "", isDefault: isDefaultRecommendation },
    { skip: !member, ...props }
  );
};
