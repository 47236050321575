import { styled } from "@mui/material";
import Typography from "src/components/Typography";

export const Wrapper = styled("div")({
  marginTop: "135px",
  flex: 1,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
});

export const Illustration = styled("img")({
  width: "375px",
  height: "210px",
});

export const Text = styled(Typography)({
  marginTop: "24px",
});
