export enum LoginTypes {
  QRCODE = "QRCode",
  QRCODE_EMAIL = "QRCodeEmail",
  ACCESSLINK = "AccessLink",
}

export enum NoAccountFoundTypes {
  DEFAULT,
  PHONE_NUMBER,
  EMAIL,
}
