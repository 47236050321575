import { Growthwork } from "src/api/types";
import GrowthworksList from "src/components/GrowthworksList";
import { Typography } from "@mui/material";
import {
  useGetPartCompletedTaskCount,
  useGetPartCompletedTasks,
  useGetPartGrowthworksResolver,
  useModuleRelationCMS,
} from "src/api/resolvers";
import { GrowthworksWrapper } from "../../styles";
import {
  useCreateMemberGrowthworkMutation,
  useGetMemberQuery,
} from "src/api/main";
import useRedirectToTask from "src/hooks/useRedirectToTask";
import { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import MovingForward from "src/components/MovingForwardPopup";

const Growthworks = () => {
  const growthworks = useGetPartGrowthworksResolver();
  const [createMemberGrowthwork, { isLoading }] =
    useCreateMemberGrowthworkMutation();
  const module_relation_cms = useModuleRelationCMS();
  const { data: member } = useGetMemberQuery();
  const completedCount = useGetPartCompletedTaskCount();
  const { data: completedTasks } = useGetPartCompletedTasks();
  const redirectToTask = useRedirectToTask();
  const [isMovingForwardPopupOpen, setIsMovingForwardPopupOpen] =
    useState(false);
  const [selectedGrowthwork, setSelectedGrowthwork] = useState<Growthwork>();
  const [recommendedGrowthwork, setRecommendedGrowthwork] =
    useState<Growthwork>();
  const orderedCompletedTasks = growthworks.map(
    ({ relation_cms_id }) => completedTasks?.[relation_cms_id]
  );
  const isTasksAreDoneOutOfOrder = orderedCompletedTasks.some(
    (value, index, array) => value && array.slice(0, index).includes(false)
  );

  const closeMovingForwardPopupOpen = () => {
    setIsMovingForwardPopupOpen(false);
  };

  useEffect(() => {
    if (!completedTasks || !growthworks) return;

    const recommendedGW = growthworks.find(
      (growthwork) => !completedTasks[growthwork.relation_cms_id]
    );

    setRecommendedGrowthwork(recommendedGW);
  }, [growthworks, completedTasks]);

  const handleClick = (growthwork: Growthwork) => {
    if (!member || isLoading) return;

    setSelectedGrowthwork(growthwork);

    if (
      isTasksAreDoneOutOfOrder &&
      recommendedGrowthwork &&
      recommendedGrowthwork.relation_cms_id !== growthwork?.relation_cms_id
    ) {
      setIsMovingForwardPopupOpen(true);
    } else {
      //TODO: remove after release
      createMemberGrowthwork({
        expertId: member.expert,
        memberId: member.uuid,
        module_relation_cms,
        cms_id: String(growthwork?.relation_cms_id),
      })
        .unwrap()
        .then((res) => res && redirectToTask({ ...growthwork, ...res }));
    }
  };

  const createGrowthwork = (id?: string) => {
    const growthworkId = "go_the_recommended_growthwork";
    if (!member || isLoading || !selectedGrowthwork) return;

    const growthwork =
      id === growthworkId &&
      recommendedGrowthwork
        ? recommendedGrowthwork
        : selectedGrowthwork;

    setIsMovingForwardPopupOpen(false);

    createMemberGrowthwork({
      expertId: member.expert,
      memberId: member.uuid,
      module_relation_cms,
      cms_id: String(growthwork?.relation_cms_id),
    })
      .unwrap()
      .then((res) => res && redirectToTask({ ...growthwork, ...res }));
  };

  return (
    <GrowthworksWrapper>
      <MovingForward
        isOpened={isMovingForwardPopupOpen}
        closeModal={closeMovingForwardPopupOpen}
        onClick={createGrowthwork}
      />
      <Typography fontFamily="Garnett">My tasks</Typography>
      <div style={{ opacity: isLoading ? 0.6 : 1 }}>
        <GrowthworksList
          growthworks={growthworks}
          completedCount={completedCount}
          completedTasks={completedTasks}
          onClick={handleClick}
          disabled={() => false}
        />
      </div>
    </GrowthworksWrapper>
  );
};

export default Growthworks;
