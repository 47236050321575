import React from "react";
import { useNavigate } from "react-router-dom";
import { EmptyStateWithIllustration as EmptyStateUI } from "ritual-ui";
import { ROUTES } from "src/types/routes";
import illustration from "src/assets/images/empty_library_illustration.webp";

const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <EmptyStateUI
      illustration={illustration}
      title="Nothing here yet"
      text="Your completed content will be saved here in case you want to rewatch it."
      action={{
        id: "library-start-pathway",
        text: "Start Pathway",
        onClick: () => navigate(ROUTES.HOME),
      }}
    />
  );
};

export default EmptyState;
