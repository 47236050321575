import React from "react";
import { useGetScheduledSessionsTypes } from "src/api/resolvers";
import { ColorDescriptionWrapper, ColorItem } from "./styles";
import { Typography } from "@mui/material";

const ColorsDescription = () => {
  const sessionEvents = useGetScheduledSessionsTypes();

  if (!sessionEvents || sessionEvents?.length <= 1) {
    return <></>;
  }

  return (
    <ColorDescriptionWrapper>
      {sessionEvents.map(({ color, short_title }) => (
        <ColorItem key={short_title} sx={{ backgroundColor: color }}>
          <Typography>{short_title}</Typography>
        </ColorItem>
      ))}
    </ColorDescriptionWrapper>
  );
};

export default ColorsDescription;
