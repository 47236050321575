import React, { useEffect } from "react";
import image from "src/assets/images/all_set_illustration.png";
import { Footer, PageWrapper, Wrapper } from "./styles";
import { useGetMemberQuery } from "src/api/main";
import NewToRitual from "src/pages/NewLogin/NewToRitual";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { LoginTypes } from "src/types/login";
import { OurRitualLogo } from "src/assets/icons";
import EmailLogin from "src/pages/DesktopView/components/EmailLogin";
import PhoneNumberLogin from "src/pages/DesktopView/components/PhoneNumberLogin";
import "react-phone-input-2/lib/style.css";

const AllSetScreen = () => {
  const { data: member } = useGetMemberQuery();

  const { state } = useLocation() as {
    state: { type: LoginTypes; email?: string };
  };
  const [searchParams] = useSearchParams();
  const ldClient = useLDClient();

  useEffect(() => {
    const email = searchParams.get("email");
    if (!ldClient || !email) return;
    ldClient.identify({
      email: email,
      key: email,
    });
  }, [member, ldClient]);

  return (
    <PageWrapper>
      <Wrapper>
        <OurRitualLogo />
        <img src={image} alt="all_set_illustration" />
        {state?.type === LoginTypes.ACCESSLINK ||
        state?.type === LoginTypes.QRCODE_EMAIL ? (
          <EmailLogin />
        ) : (
          <PhoneNumberLogin />
        )}
      </Wrapper>
      <Footer>{!member && <NewToRitual />}</Footer>
    </PageWrapper>
  );
};

export default AllSetScreen;
