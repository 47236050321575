import styled from "@emotion/styled";
import theme from "src/theme";

export const PageWrapper = styled("div")({
  display: "flex",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column-reverse",
    height: "auto",
  },
});

export const Illustration = styled("div")({
  width: "68%",
  height: "100%",
  backgroundRepeat: "no-repeat",
  padding: "24px",
  margin: "auto",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundColor: "#FAF4DE",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  img: {
    height: "100%",
    maxHeight: "800px",
    objectFit: "contain",
  },

  [theme.breakpoints.down("lg")]: {
    width: "100%",

    img: {
      width: "100%",
      maxHeight: "346px",
      objectFit: "contain",
    },
  },
});
