import { ROUTES } from "src/types/routes";

export const quotes: {
  [key: string]: {
    text: string;
    author: string;
    description: string;
  };
} = {
  [ROUTES.ONBOARDING_CREATE_ACCOUNT]: {
    text: "Joining ritual made room for emotions I didn’t know I was allowed to feel, and gave me knowledge I had thought you’re either born with or you’re not.",
    author: "Jessa, 29",
    description: "Joined OurRitual on her own",
  },
  [ROUTES.ONBOARDING_INITIAL]: {
    text: "Joining ritual changed the way I understand our fights. I can step into his shoes without feeling like it threatens my own needs.",
    author: "Veronica, 35",
    description: "Joined with her husband of 10 years",
  },
  [ROUTES.ONBOARDING_ADD_PARTNER]: {
    text: "I didn’t think simply practicing stuff we learned would actually make a difference, but it did. It’s crazy to think how skills make such an impact on love.",
    author: "Andre, 37",
    description: "Joined with his wife of 2 years",
  },
};
