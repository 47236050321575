import { styled, Typography } from "@mui/material";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px 48px 48px 48px",
  alignItems: "center",
});

export const Title = styled(Typography)({
  marginTop: "24px",
  span: {
    fontStyle: "italic",
  },
});

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[100],
  textAlign: "center",
  whiteSpace: "break-spaces",
  marginTop: "24px",
  marginBottom: "32px",
}));
