import { Button } from "../Button";
import { Wrapper, Title, Subtitle } from "./styles";

interface BottomPopupUIProps {
  Icon: () => JSX.Element;
  title: string | JSX.Element;
  subtitle: string;
  buttonId: string;
  onClick: () => void;
  buttonText: string;
  disabled?: boolean;
}

const BottomPopupUI = ({
  Icon,
  title,
  subtitle,
  buttonId,
  onClick,
  buttonText,
  disabled = false,
}: BottomPopupUIProps) => {
  return (
    <Wrapper>
      <Icon />
      <Title variant="h2">{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Button id={buttonId} onClick={onClick} disabled={disabled}>
        {buttonText}
      </Button>
    </Wrapper>
  );
};

export default BottomPopupUI;
