import { styled } from "@mui/material";

interface Disabled {
  disabled?: boolean;
}

export const Wrapper = styled("div")<Disabled>(({ disabled, theme }) => ({
  marginBottom: "16px",
  background: disabled ? theme.palette.grey[300] : "white",
  boxShadow: disabled ? "none" : "0px 8px 20px rgba(87, 89, 97, 0.05)",
  borderRadius: "32px",
  padding: "20px 10px 10px 16px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  button: {
    width: "75%",
    margin: "0 auto",
    marginTop: "16px",
  },
}));

export const ContentWrapper = styled("div")({
  pointerEvents: "none",
});

export const Content = styled("div")({
  display: "flex",
  width: "100%",
  gap: "5px",
  justifyContent: "space-between",

  img: {
    maxWidth: "128px",
  },
});

export const Title = styled("div")<Disabled>(({ disabled, theme }) => ({
  p: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.grey[400],
    marginTop: "2px",
  },

  h4: {
    marginTop: "6px",
    color: disabled ? theme.palette.grey[100] : "initial",
  },
}));

export const LabelWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "4px",

  img: {
    maxWidth: "27px",
  },
});
