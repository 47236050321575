import { styled } from "@mui/material";
import { getResponsiveValue } from "src/theme";

export const Wrapper = styled("div")({
  width: "100%",
  height: "calc(var(--vh, 1vh) * 100)",
  padding: `${getResponsiveValue(25)} 27px`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const TopBar = styled("div")({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  width: "100%",
  flex: "0 0 24px",

  svg: {
    position: "absolute",
    cursor: "pointer",
    left: 0,
  },
});

export const Heading = styled("div")({
  margin: `${getResponsiveValue(25)} 0`,

  "h2, h3": {
    textAlign: "center",
  },

  h2: {
    whiteSpace: "break-spaces",
  },

  h3: {
    marginTop: getResponsiveValue(24),
  },
});
