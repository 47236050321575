import React from "react";
import { Growthwork, LibraryGrowthworkParts } from "src/api/types";
import useRedirectToTask from "src/hooks/useRedirectToTask";
import {
  GrowthworksContainer,
  PartDescription,
  PartTitleWrapper,
  Wrapper,
} from "../styles";
import LibraryGrowthworksList from "./LibraryGrowthworksList";
import { Typography } from "src/components";
import {
  useCreateMemberGrowthworkMutation,
  useGetMemberQuery,
} from "src/api/main";

export const ModuleGrowthworksList = ({
  moduleParts,
}: {
  moduleParts: LibraryGrowthworkParts[];
}) => {
  const [createMemberGrowthwork, { isLoading }] =
    useCreateMemberGrowthworkMutation();
  const redirectToTask = useRedirectToTask();
  const { data: member } = useGetMemberQuery();

  const onGrowthworkClick = (growthwork: Growthwork) => {
    if (isLoading || !member) return;

    createMemberGrowthwork({
      expertId: member.expert,
      memberId: member.uuid,
      module_relation_cms: [
        {
          id: growthwork.relation_cms_id,
          gw_link_platform: growthwork.platform,
          gw_link_url: growthwork.baselink,
        },
      ],
      cms_id: String(growthwork.relation_cms_id),
    })
      .unwrap()
      .then((res) => res && redirectToTask({ ...growthwork, ...res }));
  };

  return (
    <GrowthworksContainer style={{ opacity: isLoading ? 0.6 : 1 }}>
      {moduleParts.map((part) => (
        <Wrapper key={part.id}>
          <PartTitleWrapper>
            <Typography variant="headline4">
              {part.part_order}. {part.part_goal}
            </Typography>
            <PartDescription>{part.part_description}</PartDescription>
          </PartTitleWrapper>
          <LibraryGrowthworksList
            growthworks={part.growthworks}
            onClick={onGrowthworkClick}
          />
        </Wrapper>
      ))}
    </GrowthworksContainer>
  );
};
