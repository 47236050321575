import React from "react";
import {
  Card,
  Goal,
  Image,
  Name,
  SelectedMark,
  WeeksLengths,
  Wrapper,
} from "./styles";

interface PathwayCardProps {
  icon: string;
  name: string;
  weeks_length: number;
  goal: string;
  selected: boolean;
  recommended: boolean;
  onClick: () => void;
}

const PathwayCard = ({
  icon,
  name,
  weeks_length,
  goal,
  selected,
  recommended,
  onClick,
}: PathwayCardProps) => {
  return (
    <Wrapper onClick={onClick} recommended={recommended} selected={selected}>
      {selected && <SelectedMark />}
      <Card recommended={recommended}>
        <Image src={icon} recommended={recommended} />
        <div>
          <Name variant="h4">{name}</Name>
          <WeeksLengths variant="h5">{weeks_length} weeks</WeeksLengths>
          <Goal variant="h5" recommended={recommended}>
            {goal}
          </Goal>
        </div>
      </Card>
    </Wrapper>
  );
};

export default PathwayCard;
